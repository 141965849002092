import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as taskActions from "../../actions/taskActions";
import * as modalActions from "../../actions/modalActions";
import * as userActions from "../../actions/userActions";
import * as codeActions from "../../actions/codeActions";

import TaskForm from "./forms/TaskForm";
import BreadCrumbs from "../common/BreadCrumbs";
import EditButton from "../common/EditButton";
import { Col } from "react-bootstrap";
import find from "lodash/find";

class EditTaskPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      editing: false,
      deleting: false,
      task: {}
    };
  }

  componentDidMount = async () => {
    let { actions } = this.props;
    await actions.getTask(this.props.match.params.id);
    actions.loadCodes();
    actions.loadUsers();
    actions.findTaskGroup(this.props.task.group_id);
  };

  handleEdit = () => {
    this.setState({ editing: !this.state.editing });
  };

  onClickSave = async (task, oldAssignees, newAssignees) => {
    let { auth, codes, taskGroup } = this.props;
    let newTask = task || this.state.modal;

    // //find new assinees
    let assigneeToAdd = newAssignees.filter(assignedUser => {
      if (!find(oldAssignees, { value: assignedUser.value })) {
        return assignedUser;
      }
    });
    // create new task for each new assignee
    assigneeToAdd.map(user => {
      let newSingleTask = {
        ...newTask,
        main_task: false,
        user_id: auth.user._id,
        assigned_to: user.value,
        status: find(codes, { label: "Assigned" })._id
      };
      this.props.actions.createTask(newSingleTask);
    });

    //find removed assignees
    let assigneeToDelete = oldAssignees.filter(assignedUser => {
      if (!find(newAssignees, { value: assignedUser.value })) {
        return assignedUser;
      }
    });
    //find task based on removed user(s) and delete their single task
    taskGroup.filter(task => {
      if (find(assigneeToDelete, { value: task.assigned_to[0].id })) {
        let id = task.id;
        this.props.actions.deleteOneTask(id);
      }
    });

    //update task(s)
    let data = {
      ...newTask,
      _id: this.props.task._id,
      user: auth.user._id
    };
    await this.props.actions.updateTask(data);
    await this.props.history.push(`/app/tasks`);
  };

  render() {
    let { task, users, codes, auth, isMobile, taskGroup } = this.props;

    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs
            breadcrumbs={[{ label: "Tasks" }, { label: "Edit Task" }]}
            onClick={this.props.history.goBack}
          />
          <div>
            <EditButton
              onClickEdit={this.handleEdit}
              disabled={auth.maxRole == 4000 && task.completed_by}
            />
          </div>
        </div>
        <div className="content-wrapper">
          <TaskForm
            task={task}
            users={users}
            auth={auth}
            codes={codes}
            isMobile={isMobile}
            onSave={this.onClickSave}
            onCancel={this.props.history.goBack}
            saving={this.props.savingTask}
            editing={this.state.editing}
            taskGroup={taskGroup}
          />
        </div>
      </>
    );
  }
}

EditTaskPage.propTypes = {
  actions: PropTypes.object,
  savingTask: PropTypes.bool,
  task: PropTypes.object,
  tasks: PropTypes.array,
  users: PropTypes.array,
  auth: PropTypes.object,
  codes: PropTypes.array,
  params: PropTypes.object,
  location: PropTypes.object,
  pathname: PropTypes.string
};

const mapStateToProps = state => {
  return {
    state: state,
    modal: state.modal,
    savingTask: state.savingTask,
    task: state.task,
    tasks: state.tasks,
    taskGroup: state.taskGroup,
    codes: state.codes,
    users: state.users,
    auth: state.auth,
    location: state.router.location,
    pathname: state.router.location.pathname
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        ...taskActions,
        ...modalActions,
        ...userActions,
        ...codeActions
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTaskPage);
