import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";

const TaskItems = ({ items, onMarkCompleted }) => {
  const taskItems = items.map((item, i) => (
    <Card key={item.id} className="task-item">
      <input
        type="checkbox"
        id={`task-item-${i}`}
        label="Mark as Complete?"
        onChange={e => onMarkCompleted(e, item)}
        checked={item.isComplete}
      />
      <label htmlFor={`task-item-${i}`} class="strikethrough">
        {item.text}
      </label>
    </Card>
  ));
  return (
    <div className="task-items">
      {taskItems.length > 0 ? (
        <div>
          <h4 className="taskCardItemTitle">To-Do Items</h4>
          {taskItems}
        </div>
      ) : (
        <h4 className="center">No To-Do Items</h4>
      )}
    </div>
  );
};

TaskItems.proptypes = {
  items: PropTypes.array,
  onMarkCompleted: PropTypes.func
};

export default TaskItems;
