import React from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faTrashAlt,
  faEllipsisV
} from "@fortawesome/fontawesome-free-solid";

const RoleList = ({ roles, onClickEdit, onClickDelete }) => {
  const columns = [
    {
      Header: "Number",
      accessor: "number",
      maxWidth: 200
    },
    {
      Header: "Title",
      accessor: "title"
    },
    {
      Header: "",
      accessor: "_id",
      maxWidth: 80,
      Cell: row => (
        <div className="text-center actions">
          <DropdownButton
            id={"dropdown"}
            title={<FontAwesomeIcon icon={faEllipsisV} />}
          >
            <Dropdown.Item onClick={() => onClickEdit(row.value)}>
              <FontAwesomeIcon icon={faFolder} />
              View
            </Dropdown.Item>
            <Dropdown.Item onClick={() => onClickDelete(row.value)}>
              <FontAwesomeIcon icon={faTrashAlt} />
              Delete
            </Dropdown.Item>
          </DropdownButton>
        </div>
      )
    }
  ];

  const props = {
    getTdProps: (state, rowInfo, column) => {
      return {
        onClick: () => {
          if (rowInfo && column && column.id !== "_id") {
            onClickEdit(rowInfo.original._id);
          }
        }
      };
    }
  };

  return (
    <ReactTable
      className="-highlight"
      data={roles}
      columns={columns}
      {...props}
      defaultPageSize={roles.length ? roles.length : 5}
      showPagination={false}
      defaultSorted={[
        {
          id: "number",
          asc: true
        }
      ]}
    />
  );
};

RoleList.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      number: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired
};

export default RoleList;
