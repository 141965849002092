import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import * as userActions from "../../actions/userActions";

import { UserAvatar } from "../users/UserAvatar";
import { Button } from "react-bootstrap";

class Logout extends Component {
  constructor(props, context) {
    super(props, context);
    autoBind(this);
  }

  logOut = () => {
    this.props.actions.logoutUser(this.props.auth.user);
  };

  render() {
    let { auth } = this.props;
    return (
      <Button className="btn-transparent logout" onClick={this.logOut}>
        <UserAvatar user={auth.user} size="sm" />
      </Button>
    );
  }
}

Logout.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object,
  logoutUser: PropTypes.func
};

const mapStateToProps = state => ({
  state: state,
  auth: state.auth
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...userActions }, dispatch)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Logout)
);
