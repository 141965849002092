import React, { Component } from "react";
import PropTypes from "prop-types";
import Formsy from "formsy-react";

import { Link } from "react-router-dom";
import { Input } from "formsy-react-components";
import { Image } from "react-bootstrap";

import logo from "../../images/mark.png";

class LoginForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false
    };
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  submit = form => {
    this.props.onSave(form);
  };

  render() {
    let { saving } = this.props;
    return (
      <Formsy
        ref="form"
        className="form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <div className="logo-container">
          <Image className="logo" src={logo} />
        </div>
        <h1>Vohokase</h1>
        <Input
          formNoValidate
          required
          name="email"
          label="Email"
          placeholder="Email"
          addonBefore={<span className="glyphicon glyphicon-envelope" />}
        />
        <Input
          formNoValidate
          required
          type="password"
          name="password"
          label="Password"
          placeholder="Password"
          addonBefore={<span className="glyphicon glyphicon-lock" />}
        />
        <div className="text-center">
          <input
            className="btn btn-success"
            type="submit"
            disabled={!this.state.canSubmit}
            value={saving ? "Signing In... " : "Sign In"}
          />
        </div>
        <Link to="/forgot">Forgot Password?</Link>
        <div className="footer-tag">
          <p>Vokohase v1.0.0</p>
        </div>
      </Formsy>
    );
  }
}

LoginForm.propTypes = {
  saving: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired
};

export default LoginForm;
