import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as topicBoardActions from "../../actions/topicBoardActions";
import * as codeActions from "../../actions/codeActions";

import PostForm from "./PostForm";
import BreadCrumbs from "../common/BreadCrumbs";

import isEmpty from "lodash/isEmpty";

class EditPostPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentDidMount = () => {
    let { codes, actions } = this.props;
    isEmpty(codes) && actions.loadCodes();

    actions.getTopicBoard(this.props.match.params.id);
  };

  // update edited post info
  handleSave = async post => {
    let data = {
      _id: this.props.post._id,
      ...post
    };

    await this.props.actions.updateTopicBoard(data);
    await this.props.actions.loadTopicBoards();
    await this.props.history.push(`/app/topic-boards`);
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    let { post, saving, codes } = this.props;

    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs
            breadcrumbs={[{ label: "Edit Post" }, { label: post.title }]}
            onClick={this.props.history.goBack}
          />
        </div>
        <div className="content-wrapper">
          <PostForm
            post={post}
            codes={codes}
            onSave={this.handleSave}
            onCancel={this.handleCancel}
            saving={saving}
          />
        </div>
      </>
    );
  }
}

EditPostPage.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object,
  boards: PropTypes.array,
  saving: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    state: state,
    auth: state.auth,
    boards: state.topicBoards,
    codes: state.codes,
    post: state.topicBoardPost,
    saving: state.savingTopicBoard
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { ...topicBoardActions, ...codeActions },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPostPage);
