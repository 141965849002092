import React, { Component } from "react";
import logo from "@/images/mark.png";
export default class MessagesHome extends Component {
  render() {
    return (
      <div className="messages__home">
        <figure className="image">
          <img src={logo} />
        </figure>
      </div>
    );
  }
}
