import React, { Component } from "react";
import PropTypes from "prop-types";
import Formsy from "formsy-react";
import autoBind from "react-autobind";

import { Input } from "formsy-react-components";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import FormFooter from "../common/FormFooter";

class CommmunityServiceForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false,
      dateSubmitted: false,
      date: null
    };
  }

  //handle submit form
  submit = async data => {
    let post = {
      date: this.state.date || this.props.post.date,
      user: this.props.post.user || this.props.auth.user,
      ...data
    };
    await this.props.handleSubmit(post);
    this.setState({
      date: null,
      dateSubmitted: false
    });
  };

  //handle datePicker click
  handleDateSelect = date => {
    this.setState({
      date: date,
      dateSubmitted: true
    });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  render() {
    let { post, handleCancel } = this.props;

    return (
      <Formsy
        className="community-service-form vertical form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Row>
          <Col md={6} xs={12}>
            <Input
              name="organization"
              label="Organization"
              value={post.organization}
              required
            />
          </Col>
          <Col md={4} xs={12}>
            <div className="form-group row">
              <label
                className="control-label"
                htmlFor={name}
                style={{ textTransform: "uppercase" }}
              >
                Date
              </label>
              <DatePicker
                name="date"
                label="Date"
                onSelect={this.handleDateSelect}
                placeholderText="Click to select a date"
                fixedHeight
                className="form-control"
                selected={
                  this.state.date
                    ? this.state.date
                    : post.date
                    ? moment(post.date)
                    : null
                }
              />
            </div>
          </Col>
          <Col md={2} xs={12}>
            <Input
              name="hours"
              type="number"
              label="Hours"
              value={post.hours}
              required
            />
          </Col>
          <Col md={12} xs={12}>
            <Input name="note" label="Notes" value={post.note} />
          </Col>
        </Row>

        <FormFooter
          cancel={handleCancel}
          canSubmit={
            post._id
              ? this.state.canSubmit
              : this.state.canSubmit && this.state.dateSubmitted
          }
        />
      </Formsy>
    );
  }
}

CommmunityServiceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  post: PropTypes.object,
  date: PropTypes.string,
  history: PropTypes.object
};

export default CommmunityServiceForm;
