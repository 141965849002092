import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const topicBoards = (state = initialState.topicBoards, action) => {
  switch (action.type) {
    case types.LOAD_TOPIC_BOARD_SUCCESS:
      return action.topicBoard;

    case types.CREATE_TOPIC_BOARD_SUCCESS:
      return [...state, Object.assign({}, action.topicBoard)];

    case types.UPDATE_TOPIC_BOARD_SUCCESS:
      return [
        ...state.filter(topicBoard => topicBoard.id !== action.topicBoard.id),
        Object.assign({}, action.topicBoard)
      ];

    case types.DELETE_TOPIC_BOARD_SUCCESS:
      return [
        ...state.filter(topicBoard => topicBoard.id !== topicBoard.postId)
      ];

    default:
      return state;
  }
};

export const topicBoardPost = (state = initialState.topicBoardPost, action) => {
  switch (action.type) {
    case types.GET_TOPIC_BOARD_SUCCESS:
      return action.topicBoardPost;

    default:
      return state;
  }
};

export const savingTopicBoard = (
  state = initialState.savingTopicBoard,
  action
) => {
  switch (action.type) {
    case types.SAVING_TOPIC_BOARD:
      return action.savingTopicBoard;

    default:
      return state;
  }
};

export const topicBoardToDelete = (
  state = initialState.topicBoardToDelete,
  action
) => {
  switch (action.type) {
    case types.REQUEST_TOPIC_BOARD_ID:
      return action.topicBoardToDelete;

    default:
      return state;
  }
};
