import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as modalActions from "../../actions/modalActions";
import * as documentActions from "../../actions/documentActions";

import DocumentCard from "./DocumentCard";
import ConfirmModal from "../common/ConfirmModal";
import BreadCrumbs from "../common/BreadCrumbs";
import Searchbar from "../common/Searchbar";
import AddButton from "../common/AddButton";

import matchSorter from "match-sorter";

class DocumentPage extends Component {
  constructor(props) {
    super(props);
    this.state = { search: "" };
  }

  componentDidMount = () => {
    let { actions } = this.props;
    actions.loadDocuments();
  };

  onClickAdd = () => {
    this.props.history.push("/app/documents/add");
  };

  onClickDelete = doc => {
    this.props.actions.requestDocumentId(doc, "delete");
  };

  onClickEdit = id => {
    this.props.history.push(`/app/documents/${id}`);
  };

  handleDelete = () => {
    this.props.actions.deleteDocument(this.props.documentToDelete);
  };

  filter = e => {
    this.setState({ search: e.target.value });
  };

  render() {
    let { auth, modal } = this.props;

    const documents = matchSorter(this.props.documents, this.state.search, {
      keys: ["name"]
    })
      .filter(doc => {
        if (auth.role.number < 8000 && doc.is_private) return;
        else return doc;
      })
      .map((doc, i) => {
        return (
          <DocumentCard
            key={i}
            document={doc}
            auth={auth}
            onClickDelete={this.onClickDelete}
            onClickEdit={this.onClickEdit}
          />
        );
      });

    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs breadcrumbs={[{ label: "Documents" }]} />
          <div>
            <Searchbar onFilter={this.filter} type="Documents" />
            {auth.role.number == 8000 && (
              <AddButton onClickAdd={this.onClickAdd} />
            )}
          </div>
        </div>
        <div className="content-wrapper transparent">
          {documents.length ? (
            <div className="list">{documents}</div>
          ) : (
            <h4 className="center">No Documents</h4>
          )}
        </div>
        <ConfirmModal
          id="documentDeleteModal"
          title="Delete Document"
          body="Are you sure you want to delete this document?"
          modal={modal}
          close={this.props.actions.hideModal}
          confirm={this.handleDelete}
        />
      </>
    );
  }
}

DocumentPage.propTypes = {
  actions: PropTypes.object,
  modal: PropTypes.object,
  userToDelete: PropTypes.string,
  users: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  state: state,
  auth: state.auth,
  documents: state.documents,
  documentToDelete: state.documentToDelete,
  modal: state.modal
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...documentActions
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentPage);
