import * as types from "./actionTypes";
import { showModalSuccess } from "./modalActions";
import { showAlertSuccess, hideAlertSuccess } from "./alertActions";
import topicBoardService from "../services/topicBoardService";

export function loadTopicBoardSuccess(topicBoard) {
  return {
    type: types.LOAD_TOPIC_BOARD_SUCCESS,
    topicBoard
  };
}

export function getTopicBoardSuccess(topicBoardPost) {
  return {
    type: types.GET_TOPIC_BOARD_SUCCESS,
    topicBoardPost
  };
}

export function savingTopicBoard(status = true) {
  return {
    type: types.SAVING_TOPIC_BOARD,
    savingTopicBoard: status
  };
}

export function createTopicBoardSuccess(topicBoard) {
  return {
    type: types.CREATE_TOPIC_BOARD_SUCCESS,
    topicBoard
  };
}

export function updateTopicBoardSuccess(topicBoard) {
  return {
    type: types.UPDATE_TOPIC_BOARD_SUCCESS,
    topicBoard
  };
}

export function requestTopicBoardId(topicBoardId) {
  return dispatch => {
    dispatch({
      type: types.REQUEST_TOPIC_BOARD_ID,
      topicBoardToDelete: topicBoardId
    });
    dispatch(showModalSuccess("topicBoardDeleteModal"));
  };
}

export function requestTopicBoardId_Comment(topicBoardId) {
  return dispatch => {
    dispatch({
      type: types.REQUEST_TOPIC_BOARD_ID,
      topicBoardToDelete: topicBoardId
    });
    dispatch(showModalSuccess("topicBoardCommentDeleteModal"));
  };
}

export function deleteTopicBoardSuccess(topicBoardId) {
  return {
    type: types.DELETE_TOPIC_BOARD_SUCCESS,
    topicBoardId
  };
}

export function loadTopicBoards() {
  return dispatch => {
    dispatch(hideAlertSuccess());
    return topicBoardService
      .loadTopicBoards()
      .then(data => dispatch(loadTopicBoardSuccess(data)))
      .catch(error => dispatch(showAlertSuccess(error, "error")));
  };
}

export function getTopicBoard(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    if (id) {
      return topicBoardService
        .getTopicBoard(id)
        .then(data => {
          dispatch(getTopicBoardSuccess(data));
        })
        .catch(error => dispatch(showAlertSuccess(error.description, "error")));
    }
  };
}

export function createTopicBoard(topicBoard) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingTopicBoard());
    return topicBoardService
      .createTopicBoard(topicBoard)
      .then(createdTopicBoard => {
        dispatch(createTopicBoardSuccess(createdTopicBoard));
        dispatch(savingTopicBoard(false));
        dispatch(
          showAlertSuccess("TopicBoard created successfully", "success")
        );
      })
      .catch(error => {
        dispatch(savingTopicBoard(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateTopicBoard(topicBoard, commentId) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingTopicBoard());
    return topicBoardService
      .updateTopicBoard(topicBoard, commentId)
      .then(updatedTopicBoard => {
        dispatch(updateTopicBoardSuccess(updatedTopicBoard, commentId));
        dispatch(savingTopicBoard(false));
        dispatch(
          showAlertSuccess("TopicBoard updated successfully", "success")
        );
      })
      .catch(error => {
        dispatch(savingTopicBoard(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteTopicBoard(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return topicBoardService
      .deleteTopicBoard(id)
      .then(() => {
        dispatch(deleteTopicBoardSuccess(id));
        dispatch(
          showAlertSuccess("TopicBoard deleted successfully", "success")
        );
      })
      .catch(error => dispatch(showAlertSuccess(error.description, "error")));
  };
}
