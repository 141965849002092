import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import UserAddress from "./forms/UserAddress";
import UserSocial from "./forms/UserSocial";
import UserInfo from "./forms/UserInfo";
import InputPasswordComponent from "./forms/InputPasswordComponent";
import AssociateCampers from "./forms/AssociateCampers";
import Formsy from "formsy-react";
import DropdownSearch from "../common/DropdownSearch";
import FormFooter from "../common/FormFooter";
import { Col } from "react-bootstrap";
import { RadioGroup } from "formsy-react-components";

import orderBy from "lodash/orderBy";
import find from "lodash/find";
import AvatarUpload from "../common/AvatarUpload";
class UserForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      avatar: props.user.avatar || "",
      canSubmit: false
    };
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  cancel = () => {
    this.props.onCancel();
  };

  submit = data => {
    const {
      first_name,
      last_name,
      snapchat,
      instagram,
      twitter,
      street,
      city,
      state,
      zip
    } = data;

    let user = this.props.user;
    user.address = { street, city, state, zip };
    user.social = { snapchat, instagram, twitter };
    user.full_name = `${first_name} ${last_name}`;
    user.avatar = this.state.avatar || user.avatar;
    if (this.props.adding) {
      user.role = this.state.role.id;
      user.password = data.password;
    }

    if (data.associated_campers) {
      user.associated_campers = data.associated_campers.map(
        camper => camper.value
      );
    }

    this.props.onSave({ ...user, ...data });
  };

  handleChange = (field, selected) => {
    this.setState({ [field]: selected.value });
  };

  handleChangeSelect = (field, selectedOptions) => {
    this.setState({ [field]: selectedOptions });
  };

  onChangeRole = (field, value) => {
    let role = find(this.props.roles, { _id: value });
    this.setState({ role });
  };

  onUploadStart = () => {
    this.setState({ canSubmit: false });
  };
  onUploadSuccess = file => {
    this.setState({ avatar: file.location, canSubmit: true });
  };
  generateSchoolOptions = codes => {
    return orderBy(
      codes
        .filter(code => code.field === "school_district")
        .map(code => ({
          value: code._id,
          label: code.label
        })),
      "label"
    );
  };

  render() {
    let { users, user, codes, roles, editing, adding, saving } = this.props;

    let role = this.state.role || user.role || {};
    user.address = user.address || {};

    return (
      <Formsy
        className="vertical form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        {adding && (
          <Col md={12} xs={12} id="roles">
            <fieldset>
              <legend>User Type</legend>
            </fieldset>
            <RadioGroup
              name="role"
              required
              value={role._id}
              disabled={!editing}
              onChange={this.onChangeRole}
              options={orderBy(roles, "number", "desc").map(role => ({
                value: role._id,
                label: role.title
              }))}
            />
          </Col>
        )}
        <UserInfo
          editing={editing}
          user={user}
          users={users}
          onChange={this.handleChange}
        />
        {adding && <InputPasswordComponent editing={editing} user={user} />}
        <UserAddress
          editing={editing}
          user={user}
          codes={codes}
          state={this.state.state || user.address.state}
          onChange={this.handleChange}
        />
        {role.number > 4000 && role.number <= 7000 && (
          <AssociateCampers
            users={users}
            onChangeSelect={this.handleChangeSelect}
            editing={editing}
            associated_campers={
              this.state.associated_campers || user.associated_campers
            }
          />
        )}
        {role.number <= 7000 && (
          <Col md={12} xs={12}>
            <fieldset>
              <legend>School District</legend>
            </fieldset>
            <DropdownSearch
              name="school_district"
              label="School District"
              disabled={!this.props.editing}
              onChange={this.handleChange}
              value={this.state.school_district || user.school_district}
              data={[...this.generateSchoolOptions(codes)]}
            />
          </Col>
        )}
        <UserSocial social={user.social || {}} editing={editing} />
        <Col md={12} xs={12}>
          <fieldset>
            <legend>Avatar</legend>
          </fieldset>
          <AvatarUpload
            disabled={!editing}
            user={user}
            avatar={user.avatar}
            onUploadStart={this.onUploadStart}
            onUploadSuccess={this.onUploadSuccess}
          />
        </Col>
        <FormFooter
          cancel={this.cancel}
          saving={saving}
          canSubmit={this.state.canSubmit}
        />
      </Formsy>
    );
  }
}

UserForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  // user: PropTypes.object,
  users: PropTypes.array,
  roles: PropTypes.array,
  codes: PropTypes.array
};

export default UserForm;
