import React, { Component, Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import windowSize from "react-window-size";

import * as alertActions from "../actions/alertActions";
import * as userActions from "../actions/userActions";

import LoginPage from "../components/login/LoginPage";
import ForgotPage from "../components/forgot/ForgotPage";
import ResetPage from "../components/reset/ResetPage";

import PrivateRoute from "./PrivateRoute";
import AdminRoutes from "./AdminRoutes";
import SchoolRoutes from "./SchoolRoutes";
import StaffRoutes from "./StaffRoutes";
import ParentRoutes from "./ParentRoutes";
import CamperRoutes from "./CamperRoutes";
import { alertMessage } from "../helpers";

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: props.auth.isAuth
    };
  }

  componentDidMount = () => {
    let { actions } = this.props;
    const token = localStorage.getItem("camp_vk_token");
    actions.checkAuth(token);
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.auth.user !== this.props.auth.user) {
      this.setState({ isAuthenticated: nextProps.auth.isAuth });
      if (!nextProps.auth.isAuth) {
        this.props.history.replace("/login");
      }
    }
  };
  componentDidUpdate = prevProps => {
    if (this.props.location !== prevProps.location) {
      window.scroll(0, 0);
    }
  };
  componentWillUpdate = nextProps => {
    if (nextProps.alert !== this.props.alert) {
      alertMessage(nextProps.alert);
    }
  };
  renderRoute = role => {
    switch (role) {
      case 8000:
        return AdminRoutes;
      case 7000:
        return StaffRoutes;
      case 6000:
        return SchoolRoutes;
      case 5000:
        return ParentRoutes;
      case 4000:
        return CamperRoutes;
    }
  };

  render() {
    const { auth, windowWidth } = this.props;
    const { isAuthenticated } = this.state;
    return (
      <Switch>
        <Redirect exact from="/" to="/login" />
        <Route exact path="/" component={LoginPage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/forgot" component={ForgotPage} />
        <Route path="/reset/:token" component={ResetPage} />
        {isAuthenticated && auth.role && (
          <PrivateRoute
            path={"/app"}
            auth={auth}
            isMobile={windowWidth < 768}
            component={this.renderRoute(auth.role.number)}
          />
        )}
      </Switch>
    );
  }
}

Routes.propTypes = {
  history: PropTypes.object,
  actions: PropTypes.object,
  auth: PropTypes.object,
  state: PropTypes.object
};

const mapStateToProps = state => ({
  state: state,
  auth: state.auth,
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  alert: state.alert
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...userActions, ...alertActions }, dispatch)
  };
}

export default windowSize(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Routes)
  )
);
