import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const conversations = (state = initialState.conversations, action) => {
  switch (action.type) {
    case types.LOAD_CONVERSATION_SUCCESS:
      return action.conversations;

    case types.CREATE_CONVERSATION_SUCCESS:
      return [
        ...state.filter(
          conversations => conversations._id !== action.conversation._id
        ),
        { ...action.conversation }
      ];

    case types.UPDATE_CONVERSATION_SUCCESS:
      return [
        ...state.filter(
          conversations => conversations._id !== action.conversation._id
        ),
        Object.assign({}, action.conversation)
      ];

    case types.DELETE_CONVERSATION_SUCCESS:
      return [
        ...state.filter(
          conversation => conversation.id !== action.conversationId
        )
      ];

    default:
      return state;
  }
};

export const conversation = (state = initialState.conversation, action) => {
  switch (action.type) {
    case types.GET_CONVERSATION_SUCCESS:
      return action.conversation;

    default:
      return state;
  }
};

export const savingConversation = (
  state = initialState.savingConversation,
  action
) => {
  switch (action.type) {
    case types.SAVING_CONVERSATION:
      return action.savingConversation;

    default:
      return state;
  }
};

export const loadingConversation = (
  state = initialState.loadingConversation,
  action
) => {
  switch (action.type) {
    case types.LOADING_CONVERSATION:
      return action.loadingConversation;

    default:
      return state;
  }
};

export const conversationToDelete = (
  state = initialState.conversationToDelete,
  action
) => {
  switch (action.type) {
    case types.REQUEST_CONVERSATION_ID:
      return action.conversationToDelete;

    default:
      return state;
  }
};

export const messages = (state = initialState.messages, action) => {
  switch (action.type) {
    case types.GET_MESSAGES_SUCCESS:
      return action.messages;

    default:
      return state;
  }
};
