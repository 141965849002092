import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { users, user, savingUser, userToDelete, auth } from "./userReducer";
import { roles, role, savingRole, roleToDelete } from "./roleReducer";
import { codes, code, savingCode, codeToDelete } from "./codeReducer";
import {
  events,
  event,
  savingEvent,
  eventToDelete,
  publicEvents
} from "./eventReducer";
import {
  tasks,
  task,
  savingTask,
  taskToDelete,
  taskGroup
} from "./taskReducer";
import { grades, grade, savingGrade, gradeToDelete } from "./gradeReducer";
import {
  communityService,
  communityServices,
  savingCommunityService,
  communityServiceToDelete,
  communityServicePost
} from "./communityServiceReducer";
import {
  conversations,
  conversation,
  loadingConversation,
  messages
} from "./conversationReducer";
import { modal } from "./modalReducer";
import { alert } from "./alertReducer";
import { logs } from "./logReducer";
import {
  topicBoards,
  topicBoardPost,
  topicBoardToDelete,
  savingTopicBoard
} from "./topicBoardReducer";
import { documents, document, documentToDelete } from "./documentReducer";

const createRootReducer = history =>
  combineReducers({
    users,
    user,
    savingUser,
    userToDelete,
    auth,
    roles,
    role,
    savingRole,
    roleToDelete,
    code,
    codes,
    savingCode,
    codeToDelete,
    events,
    publicEvents,
    event,
    savingEvent,
    eventToDelete,
    modal,
    alert,
    logs,
    tasks,
    task,
    savingTask,
    taskToDelete,
    taskGroup,
    grades,
    grade,
    savingGrade,
    gradeToDelete,
    document,
    documents,
    documentToDelete,
    communityService,
    communityServices,
    communityServicePost,
    savingCommunityService,
    communityServiceToDelete,
    conversations,
    conversation,
    loadingConversation,
    messages,
    topicBoards,
    topicBoardPost,
    topicBoardToDelete,
    savingTopicBoard,
    router: connectRouter(history)
  });

export default createRootReducer;
