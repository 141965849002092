import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import windowSize from "react-window-size";
import * as userActions from "../../actions/userActions";
import * as conversationActions from "../../actions/conversationActions";
import ConversationList from "./ConversationList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import SocketEvents from "@/sockets";
import isEmpty from "lodash/isEmpty";
import BreadCrumbs from "../common/BreadCrumbs";
import { RouteWithChildRoutes } from "@/routes/RouteWithChildRoutes";
import { getConversationParticipants } from "./utils";

import "./styles.scss";
import "./modal.scss";

class MessagesPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      new: false,
      limit: 20,
      isLoading: true
    };
  }

  componentDidMount = async () => {
    const { actions, auth } = this.props;
    await Promise.all([
      await actions.loadUsers(),
      await actions.loadConversations(auth.user._id)
    ]);
    SocketEvents.subscribeToNewMessages(({ conversation }) =>
      this.getConversation(conversation)
    );
    SocketEvents.subscribeToNewConversations(() => this.loadConversations());
  };

  componentWillUnmount = () => {
    SocketEvents.close();
  };
  getConversation = async id => {
    await this.props.actions.getConversation(id, this.state.limit);
  };
  loadConversations = async () => {
    const { actions, auth } = this.props;
    await actions.loadConversations(auth.user._id);
  };
  handleUpdateConversation = conversation => {
    console.log({ conversation });
    this.props.actions.updateConversation(conversation);
  };
  handleDeleteConversation = async id => {
    const { conversation, history } = this.props;
    if (conversation._id === id) {
      history.push("/app/messages");
    }
    this.props.actions.deleteConversation(id);
  };
  render() {
    const {
      auth,
      windowWidth,
      routes,
      match,
      conversation,
      conversations,
      actions
    } = this.props;

    const breadcrumbs = [
      {
        label: "Messages",
        to: "/app/messages"
      }
    ];

    if (!match.isExact) {
      const lastPath = window.location.pathname.slice(
        window.location.pathname.lastIndexOf("/") + 1
      );
      if (lastPath === "new") {
        breadcrumbs.push({
          label: "New Message"
        });
      } else {
        if (!isEmpty(conversation)) {
          breadcrumbs.push({
            label:
              conversation.title ||
              getConversationParticipants(
                conversation.participants.filter(p => p._id !== auth.user._id)
              )
          });
        }
      }
    }
    const senderRoles = [4000, 5000, 8000];
    return (
      <div className="content-wrapper messages__wrapper">
        <div className="header-wrapper">
          <BreadCrumbs breadcrumbs={breadcrumbs} />
          <div>
            {senderRoles.includes(auth.role.number) && (
              <Button
                className="btn right btn-action btn btn-primary"
                onClick={() => this.props.history.push("/app/messages/new")}
              >
                <FontAwesomeIcon icon={faEdit} fixedWidth />
              </Button>
            )}
          </div>
        </div>
        <div className="messages__page">
          <div
            className="conversation__panel"
            style={{ height: match.isExact ? "100%" : "" }}
          >
            {(windowWidth >= 768 || match.isExact) && (
              <ConversationList
                conversations={conversations}
                selectedConversation={!match.isExact ? conversation._id : ""}
                auth={auth}
                onClickConversation={this.onClickConversation}
                deleteConversation={this.handleDeleteConversation}
                updateConversation={this.handleUpdateConversation}
              />
            )}
          </div>
          <div className="messages_panel">
            {routes.map((route, i) => (
              <RouteWithChildRoutes key={i} {...route} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

MessagesPage.propTypes = {
  state: PropTypes.object,
  pathname: PropTypes.string,
  search: PropTypes.string,
  hash: PropTypes.string,
  users: PropTypes.array,
  auth: PropTypes.object,
  register: PropTypes.func,
  client: PropTypes.object,
  actions: PropTypes.object,
  conversation: PropTypes.object,
  history: PropTypes.object,
  windowWidth: PropTypes.number,
  routes: PropTypes.array,
  match: PropTypes.object,
  conversations: PropTypes.array
};

const mapStateToProps = state => ({
  auth: state.auth,
  users: state.users,
  conversation: state.conversation,
  conversations: state.conversations
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        ...userActions,
        ...conversationActions
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(windowSize(MessagesPage));
