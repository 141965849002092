import React, { Component } from "react";
import PropTypes from "prop-types";

import Formsy from "formsy-react";
import FormFooter from "../common/FormFooter";
import { Row, Col } from "react-bootstrap";
import { Input, Select } from "formsy-react-components";

import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import startCase from "lodash/startCase";

class CodeForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false
    };
  }

  componentDidMount = () => {
    this.setState({ entity: this.props.code.entity });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  onChange = (field, selected) => {
    this.setState({ [field]: selected });
  };

  add = model => {
    this.props.onAdd(model);
    this.refs.form.reset();
  };

  submit = model => {
    this.props.onSave(model);
    this.refs.form.reset();
  };

  cancel = () => {
    this.props.onCancel();
  };

  render() {
    let { code, adding, saving } = this.props;

    return (
      <Formsy
        ref="form"
        className="vertical form"
        onValidSubmit={adding ? this.add : this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
        onChange={this.handleSelect}
      >
        <Row>
          <Col md={6} xs={12}>
            <Select
              required
              name="entity"
              label="Entity"
              onChange={this.onChange}
              value={this.state.entity}
              options={[
                {
                  value: "",
                  label: "Please select...",
                  disabled: true,
                  selected: true
                },
                ...orderBy(
                  uniqBy(
                    [
                      ...this.props.codes.map(code => ({
                        value: code.entity,
                        label: code.entity
                      }))
                    ],
                    "value"
                  ),
                  "value"
                )
              ]}
            />
          </Col>
          <Col md={6} xs={12}>
            <Select
              required
              name="field"
              label="Field"
              onChange={this.onChange}
              value={this.state.field || code.field}
              disabled={!this.state.entity}
              options={[
                {
                  value: "",
                  label: "Please select...",
                  disabled: true,
                  selected: true
                },
                ...orderBy(
                  uniqBy(
                    this.props.codes
                      .filter(
                        codes =>
                          codes.entity == code.entity ||
                          codes.entity == this.state.entity
                      )
                      .map(code => {
                        let formattedField = code.field.replace(/[_-]/g, " ");
                        return {
                          value: code.field,
                          label: startCase(formattedField)
                        };
                      }),
                    "value"
                  ),
                  "value"
                )
              ]}
            />
          </Col>
          <Col md={6} xs={12}>
            <Input
              name="value"
              label="Value"
              placeholder="Value"
              value={code.value || ""}
            />
          </Col>
          <Col md={6} xs={12}>
            <Input
              name="label"
              label="Label"
              placeholder="Label"
              value={code.label || ""}
            />
          </Col>
        </Row>
        <FormFooter
          cancel={this.cancel}
          saving={saving}
          canSubmit={this.state.canSubmit}
        />
      </Formsy>
    );
  }
}

CodeForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  code: PropTypes.object.isRequired,
  codes: PropTypes.array.isRequired
};

export default CodeForm;
