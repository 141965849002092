import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as userActions from "../../actions/userActions";
import * as roleActions from "../../actions/roleActions";
import * as modalActions from "../../actions/modalActions";
import * as codeActions from "../../actions/codeActions";

import UserForm from "./UserForm";
import BreadCrumbs from "../common/BreadCrumbs";
import EditButton from "../common/EditButton";
import Formsy from "formsy-react";
import { Input } from "formsy-react-components";
import Loader from "../common/Loader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faHandsHelping,
  faPhone
} from "@fortawesome/fontawesome-free-solid";

import isEmpty from "lodash/isEmpty";
import UserService from "../../services/userService";

class UserEditPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      user: null,
      editing: false,
      showModal: false
    };
  }

  componentDidMount = async () => {
    const { users, codes, roles, actions } = this.props;
    await actions.getUser(this.props.match.params.id);
    isEmpty(users) && (await actions.loadUsers());
    isEmpty(roles) && (await actions.loadRoles());
    isEmpty(codes) && (await actions.loadCodes());
  };

  handleSave = user => {
    let data = {
      ...user,
      _id: this.props.user._id
    };
    data.associated_campers = data.associated_campers.map(
      camper => camper.value
    );
    this.props.actions.updateUser(data);
    this.setState({ editing: false });
  };

  handleEdit = () => {
    this.setState({ editing: !this.state.editing });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  onClickCourses = id => {
    this.props.history.push(`/app/users/${id}/courses`);
  };

  onClickCommunityService = id => {
    this.props.history.push(`/app/users/${id}/community-service`);
  };

  onClickCallLog = id => {
    this.props.history.push(`/app/users/${id}/call-log`);
  };

  onClickPasswordReset = () => {
    this.setState({ showModal: true });
  };

  onCancelModal = () => {
    this.setState({ showModal: false });
    this.refs.form.reset();
  };

  onSaveModal = async data => {
    this.setState({ showModal: false });
    await this.props.actions.changePassword(data.password);
  };

  render() {
    let { user, codes, users, savingUser, roles, auth, location } = this.props;
    let { editing } = this.state;
    // const { user } = this.state;
    return user ? (
      <>
        <div className="header-wrapper">
          <BreadCrumbs
            breadcrumbs={[{ label: "Users" }, { label: user.full_name }]}
            onClick={this.props.history.goBack}
          />
          <div className="flex -right">
            <EditButton onClickEdit={this.handleEdit} auth={auth} />
          </div>
        </div>
        <div className="content-wrapper">
          <div className="flex -right" id="adminUserOptions">
            {user.role.number == 4000 && (
              <>
                <button
                  className="btn right btn-action"
                  onClick={() => this.onClickCourses(user._id)}
                >
                  <FontAwesomeIcon icon={faBook} />
                </button>
                <button
                  className="btn right btn-action"
                  onClick={() => this.onClickCommunityService(user._id)}
                >
                  <FontAwesomeIcon icon={faHandsHelping} />
                </button>
                <button
                  className="btn right btn-action"
                  onClick={() => this.onClickCallLog(user._id)}
                >
                  <FontAwesomeIcon icon={faPhone} />
                </button>
              </>
            )}
          </div>
          <UserForm
            user={user}
            users={users}
            roles={roles}
            codes={codes}
            saving={savingUser}
            editing={editing}
            onSave={this.handleSave}
            onCancel={this.handleCancel}
          />

          {user._id == auth.user._id && (
            <button
              className="btn"
              style={{
                background: "#f15d2a",
                color: "white !important"
              }}
              onClick={this.onClickPasswordReset}
            >
              Change Password
            </button>
          )}
        </div>
        <div className={`modal ${this.state.showModal ? "show" : ""}`}>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Change Password</h2>
            </div>
            <Formsy ref="form" onValidSubmit={this.onSaveModal}>
              <div className="modal-body">
                <Input
                  required
                  type="password"
                  name="password"
                  label="Password"
                  validations="minLength: 8"
                  validationError="Password should be at least 8 characters long."
                />
                <Input
                  required
                  type="password"
                  name="verifyPassword"
                  label="Verify Password"
                  validations="equalsField:password"
                  validationError="Passwords do not match."
                />
              </div>
              <div className="modal-footer">
                <div className="btn-group">
                  <button
                    className="btn btn-action"
                    onClick={this.onCancelModal}
                  >
                    Cancel
                  </button>
                  <button className="btn btn-action" type="submit">
                    Save
                  </button>
                </div>
              </div>
            </Formsy>
          </div>
        </div>
      </>
    ) : (
      <Loader />
    );
  }
}

UserEditPage.propTypes = {
  actions: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  user: PropTypes.object,
  users: PropTypes.array,
  savingUser: PropTypes.bool,
  modal: PropTypes.object,
  roles: PropTypes.array,
  codes: PropTypes.array
};

const mapStateToProps = state => ({
  state: state,
  auth: state.auth,
  users: state.users,
  user: state.user,
  savingUser: state.savingUser,
  location: state.router.location,
  modal: state.modal,
  roles: state.roles,
  codes: state.codes
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        ...userActions,
        ...modalActions,
        ...roleActions,
        ...codeActions
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserEditPage);
