import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";

import * as roleActions from "../../actions/roleActions";
import * as modalActions from "../../actions/modalActions";

import RoleList from "./RoleList";
import RoleForm from "./RoleForm";

import BreadCrumbs from "../common/BreadCrumbs";
import Modal from "../common/Modal";
import ConfirmModal from "../common/ConfirmModal";

import { Row, Col, Button } from "react-bootstrap";
import AddButton from "../common/AddButton";

import find from "lodash/find";

class RolePage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      role: {},
      adding: false
    };
  }

  componentDidMount = async () => {
    await this.props.actions.loadRoles();
  };

  onClickAdd = () => {
    this.setState({ role: {}, adding: true });
    this.props.actions.getRole();
  };

  onClickEdit = roleId => {
    let role = find(this.props.roles, { _id: roleId });
    this.setState({ role, adding: false });
    this.props.actions.getRole(roleId, true);
  };

  onClickDelete = roleId => {
    this.props.actions.requestRoleId(roleId);
  };

  handleAdd = role => {
    this.props.actions.createRole(role);
    this.props.actions.hideModal();
  };

  handleSave = role => {
    let data = {
      _id: this.props.role._id,
      ...role
    };

    this.props.actions.updateRole(data);
    this.props.actions.hideModal();
  };

  handleDelete = () => {
    this.props.actions.deleteRole(this.props.roleToDelete);
  };

  render() {
    let { roles } = this.props;
    let { role, adding } = this.state;

    let roleForm = (
      <RoleForm
        role={role}
        onAdd={this.handleAdd}
        onSave={this.handleSave}
        onCancel={this.props.actions.hideModal}
        saving={this.props.savingRole}
        editing
        adding={adding}
      />
    );

    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs breadcrumbs={[{ label: "Roles" }]} />
          <Col md={5} xs={5} className="flex -right">
            <AddButton onClickAdd={this.onClickAdd} />
          </Col>
        </div>
        <div className="content-wrapper">
          <Col md={12} xs={12}>
            <RoleList
              roles={roles}
              onClickEdit={this.onClickEdit}
              onClickDelete={this.onClickDelete}
            />
          </Col>
          <Modal
            id="roleDetailsModal"
            title={adding ? "Add Role" : "Edit Role"}
            body={roleForm}
            modal={this.props.modal}
            close={this.props.actions.hideModal}
          />
          <ConfirmModal
            id="roleDeleteModal"
            title="Delete Role"
            body="Are you sure you want to delete this role?"
            modal={this.props.modal}
            close={this.props.actions.hideModal}
            confirm={this.handleDelete}
          />
        </div>
      </>
    );
  }
}

RolePage.propTypes = {
  actions: PropTypes.object,
  modal: PropTypes.object,
  savingRole: PropTypes.bool,
  labels: PropTypes.array,
  roleToDelete: PropTypes.string,
  roles: PropTypes.array.isRequired,
  role: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    state: state,
    modal: state.modal,
    savingRole: state.savingRole,
    roleToDelete: state.roleToDelete,
    roles: state.roles,
    role: state.role
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...roleActions, ...modalActions }, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RolePage);
