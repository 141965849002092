import React from "react";
import "./user_avatar.scss";

const UserAvatar = ({ user, hidden, size, editable }) => {
  return (
    <div className={`avatar ${size || ""} ${hidden ? "noShow" : ""}`}>
      <div className="user-icon">
        {user.avatar && <img src={user.avatar} />}
        {!user.avatar && size && user.first_name && user.last_name && (
          <p className="username">
            {user.first_name[0]}
            {user.last_name[0]}
          </p>
        )}
      </div>
      {editable && <span>Edit</span>}
    </div>
  );
};

const MultiUserAvatar = ({ users, hidden, size }) => {
  return (
    <div className="multi">
      {[...users].slice(0, 2).map((user, i) => (
        <UserAvatar key={i} user={user} size={size} />
      ))}
    </div>
  );
};

export { UserAvatar, MultiUserAvatar };
