import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import Formsy from "formsy-react";
import { Input, Textarea } from "formsy-react-components";
import autoBind from "react-autobind";
import FormFooter from "../common/FormFooter";

class RoleForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false
    };
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  add = model => {
    this.props.onAdd(model);
  };

  submit = model => {
    this.props.onSave(model);
  };

  cancel = () => {
    this.props.onCancel();
  };

  render() {
    let { role, adding, saving } = this.props;

    return (
      <Formsy
        className="vertical form"
        onValidSubmit={adding ? this.add : this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Row>
          <Col md={6} xs={12}>
            <Input name="title" type="text" label="Title" value={role.title} />
          </Col>
          <Col md={6} xs={12}>
            <Input name="number" type="number" label="Number" value={role.number} />
          </Col>
        </Row>
        <FormFooter
          cancel={this.cancel}
          saving={saving}
          canSubmit={this.state.canSubmit}
        />
      </Formsy>
    );
  }
}

RoleForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  role: PropTypes.object.isRequired
};

export default RoleForm;
