import * as types from "./actionTypes";
import { showModalSuccess } from "./modalActions";
import { showAlertSuccess, hideAlertSuccess } from "./alertActions";
import communityServiceService from "../services/communityServiceService";

export function loadCommunityServiceSuccess(communityServices) {
  return {
    type: types.LOAD_COMMUNITY_SERVICES_SUCCESS,
    communityServices
  };
}

export function getCommunityServiceSuccess(communityServicePost) {
  return {
    type: types.GET_COMMUNITY_SERVICE_SUCCESS,
    communityServicePost
  };
}

export function getCommunityServiceByCamperIdSuccess(communityService) {
  return {
    type: types.GET_COMMUNITY_SERVICE_BY_CAMPER_ID_SUCCESS,
    communityService
  };
}

export function savingCommunityService(status = true) {
  return {
    type: types.SAVING_COMMUNITY_SERVICE,
    savingCommunityService: status
  };
}

export function createCommunityServiceSuccess(communityService) {
  return {
    type: types.CREATE_COMMUNITY_SERVICE_SUCCESS,
    communityService
  };
}

export function updateCommunityServiceSuccess(communityService) {
  return {
    type: types.UPDATE_COMMUNITY_SERVICE_SUCCESS,
    communityService
  };
}

export function requestCommunityServiceId(communityServiceId) {
  return dispatch => {
    dispatch({
      type: types.REQUEST_COMMUNITY_SERVICE_ID,
      communityServiceToDelete: communityServiceId
    });
    dispatch(showModalSuccess("communityServiceDeleteModal"));
  };
}

export function deleteCommunityServiceSuccess(communityServiceId) {
  return {
    type: types.DELETE_COMMUNITY_SERVICE_SUCCESS,
    communityServiceId
  };
}

export function loadCommunityServices() {
  return dispatch => {
    dispatch(hideAlertSuccess());
    return communityServiceService
      .loadCommunityServices()
      .then(data => dispatch(loadCommunityServiceSuccess(data)))
      .catch(error => dispatch(showAlertSuccess(error, "error")));
  };
}

export function getCommunityService(id, showCOMMUNITY_SERVICEDetails = false) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    if (id) {
      return communityServiceService
        .getCommunityService(id)
        .then(communityService => {
          dispatch(getCommunityServiceSuccess(communityService));
          if (showCOMMUNITY_SERVICEDetails) {
            dispatch(showModalSuccess("communityServiceDetailsModal"));
          }
        })
        .catch(error => dispatch(showAlertSuccess(error.description, "error")));
    } else {
      dispatch(showModalSuccess("communityServiceDetailsModal"));
    }
  };
}

export function getCommunityServiceByCamperId(
  id,
  showCOMMUNITY_SERVICEDetails = false
) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    if (id) {
      return communityServiceService
        .getCommunityServiceByCamperId(id)
        .then(communityService => {
          dispatch(getCommunityServiceByCamperIdSuccess(communityService));
          if (showCOMMUNITY_SERVICEDetails) {
            dispatch(showModalSuccess("communityServiceDetailsModal"));
          }
        })
        .catch(error => dispatch(showAlertSuccess(error.description, "error")));
    } else {
      dispatch(showModalSuccess("communityServiceDetailsModal"));
    }
  };
}

export function createCommunityService(communityService) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingCommunityService(communityService));
    return communityServiceService
      .createCommunityService(communityService)
      .then(createdCommunityService => {
        dispatch(createCommunityServiceSuccess(createdCommunityService));
        dispatch(savingCommunityService(false));
        dispatch(
          showAlertSuccess("CommunityService created successfully", "success")
        );
      })
      .catch(error => {
        dispatch(savingCommunityService(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateCommunityService(communityService) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingCommunityService());
    return communityServiceService
      .updateCommunityService(communityService)
      .then(updatedCommunityService => {
        dispatch(updateCommunityServiceSuccess(updatedCommunityService));
        dispatch(savingCommunityService(false));
        dispatch(
          showAlertSuccess("CommunityService updated successfully", "success")
        );
      })
      .catch(error => {
        dispatch(savingCommunityService(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteCommunityService(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return communityServiceService
      .deleteCommunityService(id)
      .then(() => {
        dispatch(deleteCommunityServiceSuccess(id));
        dispatch(
          showAlertSuccess("CommunityService deleted successfully", "success")
        );
      })
      .catch(error => dispatch(showAlertSuccess(error.description, "error")));
  };
}
