import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const users = (state = initialState.users, action) => {
  switch (action.type) {
    case types.LOAD_USERS_SUCCESS:
      return action.users;

    case types.CREATE_USER_SUCCESS:
      return [...state, Object.assign({}, action.user)];

    case types.UPDATE_USER_SUCCESS:
      return [
        ...state.filter(users => users.id !== action.user._id),
        Object.assign({}, action.user)
      ];

    case types.DELETE_USER_SUCCESS:
      return [...state.filter(user => user._id !== action.userId)];

    default:
      return state;
  }
};

export const user = (state = initialState.user, action) => {
  switch (action.type) {
    case types.GET_USER_SUCCESS:
      return { ...state, ...action.user };
    default:
      return state;
  }
};

export const savingUser = (state = initialState.savingUser, action) => {
  switch (action.type) {
    case types.SAVING_USER:
      return action.savingUser;

    default:
      return state;
  }
};

export const userToView = (state = initialState.userToView, action) => {
  switch (action.type) {
    case types.REQUEST_USER_DETAIL:
      return action.userToView;

    default:
      return state;
  }
};

export const userToDelete = (state = initialState.userToDelete, action) => {
  switch (action.type) {
    case types.REQUEST_USER_DELETE:
      return action.userToDelete;

    default:
      return state;
  }
};

export const auth = (state = initialState.auth, action) => {
  switch (action.type) {
    case types.LOGIN_USER_SUCCESS:
      return Object.assign({}, state, {
        username: action.user.user.email,
        isAuth: true,
        role: action.user.user.role,
        baseUrl: `/app/${action.user.user.role.title.toLowerCase()}`,
        isAdmin: false,
        user: action.user.user
      });

    case types.LOGOUT_USER_SUCCESS:
      return Object.assign({}, state, {
        username: "",
        isAuth: false,
        isAdmin: false,
        role: false,
        user: {}
      });

    case types.CHECK_AUTH_SUCCESS:
      return Object.assign({}, state, {
        username: action.user.email,
        isAuth: true,
        role: action.user.role,
        baseUrl: `/app/${action.user.role.title.toLowerCase()}`,
        isAdmin: false,
        user: action.user
      });

    default:
      return state;
  }
};
