import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import Formsy from "formsy-react";
import { Input } from "formsy-react-components";
import FormFooter from "../common/FormFooter";

class CourseForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false
    };
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  cancel = () => {
    this.props.onCancel();
  };

  render() {
    let { grade, saving, onSubmit } = this.props;

    return (
      <Formsy
        ref="form"
        className="vertical form"
        onValidSubmit={onSubmit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Row>
          <Col md={12} xs={12}>
            <fieldset>
              <legend>Course Details</legend>
            </fieldset>
          </Col>
          <Col md={6} xs={12}>
            <Input
              name="subject"
              label="Subject"
              placeholder="Enter Subject"
              value={grade.subject}
              required
            />
          </Col>
          <Col md={6} xs={12}>
            <Input
              name="instructor"
              label="Instructor"
              placeholder="Enter the Full Name of the Instructor"
              value={grade.instructor}
              required
            />
          </Col>
        </Row>
        <FormFooter
          cancel={this.cancel}
          saving={saving}
          canSubmit={this.state.canSubmit}
        />
      </Formsy>
    );
  }
}

CourseForm.propTypes = {
  onSave: PropTypes.func,
  onAdd: PropTypes.func,
  saving: PropTypes.bool.isRequired,
  grade: PropTypes.object.isRequired
};

export default CourseForm;
