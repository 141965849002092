import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as topicBoardActions from "../../actions/topicBoardActions";
import * as codeActions from "../../actions/codeActions";

import PostForm from "./PostForm";
import BreadCrumbs from "../common/BreadCrumbs";

import isEmpty from "lodash/isEmpty";

class AddPostPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    let { codes, actions } = this.props;
    isEmpty(codes) && actions.loadCodes();
  };

  // create new post
  handleSave = async post => {
    let { auth } = this.props;

    let data = {
      ...post,
      author: auth.user._id
    };

    await this.props.actions.createTopicBoard(data);
    await this.props.history.push("/app/topic-boards");
  };

  render = () => {
    let { auth, codes, saving } = this.props;
    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs
            breadcrumbs={[{ label: "Topic Boards" }, { label: "New Post" }]}
            onClick={this.props.history.goBack}
          />
        </div>
        <div className="content-wrapper">
          <PostForm
            post={{}}
            codes={codes}
            user={auth.user}
            onSave={this.handleSave}
            onCancel={this.handleCancel}
            saving={saving}
          />
        </div>
      </>
    );
  };
}

AddPostPage.propTypes = {
  actions: PropTypes.object,
  saving: PropTypes.bool
};

const mapStateToProps = state => ({
  state: state,
  auth: state.auth,
  codes: state.codes,
  saving: state.savingTopicBoard
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { ...topicBoardActions, ...codeActions },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPostPage);
