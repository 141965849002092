import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Navbar,
  Nav,
  Dropdown,
  NavItem,
  DropdownButton
} from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faUser,
  faCalendarAlt,
  faFileContract,
  faBars,
  faCog,
  faHandsHelping,
  faGraduationCap,
  faDatabase,
  faUserTag,
  faTasks,
  faCommentAlt,
  faClipboard
} from "@fortawesome/free-solid-svg-icons";
import Logout from "../logout/Logout";
import { LinkContainer } from "react-router-bootstrap";
import { UserAvatar } from "../users/UserAvatar";
import image from "../../images/mark.png";

import PathMenu from "./PathMenu";
import startCase from "lodash/startCase";

const navItems = [
  {
    path: "users",
    icon: faUsers,
    role: [8000]
  },
  {
    path: "profile",
    icon: faUser,
    role: [4000, 5000, 6000, 7000]
  },
  {
    path: "directory",
    icon: faDatabase,
    role: [4000, 5000, 6000, 7000]
  },
  {
    path: "events",
    icon: faCalendarAlt,
    role: [4000, 5000, 6000, 7000, 8000]
  },
  {
    path: "tasks",
    icon: faTasks,
    role: [4000, 5000, 6000, 7000, 8000]
  },
  {
    path: "messages",
    icon: faCommentAlt,
    role: [4000, 5000, 6000, 7000, 8000]
  },
  {
    path: "courses",
    icon: faGraduationCap,
    role: [4000]
  },
  {
    path: "topic-boards",
    icon: faClipboard,
    role: [4000, 5000, 6000, 7000, 8000]
  },
  {
    path: "community-service",
    icon: faHandsHelping,
    role: [4000]
  },
  {
    path: "documents",
    icon: faFileContract,
    role: [4000, 5000, 6000, 7000, 8000]
  }
  // {
  //   path: "roles",
  //   icon: faUserTag,
  //   role: [8000]
  // },
  // {
  //   path: "codes",
  //   icon: faCog,
  //   role: [8000]
  // },
];

// const MenuFan = () => {
//   <Motion defaultStyle={{x: 0}} style={{x: spring(10)}}></Motion>
// }

const TabBar = ({ role }) => {
  let filteredItems = navItems
    .filter(item => item.role.includes(role))
    .slice(0, 3);

  const moreItems = navItems
    .filter(item => item.role.includes(role))
    .slice(3, navItems.length);

  let middle = Math.ceil(filteredItems.length / 2);

  filteredItems.splice(middle, 0, <Logout />);

  return (
    <div className="tab-bar">
      {/* <PathMenu /> */}
      {filteredItems.map((item, i) => {
        if (i == middle) {
          return item;
        } else {
          return (
            <NavLink
              key={i}
              to={`/app/${item.path}`}
              activeClassName="is-active"
            >
              <NavItem>
                <FontAwesomeIcon icon={item.icon} />
                <p>{startCase(item.path)}</p>
              </NavItem>
            </NavLink>
          );
        }
      })}
      <NavItem>
        <div className="menu">
          <DropdownButton title={<FontAwesomeIcon icon={faBars} />}>
            {moreItems.map((item, i) => (
              <LinkContainer to={`/app/${item.path}`} key={i + 3}>
                <Dropdown.Item eventKey={i}>
                  {startCase(item.path)}
                </Dropdown.Item>
              </LinkContainer>
            ))}
          </DropdownButton>
        </div>
      </NavItem>
      {/* <Link to="/app/users">
     <NavItem>
       <FontAwesomeIcon color="#f4774b" size="3x" icon={faUsers} />
       <p>Users</p>
     </NavItem>
   </Link>
   <Link to="/app/events">
     <NavItem>
       <FontAwesomeIcon color="#f4774b" size="3x" icon={faCalendar} />
       <p>Calender</p>
     </NavItem>
   </Link>
   <Link to="/app/tasks">
     <NavItem>
       <FontAwesomeIcon color="#f4774b" size="3x" icon={faTasks} />
       <p>Tasks</p>
     </NavItem>
   </Link>
   <Logout />
   <Link to="/app/documents">
     <NavItem>
       <FontAwesomeIcon color="#f4774b" size="3x" icon={faFileContract} />
       <p>Documents</p>
     </NavItem>
   </Link>
   <Link to="/app/codes">
     <NavItem>
       <FontAwesomeIcon color="#f4774b" size="3x" icon={faCog} />
       <p>Codes</p>
     </NavItem>
   </Link>
   <Link to="/app/roles">
     <NavItem>
       <FontAwesomeIcon color="#f4774b" size="3x" icon={faUserTag} />
       <p>Roles</p>
     </NavItem>
   </Link> */}
    </div>
  );
};
TabBar.propTypes = {
  state: PropTypes.object,
  role: PropTypes.number
};

export default TabBar;
