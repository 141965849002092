import io from "socket.io-client";
const socket = io("/");

class SocketEvents {
  static subscribeToNewMessages(cb) {
    socket.on("NEW_MESSAGE", cb);
  }
  static subscribeToNewConversations(cb) {
    socket.on("NEW_CONVERSATION", cb);
  }
  static close() {
    socket.off("NEW_MESSAGE");
    socket.off("NEW_CONVERSATION");
  }
}

export default SocketEvents;
