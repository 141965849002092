import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as userActions from "../../actions/userActions";

import ForgotForm from "./ForgotForm";
import { Row, Col } from "react-bootstrap";

class ForgotPage extends Component {
  handleSave = user => {
    this.props.actions.forgotPassword(user);
  };

  render() {
    return (
      <Row className="login-page">
        <Col md={12} xs={12} className="form-container center">
          <ForgotForm
            onSave={this.handleSave}
            saving={this.props.savingUser}
            login={this.props.login}
          />
        </Col>
      </Row>
    );
  }
}

ForgotPage.propTypes = {
  actions: PropTypes.object.isRequired,
  savingUser: PropTypes.bool,
  login: PropTypes.object
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state,
    savingUser: state.savingUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...userActions }, dispatch)
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ForgotPage);
