import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";

import Formsy from "formsy-react";
import { Textarea, Input } from "formsy-react-components";
import FormFooter from "../common/FormFooter";

class CallCard extends Component {
  constructor(props) {
    super(props);
  }
  render = () => {
    let {
      call,
      cancel,
      submit,
      enableButton,
      disableButton,
      canSubmit
    } = this.props;
    return (
      <Formsy
        className="vertical form"
        onValidSubmit={submit}
        onValid={enableButton}
        onInvalid={disableButton}
      >
        {/* <Row> */}
        {/* <h3>Add New Call</h3> */}
        <Col md={12} xs={12} className="pad-0">
          <Input required name="title" label="Title" value={call.title} />
        </Col>
        <Col md={12} xs={12} className="pad-0">
          <Textarea
            name="description"
            label="Description"
            value={call.description}
            required
          />
        </Col>
        {/* </Row> */}
        <FormFooter cancel={cancel} canSubmit={canSubmit} canSubmit={true} />
      </Formsy>
    );
  };
}

CallCard.propTypes = {
  call: PropTypes.object,
  user: PropTypes.object
};

export default CallCard;
