import React from "react";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import CommentCard from "./CommentCard";

export default function CommentList({
  comments,
  auth,
  onCommentDelete,
  modal,
  handleCommentDelete,
  actions
}) {
  return (
    <>
      <div className="list">
        {!isEmpty(comments) ? (
          <>
            <h3 id="commentListHeading">
              Comments ({!isEmpty(comments) && comments.length})
            </h3>
            {orderBy(comments, "created_on", "desc").map(comment => (
              <CommentCard
                key={comment._id}
                comment={comment}
                user={comment.user}
                auth={auth}
                onCommentDelete={onCommentDelete}
                modal={modal}
                handleCommentDelete={handleCommentDelete}
                actions={actions}
              />
            ))}
          </>
        ) : (
          <h4 className="text-center">No comments</h4>
        )}
      </div>
    </>
  );
}
