import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const communityServices = (
  state = initialState.communityServices,
  action
) => {
  switch (action.type) {
    case types.LOAD_COMMUNITY_SERVICES_SUCCESS:
      return action.communityServices;

    case types.LOAD_COMMUNITY_SERVICES_BY_USER_ID_SUCCESS:
      return action.communityServices;

    case types.CREATE_COMMUNITY_SERVICE_SUCCESS:
      return [...state, Object.assign({}, action.communityService)];

    case types.UPDATE_COMMUNITY_SERVICE_SUCCESS:
      return [
        ...state.filter(
          communityServices =>
            communityServices.id !== action.communityService.id
        ),
        Object.assign({}, action.communityService)
      ];

    case types.DELETE_COMMUNITY_SERVICE_SUCCESS:
      return [
        ...state.filter(
          communityService => communityService.id !== action.communityServiceId
        )
      ];

    default:
      return state;
  }
};

export const communityService = (
  state = initialState.communityService,
  action
) => {
  switch (action.type) {
    case types.GET_COMMUNITY_SERVICE_BY_CAMPER_ID_SUCCESS:
      return action.communityService;

    default:
      return state;
  }
};

export const communityServicePost = (
  state = initialState.communityServicePost,
  action
) => {
  switch (action.type) {
    case types.GET_COMMUNITY_SERVICE_SUCCESS:
      return action.communityServicePost;

    default:
      return state;
  }
};

export const savingCommunityService = (
  state = initialState.savingCommunityService,
  action
) => {
  switch (action.type) {
    case types.SAVING_COMMUNITY_SERVICE:
      return action.savingCommunityService;

    default:
      return state;
  }
};

export const communityServiceToDelete = (
  state = initialState.communityServiceToDelete,
  action
) => {
  switch (action.type) {
    case types.REQUEST_COMMUNITY_SERVICE_ID:
      return action.communityServiceToDelete;

    default:
      return state;
  }
};
