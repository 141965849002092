import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import { withRouter } from "react-router-dom";

import * as userActions from "../../actions/userActions";
import * as communityServiceActions from "../../actions/communityServiceActions";
import * as routeActions from "../../actions/routeActions";

import BreadCrumbs from "../common/BreadCrumbs";
import Searchbar from "../common/Searchbar";
import AddButton from "../common/AddButton";
import { Row, Col } from "react-bootstrap";
import { DIRECTORY, COMMUNITY_SERVICE } from "../../routes/constants";
import CommunityServiceForm from "./CommunityServiceForm";

class AddCommunityServicePage extends Component {
  constructor(props) {
    super(props);
  }

  handleSubmit = async post => {
    await this.props.actions.createCommunityService(post);
    await this.props.history.push("/app/community-service");
  };
  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    let { auth } = this.props;
    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs breadcrumbs={[{ label: "Community Service" }]} />
        </div>
        <div className="content-wrapper">
          <CommunityServiceForm
            handleSubmit={this.handleSubmit}
            auth={auth}
            post={{}}
            handleCancel={this.handleCancel}
          />
        </div>
      </>
    );
  }
}

AddCommunityServicePage.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object,
  modal: PropTypes.object,
  user: PropTypes.object,
  communityService: PropTypes.array,
  projects: PropTypes.array
};

const mapStateToProps = state => ({
  state: state,
  auth: state.auth,
  user: state.user,
  modal: state.modal,
  communityService: state.communityService,
  projects: state.projects
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        ...userActions,
        ...communityServiceActions,
        ...routeActions
      },
      dispatch
    )
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddCommunityServicePage)
);
