import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as userActions from "../../actions/userActions";
import * as roleActions from "../../actions/roleActions";
import * as codeActions from "../../actions/codeActions";

import UserForm from "./UserForm";
import BreadCrumbs from "../common/BreadCrumbs";

class UserAddPage extends Component {
  componentDidMount = () => {
    let { actions } = this.props;

    actions.loadRoles();
    actions.loadUsers();
    actions.loadCodes();
  };

  handleSave = async user => {
    if (user.associated_campers) {
      user.associated_campers = user.associated_campers.map(
        camper => camper.value
      );
    }

    if (!user.avatar) {
      user.avatar = "";
    }

    await this.props.actions.createUser(user);
    await this.props.history.push("/app/users");
  };

  render() {
    let { users, codes, savingUser, roles } = this.props;

    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs
            breadcrumbs={[{ label: "Users" }, { label: "New User" }]}
            onClick={this.props.history.goBack}
          />
        </div>
        <div className="content-wrapper">
          <UserForm
            user={{}}
            users={users}
            roles={roles}
            codes={codes}
            saving={savingUser}
            onSave={this.handleSave}
            onCancel={this.props.history.goBack}
            editing
            adding
          />
        </div>
      </>
    );
  }
}

UserAddPage.propTypes = {
  actions: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  savingUser: PropTypes.bool,
  user: PropTypes.object,
  users: PropTypes.array,
  roles: PropTypes.array,
  codes: PropTypes.codes
};

const mapStateToProps = state => ({
  state: state,
  auth: state.auth,
  user: state.user,
  users: state.users,
  savingUser: state.savingUser,
  roles: state.roles,
  codes: state.codes
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { ...userActions, ...roleActions, ...codeActions },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAddPage);
