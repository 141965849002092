import toastr from "toastr";

export function alertMessage(alert) {
  if (alert.show) {
    switch (alert.message.type) {
      case "success":
        toastr.success(alert.message.content);
        break;
      case "error":
        toastr.error(alert.message.content);
        break;
      default:
        return false;
    }
  }
  return false;
}

export function formatPhone(phoneNumberString) {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  return (
    match &&
    [match[1] ? "+1 " : "", "(", match[2], ") ", match[3], "-", match[4]].join(
      ""
    )
  );
}

export function prettyBytes(num, precision = 3, addSpace = true) {
  const UNITS = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  if (Math.abs(num) < 1) return num + (addSpace ? " " : "") + UNITS[0];
  const exponent = Math.min(
    Math.floor(Math.log10(num < 0 ? -num : num) / 3),
    UNITS.length - 1
  );
  const n = Number(
    ((num < 0 ? -num : num) / 1000 ** exponent).toPrecision(precision)
  );
  return (num < 0 ? "-" : "") + n + (addSpace ? " " : "") + UNITS[exponent];
}
