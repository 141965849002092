import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import createRootReducer from "../reducers";

export const history = createBrowserHistory();

export default function configureStore(initialState) {
  const logger = createLogger({
    predicate: (getState, action) => action.type !== "HIDE_ALERT_SUCCESS"
  });

  const middleware = [thunk, logger]; // order of middleware matters

  const store = createStore(
    createRootReducer(history),
    initialState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middleware // thunk et. al
      )
    )
  );

  // Hot reloading
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers", () => {
      store.replaceReducer(createRootReducer(history));
    });
  }

  return store;
}
