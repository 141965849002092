import React from "react";
import { UserAvatar } from "../users/UserAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/fontawesome-free-solid";
import { Row, Col } from "react-bootstrap";
import ConfirmModal from "../common/ConfirmModal";
import moment from "moment";

const CommentCard = ({
  comment,
  user,
  auth,
  key,
  onCommentDelete,
  modal,
  handleCommentDelete,
  actions
}) => {
  const date = `${moment(comment.created_on).format("MMM DD, YYYY")}`;
  return (
    <div id="comment" className="comment-wrapper" key={key}>
      <Row style={{ width: "100%" }}>
        <Col md={1}>
          {user._id && <UserAvatar user={user} editable={false} size="sm" />}
        </Col>
        <Col md={10}>
          <p>
            <strong>
              {comment.user.first_name} {comment.user.last_name}
            </strong>
          </p>
          <p>
            <small>{date}</small>
          </p>
        </Col>
        <Col md={1}>
          {auth.role.number > 7000 && (
            <button
              className="btn right btn-action"
              onClick={() => onCommentDelete(comment)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          )}
        </Col>
      </Row>
      <Row
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          margin: "5px 0px"
        }}
      >
        <Col md={10}>
          <p>{comment.comment}</p>
        </Col>
      </Row>
      <ConfirmModal
        id="topicBoardCommentDeleteModal"
        title="Delete Comment"
        body="Are you sure you want to delete this comment?"
        modal={modal}
        close={actions.hideModal}
        confirm={() => handleCommentDelete(comment)}
      />
    </div>
  );
};

export default CommentCard;
