import * as endpoints from "./apiEndpoints";
import axios from "axios";

class topicBoardService {
  static loadTopicBoards() {
    const url = `${endpoints.BASE_URL}${endpoints.LOAD_TOPIC_BOARDS}`;
    return axios.get(url).then(response => response.data);
  }

  static getTopicBoard(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_TOPIC_BOARDS}/${id}`;

    return axios.get(url).then(response => response.data);
  }

  static createTopicBoard(post) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_TOPIC_BOARDS}`;
    const body = {
      topicBoard: post
    };

    return axios.post(url, body).then(response => response.data);
  }

  static updateTopicBoard(post, commentId) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_TOPIC_BOARDS}/${post._id}`;
    const body = {
      topicBoard: post,
      commentId: commentId
    };

    return axios.put(url, body).then(response => response.data);
  }

  static deleteTopicBoard(id) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_TOPIC_BOARDS}/${id}`;
    return axios.delete(url).then(response => response.data);
  }
}

export default topicBoardService;
