import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as modalActions from "../../../actions/modalActions";

import Formsy from "formsy-react";
import FormFooter from "../../common/FormFooter";
import DateTimePicker from "../../common/DateTimePicker";
import DropdownSearch from "../../common/DropdownSearch";
import { Row, Col } from "react-bootstrap";
import { Input, Checkbox, Textarea } from "formsy-react-components";

import moment from "moment";
import orderBy from "lodash/orderBy";

class EventForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false,
      events: [],
      invited: "",
      validDateRange: false
    };
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  submit = form => {
    const { description, isPublic, title } = form;
    const { start_time, end_time } = this.state;
    let label = isPublic ? "Public" : "Private";

    let formData = {
      description,
      isPublic: isPublic === true ? true : false,
      title,
      start_time,
      label,
      end_time,
      invited: form.invited
        ? isPublic === true
          ? []
          : form.invited.map(assignedUser => assignedUser.value)
        : []
    };

    this.props.onSave(formData);
  };

  cancel = () => {
    this.props.onCancel();
  };

  selectDate = (date, name) => {
    const { start_time, end_time } = this.state;
    // checks if start time is before current time]
    const validDateRange =
      name === "start_time"
        ? moment(date).isBefore(end_time)
        : moment(start_time).isBefore(date);
    this.setState({
      validDateRange,
      canSubmit: validDateRange,
      [name]: date
    });
  };

  userAssociationList = () => {
    return (
      <DropdownSearch
        name="invited"
        label="Invited"
        disabled={!this.props.editing}
        onChange={this.onChangeSelect}
        isMulti={true}
        value={this.state.invited || this.props.event.invited}
        data={[
          ...orderBy(this.props.users, "full_name")
            .filter(user => user.role.number !== 8000)
            .map(user => ({
              value: user._id,
              label: user.full_name
            }))
        ]}
      />
    );
  };

  onChangeSelect = (field, selected) => {
    this.setState({ [field]: selected });
  };

  render() {
    let { validDateRange, end_time, start_time } = this.state;
    let { event, editing, saving, readOnly } = this.props;
    let startTime = this.state.start_time || event.start_time;
    let endTime = this.state.end_time || event.end_time;
    let isPublic =
      this.state.isPublic || event.isPublic || event.label === "Public"
        ? true
        : false;

    return (
      <Formsy
        ref="form"
        className="vertical form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Row>
          <Col md={readOnly ? 12 : 6} xs={12}>
            <Input
              name="title"
              label="Title"
              placeholder="Title"
              disabled={!editing}
              value={event.title}
              validations="minLength:4"
              validationError="Title is too short"
            />
          </Col>
          {!readOnly && (
            <Col md={6} xs={12}>
              {this.userAssociationList()}
            </Col>
          )}
          <Col md={6} xs={12}>
            <DateTimePicker
              required
              name="start_time"
              label="Start Time"
              disabled={!editing}
              value={startTime}
              onChange={this.selectDate}
            />
            {!validDateRange &&
              !readOnly &&
              start_time !== undefined &&
              end_time !== undefined && (
                <p className="danger-text">
                  End time should be after start time
                </p>
              )}
          </Col>
          <Col md={6} xs={12}>
            <DateTimePicker
              required
              name="end_time"
              label="End Time"
              disabled={!editing}
              value={endTime}
              onChange={this.selectDate}
              minDate={new Date(startTime)}
            />
            {!validDateRange &&
              !readOnly &&
              start_time !== undefined &&
              end_time !== undefined && (
                <p className="danger-text">
                  End time should be after start time
                </p>
              )}
          </Col>
          <Col md={12} xs={12}>
            <Textarea
              type="text"
              name="description"
              label="Description"
              placeholder="Description"
              disabled={!editing}
              value={event.description}
            />
          </Col>
          <Col md={12} xs={12}>
            <Checkbox
              name="isPublic"
              value={isPublic}
              label="Public Event"
              disabled={!editing}
            />
          </Col>
        </Row>
        <FormFooter
          cancel={this.cancel}
          saving={saving}
          readOnly={readOnly}
          canSubmit={this.state.canSubmit}
        />
      </Formsy>
    );
  }
}

EventForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  event: PropTypes.object.isRequired,
  actions: PropTypes.object,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  auth: PropTypes.object,
  users: PropTypes.array,
  editing: PropTypes.bool,
  readOnly: PropTypes.bool,
  adding: PropTypes.bool,
  codes: PropTypes.array,
  users: PropTypes.array
};

const mapStateToProps = state => {
  return {
    state: state,
    auth: state.auth,
    modal: state.modal
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...modalActions }, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventForm);
