import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import * as userActions from "../../actions/userActions";
import * as communityServiceActions from "../../actions/communityServiceActions";
import * as modalActions from "../../actions/modalActions";

import BreadCrumbs from "../common/BreadCrumbs";
import Searchbar from "../common/Searchbar";
import AddButton from "../common/AddButton";
import { Row, Col } from "react-bootstrap";
import CommunityServiceList from "./CommunityServiceList";
import ConfirmModal from "../common/ConfirmModal";

class CommunityServicePage extends Component {
  constructor(props) {
    super(props);
    this.state = { search: "" };
  }

  componentDidMount = () => {
    let { actions, user, match, auth } = this.props;
    let camperId = match.params.id ? match.params.id : auth.user._id;

    if (!user.first_name) actions.getUser(camperId);
    actions.getCommunityServiceByCamperId(camperId);
  };

  //send to add page
  onClickAdd = () => {
    this.props.history.push("/app/community-service/add");
  };

  //on click table row
  onClickView = id => {
    this.props.history.push(`/app/community-service/${id}/edit`);
  };

  //on click edit post
  onClickEdit = id => {
    this.props.history.push(`/app/community-service/${id}/edit`);
  };

  //on click delete post
  onClickDelete = async id => {
    await this.props.actions.requestCommunityServiceId(id);
  };

  //on delete modal confirm
  handleDelete = async () => {
    await this.props.actions.deleteCommunityService(
      this.props.communityServiceToDelete
    );

    let { actions, match, auth } = this.props;
    let camperId = match.params.id ? match.params.id : auth.user._id;

    await actions.getCommunityServiceByCamperId(camperId);
  };

  filter = e => {
    this.setState({ search: e.target.value });
  };

  getLabel = () => {
    let { user, match } = this.props;
    if (!match.params.id) {
      return [{ label: "Community Service" }];
    } else {
      return [{ label: user.full_name }, { label: "Community Service" }];
    }
  };

  render() {
    let { auth, communityService, isMobile } = this.props;
    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs
            breadcrumbs={this.getLabel()}
            onClick={this.props.history.goBack}
          />
          <div>
            <Searchbar onFilter={this.filter} />
            {auth.user.role.number == 4000 && (
              <AddButton onClickAdd={this.onClickAdd} />
            )}
          </div>
        </div>
        <div className="content-wrapper transparent">
          <CommunityServiceList
            communityService={communityService}
            onClickView={this.onClickView}
            onClickEdit={this.onClickEdit}
            onClickDelete={this.onClickDelete}
            isMobile={isMobile}
            auth={auth}
            search={this.state.search}
          />
        </div>
        <ConfirmModal
          id="communityServiceDeleteModal"
          title="Delete Community Service"
          body="Are you sure you want to delete this community service?"
          modal={this.props.modal}
          close={this.props.actions.hideModal}
          confirm={this.handleDelete}
        />
      </>
    );
  }
}

CommunityServicePage.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object,
  modal: PropTypes.object,
  user: PropTypes.object,
  communityService: PropTypes.array
};

const mapStateToProps = state => ({
  state: state,
  auth: state.auth,
  user: state.user,
  modal: state.modal,
  communityService: state.communityService,
  communityServiceToDelete: state.communityServiceToDelete
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        ...userActions,
        ...communityServiceActions,
        ...modalActions
      },
      dispatch
    )
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CommunityServicePage)
);
