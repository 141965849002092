import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MessageInput from "./MessageInput";
import MessageList from "./MessageList";
import * as userActions from "../../actions/userActions";
import * as conversationActions from "../../actions/conversationActions";
import debounce from "lodash/debounce";
import Loader from "@/components/common/Loader";

import { InputGroup, FormControl, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

class Message extends Component {
  constructor(props) {
    super(props);

    this.childRef = React.createRef();
    this.container = React.createRef();

    this.state = { loading: true, limit: 20 };
  }

  async componentDidMount() {
    await this.getConversation();
  }

  componentDidUpdate = async nextProps => {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      await this.getConversation();
    }
  };

  getConversation = async () => {
    await this.props.actions.getConversation(
      this.props.match.params.id,
      this.state.limit
    );
  };
  onScroll = () => {
    let { conversation } = this.props;
    const offset = 100;
    if (this.container.current.scrollTop < offset) {
      if (this.state.limit <= conversation.messages.length) {
        this.setState({ limit: this.state.limit + 20 }, () => {
          this.props.actions.getConversation(
            conversation._id,
            this.state.limit
          );
        });
      }
    }
  };

  sendMessage = async model => {
    let { conversation, auth, actions } = this.props;
    if (model.message.length) {
      const message = {
        content: model.message,
        conversation: conversation._id,
        sender: auth.user._id,
        time_created: new Date()
      };
      await actions.createMessage(message);
      this.childRef.current && this.childRef.current.formsyForm.reset();
    }
  };

  render() {
    let { conversation, loadingConversation, auth, users } = this.props;
    return (
      <div className="message__inner">
        {loadingConversation ? (
          <Loader />
        ) : (
          <MessageList
            loading={loadingConversation}
            ref={this.container}
            conversation={conversation}
            users={users}
            auth={auth}
            onScroll={debounce(() => this.onScroll(), 1000)}
          />
        )}
        <MessageInput
          ref={this.childRef}
          onSubmit={this.sendMessage}
          disabled={loadingConversation}
        />
      </div>
    );
  }
}

Message.propTypes = {
  state: PropTypes.object,
  pathname: PropTypes.string,
  search: PropTypes.string,
  hash: PropTypes.string,
  users: PropTypes.array,
  auth: PropTypes.object,
  register: PropTypes.func,
  client: PropTypes.object,
  actions: PropTypes.object,
  conversation: PropTypes.object,
  loadingConversation: PropTypes.bool,
  match: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  users: state.users,
  conversations: state.conversations,
  conversation: state.conversation
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { ...userActions, ...conversationActions },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Message);
