import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter as twitter,
  faSnapchat as snapchat,
  faInstagram as instagram
} from "@fortawesome/free-brands-svg-icons";
import {
  faMapMarkerAlt,
  faEnvelopeOpen,
  faMobile,
  faBuilding,
  faHome
} from "@fortawesome/fontawesome-free-solid";
import { Button } from "react-bootstrap";

import find from "lodash/find";
import { formatPhone } from "../../helpers";

const social = { twitter, snapchat, instagram };

const createMarkup = address => {
  return { __html: `${address}<br />` };
};

const buildAddress = (user, codes) => {
  let state = find(codes, { _id: user.address.state });
  [
    user.address.street,
    user.address.city,
    state ? state.value : "",
    user.address.zip
  ]
    .filter(x => x && x.length)
    .join(", ");
};

function UserProfile({ user, codes }) {
  const address = user.address ? buildAddress(user, codes) : null;

  const userDetails = [
    {
      icon: faMapMarkerAlt,
      info: address
    },
    { icon: faEnvelopeOpen, info: user.email },
    { icon: faHome, info: formatPhone(user.home_phone) },
    { icon: faMobile, info: formatPhone(user.mobile_phone) }
  ];
  const avatar =
    user.avatar ||
    "https://i0.wp.com/www.winhelponline.com/blog/wp-content/uploads/2017/12/user.png?fit=256%2C256&quality=100&ssl=1";
  console.log(avatar);
  return (
    <div className="user__profile">
      <header>
        <figure className="image round">
          <img src={avatar} />
        </figure>
        <div>
          <h1 className="title">{user.full_name}</h1>
        </div>
        {user.school_district && (
          <p className="subtitle">{user.school_district.value}</p>
        )}
        <div className="button__group">
          {Object.keys(social)
            .filter(key => !!user.social[key].length)
            .map((key, i) => (
              <a
                className="user__profile__button"
                key={key}
                href={`https://${key}.com/${user.social[key]}`}
                target="_blank"
                rel="noopener"
              >
                <FontAwesomeIcon icon={social[key]} />
              </a>
            ))}
        </div>
      </header>
      <hr />
      <section>
        <div className="bio">
          <h3 className="title">Bio</h3>
          {user.biography || ""}
        </div>
      </section>
      <hr />
      <footer>
        <h3 className="title">Contact</h3>
        {/* {Object.keys(user).map((k, i) => {
          return (
            <pre key={i}>
              {k}: {JSON.stringify(user[k])}
            </pre>
          );
        })} */}
        {/* <span
          dangerouslySetInnerHTML={createMarkup(user.address.street_address)}
        /> */}
        <div className="contact-info">
          {userDetails
            .filter(item => item.info)
            .map((item, i) => (
              <li key={i}>
                <FontAwesomeIcon icon={item.icon} />
                {item.info}
              </li>
            ))}
        </div>
      </footer>
    </div>
  );
}

export default UserProfile;
