import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as taskActions from "../../../actions/taskActions";
import * as codeActions from "../../../actions/codeActions";
import * as modalActions from "../../../actions/modalActions";

import TaskDetails from "./TaskDetails";
import TaskItems from "./TaskItems";
import BreadCrumbs from "../../common/BreadCrumbs";
import { Row, Col } from "react-bootstrap";

import isEmpty from "lodash/isEmpty";
import find from "lodash/find";

class ViewTaskPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      editing: false,
      deleting: false,
      task: {}
    };
  }

  componentDidMount = () => {
    let { match, actions } = this.props;
    actions.getTask(match.params.id);
    actions.loadCodes();
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  markCompleted = (e, item) => {
    let { task, codes } = this.props;
    let updatedItem = find(task.items, { id: item.id });

    updatedItem.isComplete = e.target.checked;

    let notYetCompleted = task.items.filter(item => item.isComplete == false);

    if (notYetCompleted.length == 0) {
      let status = find(codes, { field: "status", value: "C" });
      task.status = status._id;
    }

    this.props.actions.updateTask(task);
  };

  render() {
    let { task, isMobile } = this.props;
    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs
            breadcrumbs={[{ label: "Tasks" }, { label: "View" }]}
            onClick={this.props.history.goBack}
          />
        </div>
        <div id="task-view" className="content-wrapper">
          {!isEmpty(task) && (
            <>
              <TaskDetails
                task={task}
                description={task.description}
                isMobile={isMobile}
              />
              <TaskItems
                items={task.items}
                onMarkCompleted={this.markCompleted}
              />
            </>
          )}
        </div>
      </>
    );
  }
}

ViewTaskPage.propTypes = {
  actions: PropTypes.object,
  savingTask: PropTypes.bool,
  task: PropTypes.object,
  users: PropTypes.array,
  auth: PropTypes.object,
  modal: PropTypes.object,
  params: PropTypes.object,
  location: PropTypes.object,
  pathname: PropTypes.string
};

const mapStateToProps = state => {
  return {
    state: state,
    modal: state.modal,
    savingTask: state.savingTask,
    task: state.task,
    auth: state.auth,
    codes: state.codes,
    location: state.router.location,
    pathname: state.router.location.pathname
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        ...taskActions,
        ...codeActions,
        ...modalActions
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewTaskPage);
