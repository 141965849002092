import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Formsy from "formsy-react";

import { Input } from "formsy-react-components";
import { Row, Col } from "react-bootstrap";

class ResetPassForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false
    };
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  submit = model => {
    this.props.onSave(model);
  };

  render() {
    return (
      <Formsy
        ref="form"
        className="reset form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <h1>Reset Password</h1>
        <Input
          formNoValidate
          required
          type="password"
          name="newPassword"
          label="Password"
          placeholder="Password"
          value={""}
        />
        <Input
          formNoValidate
          required
          type="password"
          name="verifyPassword"
          label="Confirm Password"
          validations="equalsField:newPassword"
          validationError="Passwords do not match."
          placeholder="Password"
          value={""}
        />
        <Row className="text-center footer">
          <Link className="btn btn-cancel" to="/">
            Cancel
          </Link>
          <input
            className="btn btn-success"
            type="submit"
            disabled={!this.state.canSubmit}
            value={this.props.saving ? "Resetting... " : "Reset"}
          />
        </Row>
      </Formsy>
    );
  }
}

ResetPassForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired
};

export default ResetPassForm;
