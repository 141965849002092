import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const grades = (state = initialState.grades, action) => {
  switch (action.type) {
    case types.LOAD_GRADES_SUCCESS:
      return action.grades;

    case types.CREATE_GRADE_SUCCESS:
      return [...state, Object.assign({}, action.grade)];

    case types.UPDATE_GRADE_SUCCESS:
      return [
        ...state.filter(grades => grades.id !== action.grade.id),
        Object.assign({}, action.grade)
      ];

    case types.DELETE_GRADE_SUCCESS:
      return [...state.filter(grade => grade.id !== action.gradeId)];

    default:
      return state;
  }
};

export const grade = (state = initialState.grade, action) => {
  switch (action.type) {
    case types.GET_GRADE_SUCCESS:
      return action.grade;

    case types.GET_GRADE_BY_CAMPER_ID_SUCCESS:
      return action.grade;

    default:
      return state;
  }
};

export const savingGrade = (state = initialState.savingGrade, action) => {
  switch (action.type) {
    case types.SAVING_GRADE:
      return action.savingGrade;

    default:
      return state;
  }
};

export const gradeToDelete = (state = initialState.gradeToDelete, action) => {
  switch (action.type) {
    case types.REQUEST_GRADE_ID:
      return action.gradeToDelete;

    default:
      return state;
  }
};
