import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import { withRouter } from "react-router-dom";

import * as userActions from "../../actions/userActions";
import * as communityServiceActions from "../../actions/communityServiceActions";
import * as routeActions from "../../actions/routeActions";

import BreadCrumbs from "../common/BreadCrumbs";
import Searchbar from "../common/Searchbar";
import AddButton from "../common/AddButton";
import { Row, Col } from "react-bootstrap";
import { DIRECTORY, COMMUNITY_SERVICE } from "../../routes/constants";
import CommunityServiceForm from "./CommunityServiceForm";

class EditCommunityServicePage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = async () => {
    await this.props.actions.getCommunityService(this.props.match.params.id);
  };

  //handle submit based on the type of user
  handleSubmit = async data => {
    let post = {
      id: this.props.match.params.id,
      ...data
    };
    await this.props.actions.updateCommunityService(post);

    if (this.props.auth.user.role.number == 8000) {
      let userId = data.user.id;
      await this.props.history.push(`/app/users/${userId}/community-service`);
    } else if (this.props.auth.user.role.number == 4000) {
      await this.props.history.push(`/app/community-service`);
    }
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  getLabel = () => {
    let { user, match } = this.props;
    if (!match.params.id) {
      return [{ label: "Community Service" }];
    } else {
      return [{ label: user.full_name }, { label: "Edit Community Service" }];
    }
  };

  render() {
    let { auth, communityServicePost } = this.props;
    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs breadcrumbs={this.getLabel()} />
        </div>
        <div className="content-wrapper">
          <CommunityServiceForm
            handleSubmit={this.handleSubmit}
            auth={auth}
            post={communityServicePost}
            date={communityServicePost.date}
            handleCancel={this.handleCancel}
          />
        </div>
      </>
    );
  }
}

EditCommunityServicePage.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object,
  modal: PropTypes.object,
  user: PropTypes.object,
  communityServicePost: PropTypes.object,
  communityService: PropTypes.array,
  projects: PropTypes.array
};

const mapStateToProps = state => ({
  state: state,
  auth: state.auth,
  user: state.user,
  modal: state.modal,
  communityServicePost: state.communityServicePost,
  communityService: state.communityService,
  projects: state.projects
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        ...userActions,
        ...communityServiceActions,
        ...routeActions
      },
      dispatch
    )
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditCommunityServicePage)
);
