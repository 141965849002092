// prefilter current user
export function getConversationParticipants(conversationParticipants) {
  const participants = conversationParticipants.map(user => user.full_name);
  return `${participants.splice(0, 3).join(", ")}${
    participants.length > 0
      ? ` + ${participants.length}` +
        " other" +
        (participants.length > 1 ? "s" : "")
      : ""
  }`;
}
