import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const DEVICE_WIDTH = window.screen.width;

const CustomToolbar = ({ toolbar }) => {
  let customToolbar;
  if (toolbar) {
    const goToBack = () => {
      if (toolbar.view === "month") {
        toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      } else if (toolbar.view === "week") {
        toolbar.date.setDate(toolbar.date.getDate() - 7);
      } else if (toolbar.view === "day") {
        toolbar.date.setDate(toolbar.date.getDate() - 1);
      } else if (toolbar.view === "agenda") {
        toolbar.date.setDate(toolbar.date.getDate() - 30);
      }
      toolbar.onNavigate("prev");
    };

    const goToNext = () => {
      if (toolbar.view === "month") {
        toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      } else if (toolbar.view === "week") {
        toolbar.date.setDate(toolbar.date.getDate() + 7);
      } else if (toolbar.view === "day") {
        toolbar.date.setDate(toolbar.date.getDate() + 1);
      } else if (toolbar.view === "agenda") {
        toolbar.date.setDate(toolbar.date.getDate() + 30);
      }
      toolbar.onNavigate("next");
    };

    const goToCurrent = () => {
      const now = new Date();
      if (toolbar.view == "agenda") {
        toolbar.date.setMonth(now.getMonth());
        toolbar.date.setYear(now.getFullYear());
        toolbar.date.setDate(1);
      } else {
        toolbar.date.setMonth(now.getMonth());
        toolbar.date.setDate(now.getDate());
        toolbar.date.setYear(now.getFullYear());
      }
      toolbar.onNavigate("current");
    };

    const renderLabel = view => {
      switch (view) {
        case "day":
          return (
            <span>
              {moment(toolbar.date).format("dddd, MMMM ")}
              <strong>{toolbar.date.getDate()}</strong>
            </span>
          );
        case "week":
          return <span>{toolbar.label}</span>;
        case "agenda":
          return <span>{toolbar.label}</span>;
        default:
          return (
            <span>
              {moment(toolbar.date).format("MMMM")}&nbsp;
              <strong>{toolbar.date.getFullYear()}</strong>
            </span>
          );
      }
    };

    // renders a custom toolbar based on device width.  JSX used to style column elements
    const renderToolbar = () => {
      if (DEVICE_WIDTH > 767) {
        return (
          <div className="">
            <div
              // md={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <span className="toolbar-label">{renderLabel(toolbar.view)}</span>
            </div>

            <Row style={{ margin: "20px 0" }}>
              <Col
                md={3}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingLeft: 0
                }}
              >
                <Button className="toolbar-button" onClick={goToBack}>
                  <FontAwesomeIcon icon={faChevronLeft} className="icon" />
                </Button>
                <div className="spacer" />
                <Button className="toolbar-button" onClick={goToNext}>
                  <FontAwesomeIcon icon={faChevronRight} className="icon" />
                </Button>
              </Col>
              <Col
                md={6}
                style={{ display: "flex", justifyContent: "center" }}
                className="views"
              >
                <Button
                  onClick={() => toolbar.onView("day")}
                  className={
                    toolbar.view == "day"
                      ? "toolbar-button-active"
                      : "toolbar-button"
                  }
                >
                  Day
                </Button>
                <div className="spacer" />
                <Button
                  onClick={() => toolbar.onView("week")}
                  className={
                    toolbar.view == "week"
                      ? "toolbar-button-active"
                      : "toolbar-button"
                  }
                >
                  Week
                </Button>
                <div className="spacer" />
                <Button
                  onClick={() => toolbar.onView("month")}
                  className={
                    toolbar.view == "month"
                      ? "toolbar-button-active"
                      : "toolbar-button"
                  }
                >
                  Month
                </Button>
                <div className="spacer" />
                <Button
                  onClick={() => toolbar.onView("agenda")}
                  className={
                    toolbar.view == "agenda"
                      ? "toolbar-button-active"
                      : "toolbar-button"
                  }
                >
                  Agenda
                </Button>
              </Col>
              <Col
                md={3}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: 0
                }}
              >
                <Button className="toolbar-button" onClick={goToCurrent}>
                  Today
                </Button>
              </Col>
            </Row>
          </div>
        );
      } else {
        return (
          <div>
            <Row>
              <Col
                xs={12}
                md={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <span className="toolbar-label">
                  {renderLabel(toolbar.view)}
                </span>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                md={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button className="toolbar-button" onClick={goToBack}>
                  <FontAwesomeIcon icon={faChevronLeft} className="icon" />
                </Button>
                <div className="spacer" />
                <Button className="toolbar-button" onClick={goToNext}>
                  <FontAwesomeIcon icon={faChevronRight} className="icon" />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                md={12}
                style={{ display: "flex", justifyContent: "center" }}
                className="views"
              >
                <Button className="toolbar-button" onClick={goToCurrent}>
                  Today
                </Button>
                <div className="spacer" />
                <Button
                  onClick={() => toolbar.onView("day")}
                  className={
                    toolbar.view == "day"
                      ? "toolbar-button-active"
                      : "toolbar-button"
                  }
                >
                  Day
                </Button>
                <div className="spacer" />
                <Button
                  onClick={() => toolbar.onView("week")}
                  className={
                    toolbar.view == "week"
                      ? "toolbar-button-active"
                      : "toolbar-button"
                  }
                >
                  Week
                </Button>
                <div className="spacer" />
                <Button
                  onClick={() => toolbar.onView("month")}
                  className={
                    toolbar.view == "month"
                      ? "toolbar-button-active"
                      : "toolbar-button"
                  }
                >
                  Month
                </Button>
              </Col>
            </Row>
          </div>
        );
      }
    };
    customToolbar = renderToolbar();
  }

  return <div>{customToolbar}</div>;
};

CustomToolbar.propTypes = {
  toolbar: PropTypes.object
};

export default CustomToolbar;
