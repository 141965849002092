import React from "react";
import PropTypes from "prop-types";
import BigCalendar from "react-big-calendar";
import CustomToolbar from "./CustomToolbar";
import moment from "moment";

const localizer = BigCalendar.momentLocalizer(moment);

const CalendarComponent = ({ events, onSelectEvent, onMoveEvent }) => {
  // extends the calendars toolbar
  const customToolbar = toolbar => {
    return <CustomToolbar toolbar={toolbar} />;
  };
  // put our events array in the proper format for react-big-calendar
  let calenderEvents;
  if (events) {
    calenderEvents = events.map(event => {
      return {
        ...event,
        title: `${event.title}`,
        start: new Date(event.start_time)
          ? new Date(event.start_time)
          : new Date(event.date),
        end: new Date(event.end_time)
          ? new Date(event.end_time)
          : moment(new Date(event.date)).add(1, "days"),
        description: event.description ? event.description : ""
      };
    });
  } else {
    calenderEvents = [];
  }

  return (
    <BigCalendar
      selectable
      localizer={localizer}
      events={calenderEvents}
      defaultView="month"
      defaultDate={new Date()}
      onSelectEvent={onSelectEvent}
      components={{ toolbar: customToolbar }}
      eventPropGetter={event => {
        let backgroundColor = "event-";
        event.isPublic || event.label === "Public"
          ? (backgroundColor = backgroundColor + "public")
          : (backgroundColor = backgroundColor + "private");
        return {
          className: backgroundColor
        };
      }}
    />
  );
};

CalendarComponent.propTypes = {
  events: PropTypes.array.isRequired,
  codes: PropTypes.array,
  event: PropTypes.object,
  onSelectEvent: PropTypes.func,
  onMoveEvent: PropTypes.func
};

export default CalendarComponent;
