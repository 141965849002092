import React, { Component } from "react";
import windowSize from "react-window-size";

import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

class AddButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { onClickAdd } = this.props;
    return this.props.windowWidth >= 768 ? (
      <Button className="btn right btn-action" onClick={onClickAdd}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    ) : (
      <Button className="btn right btn-action" onClick={onClickAdd}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    );
  }
}

export default windowSize(AddButton);
