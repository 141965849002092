import * as types from "./actionTypes";
import { showModalSuccess } from "./modalActions";
import { showAlertSuccess, hideAlertSuccess } from "./alertActions";
import conversationService from "../services/conversationService";

export function loadConversationSuccess(conversations) {
  return {
    type: types.LOAD_CONVERSATION_SUCCESS,
    conversations
  };
}

export function getConversationSuccess(conversation) {
  return {
    type: types.GET_CONVERSATION_SUCCESS,
    conversation
  };
}

export function getMessagesSuccess(messages) {
  return {
    type: types.GET_MESSAGES_SUCCESS,
    messages
  };
}

export function loadingConversation(status = true) {
  return {
    type: types.LOADING_CONVERSATION,
    loadingConversation: status
  };
}

export function savingConversation(status = true) {
  return {
    type: types.SAVING_CONVERSATION,
    savingConversation: status
  };
}

export function createConversationSuccess(conversation) {
  return {
    type: types.CREATE_CONVERSATION_SUCCESS,
    conversation
  };
}

export function updateConversationSuccess(conversation) {
  return {
    type: types.UPDATE_CONVERSATION_SUCCESS,
    conversation
  };
}

export function requestConversationId(conversationId) {
  return dispatch => {
    dispatch({
      type: types.REQUEST_CONVERSATION_ID,
      conversationToDelete: conversationId
    });
    dispatch(showModalSuccess("conversationDeleteModal"));
  };
}

export function deleteConversationSuccess(conversationId) {
  return {
    type: types.DELETE_CONVERSATION_SUCCESS,
    conversationId
  };
}

export function createMessageSuccess(conversation) {
  return {
    type: types.CREATE_MESSAGE_SUCCESS,
    conversation
  };
}

export function loadConversations(userId) {
  return dispatch => {
    dispatch(hideAlertSuccess());
    return conversationService
      .loadConversations(userId)
      .then(data => dispatch(loadConversationSuccess(data)))
      .catch(error => {
        console.log("error", error);
        dispatch(showAlertSuccess(error, "error"));
      });
  };
}

export function getConversation(id, limit) {
  return (dispatch, getState) => {
    dispatch(loadingConversation());
    dispatch(hideAlertSuccess());
    return conversationService
      .getConversation(id, limit)
      .then(conversation => {
        dispatch(getConversationSuccess(conversation));
        dispatch(loadingConversation(false));
      })
      .catch(error => dispatch(showAlertSuccess(error, "error")));
  };
}

export function createConversation(participants, message) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingConversation());
    return conversationService
      .createConversation(participants, message)
      .then(createdConversation => {
        dispatch(createConversationSuccess(createdConversation));
        dispatch(savingConversation(false));
      })
      .catch(error => {
        dispatch(savingConversation(false));
        dispatch(showAlertSuccess(error, "error"));
      });
  };
}

export function updateConversation(conversation) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingConversation());
    return conversationService
      .updateConversation(conversation)
      .then(updatedConversation => {
        dispatch(updateConversationSuccess(updatedConversation));
        dispatch(savingConversation(false));
        dispatch(
          showAlertSuccess("Conversation updated successfully", "success")
        );
      })
      .catch(error => {
        dispatch(savingConversation(false));
        dispatch(showAlertSuccess(error, "error"));
      });
  };
}

export function deleteConversation(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return conversationService
      .deleteConversation(id)
      .then(() => {
        dispatch(deleteConversationSuccess(id));
        dispatch(
          showAlertSuccess("Conversation deleted successfully", "success")
        );
      })
      .catch(error => dispatch(showAlertSuccess(error, "error")));
  };
}

export function createMessage(message) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingConversation());
    return conversationService
      .createMessage(message)
      .then(createdMessage => {
        dispatch(createMessageSuccess(createdMessage));
        dispatch(savingConversation(false));
      })
      .catch(error => {
        dispatch(savingConversation(false));
        dispatch(showAlertSuccess(error, "error"));
      });
  };
}
