import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const tasks = (state = initialState.tasks, action) => {
  switch (action.type) {
    case types.LOAD_TASKS_SUCCESS:
      return action.tasks;

    case types.LOAD_TASKS_BY_USER_ID_SUCCESS:
      return action.tasks;

    case types.CREATE_TASK_SUCCESS:
      return [...state, Object.assign({}, action.task)];

    case types.UPDATE_TASK_SUCCESS:
      return [
        ...state.filter(tasks => tasks.id !== action.task.id),
        Object.assign({}, action.task)
      ];

    case types.DELETE_TASK_SUCCESS:
      return [...state.filter(task => task.id !== action.taskId)];

    default:
      return state;
  }
};

export const taskGroup = (state = initialState.taskGroup, action) => {
  switch (action.type) {
    case types.GET_TASK_GROUP_SUCCESS:
      return action.taskGroup;

    default:
      return state;
  }
};

export const task = (state = initialState.task, action) => {
  switch (action.type) {
    case types.GET_TASK_SUCCESS:
      return Object.assign({}, state, {
        ...action.task,
        assigned_to: action.task.assigned_to.map(user => {
          return {
            value: user._id,
            label: user.full_name
          };
        })
      });

    default:
      return state;
  }
};

export const savingTask = (state = initialState.savingTask, action) => {
  switch (action.type) {
    case types.SAVING_TASK:
      return action.savingTask;

    default:
      return state;
  }
};

export const taskToDelete = (state = initialState.taskToDelete, action) => {
  switch (action.type) {
    case types.REQUEST_TASK_ID:
      return action.taskToDelete;

    default:
      return state;
  }
};
