import * as endpoints from "./apiEndpoints";
import axios from "axios";

class CommunityServiceService {
    static loadCommunityServices() {
        const url = `${endpoints.BASE_URL}${endpoints.LOAD_COMMUNITY_SERVICES}`;
        return axios.get(url).then(response => response.data);
    }

    static getCommunityService(id) {
        const url = `${endpoints.BASE_URL}${endpoints.GET_COMMUNITY_SERVICE}/${id}`;
        return axios.get(url).then(response => response.data);
    }

    static getCommunityServiceByCamperId(id) {
        const url = `${endpoints.BASE_URL}${endpoints.GET_COMMUNITY_SERVICE}${
      endpoints.BY_ASSC_CAMPER
    }/${id}`;
        console.log("url: ", url);
        return axios.get(url).then(response => response.data);
    }

    static createCommunityService(communityService) {
        const url = `${endpoints.BASE_URL}${endpoints.POST_COMMUNITY_SERVICE}`;
        const body = {
            communityService: communityService
        };

        return axios.post(url, body).then(response => response.data);
    }

    static updateCommunityService(communityService) {
        const url = `${endpoints.BASE_URL}${endpoints.PUT_COMMUNITY_SERVICE}/${
      communityService.id
    }`;
        const body = {
            communityService: communityService
        };

        return axios.put(url, body).then(response => response.data);
    }

    static deleteCommunityService(id) {
        const url = `${endpoints.BASE_URL}${
      endpoints.DELETE_COMMUNITY_SERVICE
    }/${id}`;
        return axios.delete(url).then(response => response.data);
    }
}

export default CommunityServiceService;