export const initialUserState = {
  first_name: "",
  last_name: "",
  email: "",
  biography: "",
  role: {},
  address: {
    street: "",
    city: "",
    zip: "",
    state: ""
  },
  school_district: "",
  associated_campers: [],
  social: {
    snapchat: "",
    twitter: "",
    instagram: ""
  },
  suffix: "",
  mobile_phone: "",
  work_phone: "",
  home_phone: ""
};
export default {
  alert: {
    message: {
      content: "",
      type: ""
    },
    show: false
  },
  modal: {
    id: "",
    show: false
  },
  savingUser: false,
  userToDelete: "",
  userToView: "",
  users: [],
  user: initialUserState,
  auth: {
    username: "",
    user: initialUserState,
    role: "",
    baseUrl: "",
    isAuth: false,
    isAdmin: false,
    isBrowser: false,
    resetSent: false
  },
  roles: [],
  role: {},
  savingRole: false,
  roleToDelete: "",
  codes: [],
  code: {},
  savingCode: false,
  codeToDelete: "",
  events: [],
  publicEvents: [],
  event: {
    invites: []
  },
  savingEvent: false,
  eventToDelete: "",
  logs: [],
  tasks: [],
  task: {
    assigned_to: [],
    assigned_date: "",
    due_date: "",
    completed_date: "",
    title: "",
    description: "",
    items: [],
    status: "",
    user: {}
  },
  savingTask: false,
  taskToDelete: "",
  taskGroup: [],
  grade: {},
  grades: [],
  savingGrade: false,
  gradeToDelete: "",
  communityService: [],
  communityServices: [],
  communityServicePost: {},
  conversations: [],
  conversation: {},
  loadingConversation: false,
  messages: [],
  savingCommunityService: false,
  communityServiceToDelete: "",
  topicBoards: [],
  topicBoardPost: { comments: [], author: {}, topic: {} },
  topicBoardToDelete: {},
  savingTopicBoard: false,
  documents: [],
  document: {},
  documentToDelete: ""
};
