import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";

import * as eventActions from "../../actions/eventActions";
import * as codeActions from "../../actions/codeActions";
import * as modalActions from "../../actions/modalActions";
import * as userActions from "../../actions/userActions";

import EventForm from "./forms/EventForm";
import BreadCrumbs from "../common/BreadCrumbs";
import ConfirmModal from "../common/ConfirmModal";
import { Row, Col } from "react-bootstrap";

import isEmpty from "lodash/isEmpty";

class AddEventPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      editing: true
    };

    props.actions.loadUsers();
    props.actions.loadCodes();
    autoBind(this);
  }

  handleCancel = () => {
    this.props.history.goBack();
  };

  handleSave = async event => {
    let { auth } = this.props;
    let eventLabel = event.isPublic ? "Public" : "Private";
    let data = {
      ...event,
      user_id: auth.user._id,
      label: eventLabel
    };

    console.log("event: ", event);

    await this.props.actions.createEvent(data);
    await this.props.history.push(`/app/events`);
  };

  onClickSave = event => {
    this.handleSave(event);
  };

  handleEnterTime = () => {
    this.props.actions.showModal("eventDetailsModal");
  };

  render() {
    let { auth, users, codes } = this.props;

    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs
            breadcrumbs={[
              { label: "Events", to: "/app/events" },
              { label: "Add Event" }
            ]}
          />
        </div>
        <div className="content-wrapper">
          <EventForm
            event={{}}
            users={users}
            auth={auth}
            codes={codes}
            saving={this.props.savingEvent}
            onSave={this.onClickSave}
            onCancel={this.handleCancel}
            editing={this.state.editing}
          />
          <ConfirmModal
            id="eventDeleteModal"
            title="Save Event"
            body="Are you sure you want to save this event? Once it is completed you will not be able to make edits."
            modal={this.props.modal}
            close={this.props.actions.hideModal}
            confirm={this.handleSave}
          />
        </div>
      </>
    );
  }
}

AddEventPage.propTypes = {
  auth: PropTypes.object,
  actions: PropTypes.object,
  savingEvent: PropTypes.bool,
  event: PropTypes.object,
  events: PropTypes.array,
  users: PropTypes.array,
  isMobile: PropTypes.bool,
  codes: PropTypes.array
};

const mapStateToProps = state => {
  return {
    state: state,
    modal: state.modal,
    auth: state.auth,
    savingEvent: state.savingEvent,
    events: state.events,
    codes: state.codes,
    users: state.users
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        ...eventActions,
        ...modalActions,
        ...userActions,
        ...codeActions
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEventPage);
