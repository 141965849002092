import * as types from "./actionTypes";
import { showAlertSuccess, hideAlertSuccess } from "./alertActions";
import { showModalSuccess } from "./modalActions";

import documentService from "../services/documentService";

export function uploadFileSuccess(uploadedFile) {
  return { type: types.UPLOAD_FILE_SUCCESS, uploadedFile };
}

export function loadDocumentsSuccess(documents) {
  return { type: types.LOAD_DOCUMENTS_SUCCESS, documents };
}

export function getDocumentSuccess(document) {
  return { type: types.GET_DOCUMENT_SUCCESS, document };
}

export function deleteDocumentSuccess(document) {
  return { type: types.DELETE_DOCUMENT_SUCCESS, document };
}

export function savingDocument(status = true) {
  return { type: types.SAVING_DOCUMENT, savingDocument: status };
}

export function loadingDocument(status = true) {
  return { type: types.LOADING_DOCUMENT, loadingDocument: status };
}

export function createDocumentSuccess(document) {
  return { type: types.CREATE_DOCUMENT_SUCCESS, document };
}

export function updateDocumentSuccess(document) {
  return { type: types.UPDATE_DOCUMENT_SUCCESS, document };
}

export function uploadingFile(status = true) {
  return {
    type: types.UPLOADING_FILE,
    uploadingFile: status
  };
}

export function requestDocumentId(docId) {
  return dispatch => {
    dispatch(showModalSuccess("documentDeleteModal"));
    dispatch({
      type: types.REQUEST_DOCUMENT_DELETE,
      documentToDelete: docId
    });
  };
}

export function getDocument(id, showDOCUMENTDetails = false) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return documentService
      .getDocument(id)
      .then(doc => {
        dispatch(getDocumentSuccess(doc));
        if (showDOCUMENTDetails) {
          dispatch(showModalSuccess("documentDetailsModal"));
        }
      })
      .catch(error => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function createDocument(document) {
  return dispatch => {
    dispatch(hideAlertSuccess());
    dispatch(savingDocument());
    return documentService
      .createDocument(document)
      .then(createdDocument => {
        dispatch(createDocumentSuccess(createdDocument));
        dispatch(savingDocument(false));
        dispatch(
          showAlertSuccess("Document uploaded successfully.", "success")
        );
      })
      .catch(error => {
        dispatch(savingDocument(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateDocument(document) {
  return dispatch => {
    dispatch(hideAlertSuccess());
    dispatch(savingDocument());
    return documentService
      .updateDocument(document)
      .then(updatedDocument => {
        dispatch(updateDocumentSuccess(updatedDocument));
        dispatch(savingDocument(false));
        dispatch(showAlertSuccess("Document updated successfully.", "success"));
      })
      .catch(error => {
        dispatch(savingDocument(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteDocument(documentId) {
  return dispatch => {
    dispatch(hideAlertSuccess());
    dispatch(savingDocument());
    return documentService
      .deleteDocument(documentId)
      .then(deletedDocument => {
        dispatch(deleteDocumentSuccess(deletedDocument));
        dispatch(savingDocument(false));
        dispatch(showAlertSuccess(`Deleted document successfully`, "success"));
      })
      .catch(err => {
        console.warn("error", error);
        dispatch(savingDocument(false));
        dispatch(showAlertSuccess(error, "error"));
      });
  };
}
export function loadDocuments() {
  return dispatch => {
    return documentService
      .loadDocuments()
      .then(({ data }) => dispatch(loadDocumentsSuccess(data)))
      .catch(err => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function uploadFile(files) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(uploadingFile());
    return documentService
      .uploadFile(files)
      .then(uploadedFile => {
        dispatch(uploadFileSuccess(uploadedFile));
        dispatch(uploadingFile(false));
        dispatch(
          showAlertSuccess(
            `${files.length} ${
              files.length == 1 ? "File" : "Files"
            } uploaded successfully`,
            "success"
          )
        );
      })
      .catch(error => {
        console.warn("error", error);
        dispatch(uploadingFile(false));
        dispatch(showAlertSuccess(error, "error"));
      });
  };
}
