import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const logs = (state = initialState.logs, action) => {
  switch (action.type) {
    case types.LOAD_LOG_SUCCESS:
      return action.logs;

    default:
      return state;
  }
};
