import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as modalActions from "../../actions/modalActions";
import { Row, Col, Button } from "react-bootstrap";

import moment from "moment";

// Displays the event information for non admin users.
class NonAdminEventView extends Component {
  cancel = () => {
    this.props.onCancel();
  };

  // function that displays event attendees
  getAttendees = event => {
    let attendees = "";
    if (event.label === "Private" || !event.isPublic) {
      if (event.invited !== undefined && event.invited.length > 0) {
        for (let user in event.invited) {
          attendees += `${event.invited[user].label}, `;
        }
      }
    }
    // in the event that a calendar event is public and has no attendees, display a message
    if (attendees === "") {
      attendees = "This event is open to everyone.  ";
    }
    return <p>{attendees.substring(0, attendees.length - 2)}</p>;
  };

  render() {
    let { event } = this.props;
    return (
      <>
        <Row>
          <div className="card-header">
            <h3>{event.title}</h3>
            <small>
              {moment(event.start_time).format("MMM DD YYYY, h:mm a")}
            </small>
            <small>
              {` \u21D2 `}
              {moment(event.end_time).format("MMM DD YYYY, h:mm a")}
            </small>
          </div>
        </Row>
        <Row>
          <div className="singleEventDescription">
            <p>{event.description || "No description"}</p>
          </div>
        </Row>
        <Row>
          <Col>
            <h4>Attendees:</h4>
          </Col>
        </Row>
        <Row>
          <Col>{this.getAttendees(event)}</Col>
        </Row>
        <Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              margin: "10px 0px"
            }}
          >
            <Button onClick={this.cancel} id="singleEventCloseBtn">
              Close
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}

NonAdminEventView.propTypes = {
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  event: PropTypes.object.isRequired,
  actions: PropTypes.object,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  auth: PropTypes.object,
  users: PropTypes.array,
  editing: PropTypes.bool,
  readOnly: PropTypes.bool,
  adding: PropTypes.bool,
  codes: PropTypes.array,
  users: PropTypes.array
};

const mapStateToProps = state => {
  return {
    state: state,
    auth: state.auth,
    modal: state.modal
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...modalActions }, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NonAdminEventView);
