import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as userActions from "../../actions/userActions";
import * as codeActions from "../../actions/codeActions";
import * as modalActions from "../../actions/modalActions";

import BreadCrumbs from "../common/BreadCrumbs";
import EditButton from "../common/EditButton";
import Modal from "../common/Modal";

import ProfileForm from "./ProfileForm";
import UserProfile from "./UserProfile";

import isEmpty from "lodash/isEmpty";

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = { user: {}, editing: false };
  }

  componentDidMount = async () => {
    let { auth, match, actions } = this.props;
    await actions.getUser(match.params.id || auth.user._id);
    await actions.loadCodes();
  };

  componentWillReceiveProps = nextProps => {
    let { auth, match, actions } = nextProps;
    if (this.props.location.pathname !== nextProps.location.pathname) {
      actions.getUser(match.params.id || auth.user._id);
    }

    if (
      nextProps.user !== this.props.user ||
      nextProps.auth !== this.props.auth
    ) {
      this.setState({ user: nextProps.user });
    }
  };

  handleEdit = () => {
    this.setState({ editing: true });
  };

  cancel = () => {
    this.setState({ editing: false });
  };

  handleSave = async form => {
    let user = {
      ...this.props.auth.user,
      ...form,
      address: {
        street: form.street,
        state: form.state,
        zip: form.zip,
        city: form.city
      },
      social: {
        snapchat: form.snapchat,
        twitter: form.twitter,
        instagram: form.instagram
      }
    };

    await this.props.actions.updateUser(user);
    await this.props.actions.hideModal();
    await this.props.actions.getUser(this.props.auth.user._id);
    this.setState({ editing: false });
  };

  getLabel = () => {
    let { user, match } = this.props;
    if (!match.params.id) {
      return [{ label: user.full_name }];
    } else {
      return [{ label: "Directory" }, { label: user.full_name }];
    }
  };

  render = () => {
    let { auth, savingUser, codes, actions } = this.props;
    let { user } = this.state;

    const profileForm = (
      <ProfileForm
        user={user}
        codes={codes}
        savingUser={savingUser}
        onSave={this.handleSave}
        onCancel={this.cancel}
        actions={actions}
      />
    );

    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs
            breadcrumbs={this.getLabel()}
            onClick={this.props.history.goBack}
          />
          <div className="flex -right">
            {auth.user._id == user._id && (
              <EditButton onClickEdit={this.handleEdit} auth={auth} />
            )}
          </div>
        </div>
        <div className="content-wrapper">
          {!isEmpty(user) && this.state.editing == false && (
            <UserProfile user={user} codes={codes} />
          )}
          {this.state.editing == true && profileForm}
        </div>
      </>
    );
  };
}

ProfilePage.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object,
  baseUrl: PropTypes.string,
  pathname: PropTypes.string
};

const mapStateToProps = state => ({
  state: state,
  auth: state.auth,
  user: state.user,
  savingUser: state.savingUser,
  modal: state.modal,
  codes: state.codes
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { ...userActions, ...codeActions, ...modalActions },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePage);
