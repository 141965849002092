import React from "react";
import { Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import PrivateRoute from "./PrivateRoute";
import Sidebar from "../components/nav/Sidebar";
import TabBar from "../components/nav/TabBar";

import ProfilePage from "../components/profile/ProfilePage";
import DirectoryPage from "../components/directory/DirectoryPage";
import EventPage from "../components/event/EventPage";
import EditEventPage from "../components/event/EditEventPage";
import TasksPage from "../components/tasks/TasksPage";
import ViewTaskPage from "../components/tasks/view/ViewTaskPage";
import CoursesPage from "../components/grades/courses/CoursesPage";
import MessagesPage from "../components/messages/MessagesPage";
import CommunityServicePage from "../components/community-service/CommunityServicePage";
import TopicBoardsPage from "../components/boards/TopicBoardsPage";
import BoardPostPage from "../components/boards/BoardPostPage";

// MESSAGES
import { messagingRoutes } from "./MessagingRoutes";
import { RouteWithChildRoutes } from "./RouteWithChildRoutes";

// DOCUMENTS
import DocumentPage from "../components/documents/DocumentPage";

/* SCHOOL ROLE
 * View profile
 * View associated Campers
 * View tasks and campers' tasks
 * View campers' courses
 */

const SchoolRoutes = ({ auth, isMobile }) => {
  const routeProps = { exact: true, auth, isMobile };

  return (
    <>
      {!isMobile ? (
        <Sidebar role={auth.role.number} />
      ) : (
        <TabBar role={auth.role.number} />
      )}
      <Switch>
        <Redirect exact from="/app" to="/app/profile" />
        <PrivateRoute
          {...routeProps}
          path={`/app/profile`}
          component={ProfilePage}
          auth={auth}
        />
        <PrivateRoute
          {...routeProps}
          path={`/app/directory`}
          component={DirectoryPage}
          auth={auth}
        />
        <PrivateRoute
          path="/app/directory/:id"
          component={ProfilePage}
          {...routeProps}
        />
        <PrivateRoute
          {...routeProps}
          path="/app/documents"
          component={DocumentPage}
        />
        {/* Tasks */}
        <PrivateRoute
          {...routeProps}
          path="/app/tasks"
          component={TasksPage}
          auth={auth}
          isMobile={isMobile}
        />
        <PrivateRoute
          {...routeProps}
          path="/app/tasks/:id"
          component={ViewTaskPage}
          auth={auth}
          isMobile={isMobile}
        />
        <PrivateRoute
          {...routeProps}
          path={`/app/events`}
          component={EventPage}
        />
        <PrivateRoute
          path="/app/events/:id"
          component={EditEventPage}
          {...routeProps}
        />
        {/* Children Routes */}
        <PrivateRoute
          {...routeProps}
          path={`/app/users/:id`}
          component={ProfilePage}
        />
        <PrivateRoute
          {...routeProps}
          path={`/app/users/:id/courses`}
          component={CoursesPage}
        />
        {messagingRoutes.map((route, i) => (
          <RouteWithChildRoutes key={i} {...route} />
        ))}
        <PrivateRoute
          {...routeProps}
          path={`/app/users/:id/events`}
          component={EventPage}
          auth={auth}
          isMobile={isMobile}
        />
        <PrivateRoute
          {...routeProps}
          path={`/app/users/:id/community-service`}
          component={CommunityServicePage}
        />
        <PrivateRoute
          {...routeProps}
          path={`/app/users/:id/tasks`}
          component={TasksPage}
        />
        <PrivateRoute
          {...routeProps}
          path={`/app/tasks/:id`}
          component={ViewTaskPage}
        />
        <PrivateRoute
          {...routeProps}
          path={`/app/users/:id/events`}
          component={EventPage}
        />
        <PrivateRoute
          {...routeProps}
          path="/app/topic-boards"
          component={TopicBoardsPage}
        />
        <PrivateRoute
          {...routeProps}
          path="/app/topic-boards/:id"
          component={BoardPostPage}
        />
      </Switch>
    </>
  );
};

SchoolRoutes.propTypes = {
  history: PropTypes.object,
  auth: PropTypes.object
};

export default SchoolRoutes;
