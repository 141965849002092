import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const roles = (state = initialState.roles, action) => {
  switch (action.type) {
    case types.LOAD_ROLE_SUCCESS:
      return action.roles;

    case types.CREATE_ROLE_SUCCESS:
      return [...state, Object.assign({}, action.role)];

    case types.UPDATE_ROLE_SUCCESS:
      return [
        ...state.filter(roles => roles.id !== action.role.id),
        Object.assign({}, action.role)
      ];

    case types.DELETE_ROLE_SUCCESS:
      return [...state.filter(role => role.id !== action.roleId)];

    default:
      return state;
  }
};

export const role = (state = initialState.role, action) => {
  switch (action.type) {
    case types.GET_ROLE_SUCCESS:
      return action.role;

    default:
      return state;
  }
};

export const savingRole = (state = initialState.savingRole, action) => {
  switch (action.type) {
    case types.SAVING_ROLE:
      return action.savingRole;

    default:
      return state;
  }
};

export const roleToDelete = (state = initialState.roleToDelete, action) => {
  switch (action.type) {
    case types.REQUEST_ROLE_ID:
      return action.roleToDelete;

    default:
      return state;
  }
};