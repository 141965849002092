import React from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";

import moment from "moment";

const CourseProgressList = ({
  gradeBreakdown,
  renderEditable,
  role,
  onClickDelete
}) => {
  const columns = [
    {
      Header: "Date",
      accessor: "date",
      Cell: row => moment(row.value).format("MM/DD/YYYY")
    },
    {
      Header: "Average",
      accessor: "average",
      Cell: renderEditable
    },
    {
      Header: "Letter",
      accessor: "letter",
      Cell: renderEditable
    },
    {
      Header: "Tests",
      accessor: "test",
      Cell: renderEditable
    },
    {
      Header: "Quizzes",
      accessor: "quiz",
      Cell: renderEditable
    },
    {
      Header: "Projects",
      accessor: "project",
      Cell: renderEditable
    },
    {
      Header: "Homework",
      accessor: "homework",
      Cell: renderEditable
    },
    {
      Header: "Classwork",
      accessor: "classwork",
      Cell: renderEditable
    }
  ];

  role > 7000 &&
    columns.push({
      accessor: "id",
      maxWidth: 100,
      sortable: false,
      Cell: row => (
        <div className="text-center actions">
          <Button
            className="btn-transparent"
            onClick={() => onClickDelete(row.value)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      )
    });

  let gradesView = (
    <ReactTable
      id="gradeBreakdownTable"
      className="-striped -highlight"
      data={gradeBreakdown}
      columns={columns}
      showPagination={false}
      pageSize={gradeBreakdown.length}
      style={{ height: "300px" }}
    />
  );

  return <div>{gradesView}</div>;
};

CourseProgressList.propTypes = {
  gradeBreakdown: PropTypes.array.isRequired,
  auth: PropTypes.object
};

export default CourseProgressList;
