export const CAMPER_BASE = "/app/camper";
export const ADMIN_BASE = "/app/admin";
export const PARENT_BASE = "/app/parent";
export const STAFF_BASE = "/app/staff";
export const SCHOOL_BASE = "/app/school";

export const USERS = "/users";

export const DIRECTORY = "/directory";
export const PROFILE = "/profile";
export const COURSES = "/courses";
export const EVENTS = "/events";
export const TASKS = "/tasks";
export const COMMUNITY_SERVICE = "/community-service";
export const TOPIC_BOARDS = "/topic-boards";

export const DOCUMENTS = "/documents";
