import React, { useRef } from "react";
import PropTypes from "prop-types";

import { Input } from "formsy-react-components";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const TodoForm = ({ disabled, addItem }) => {
  let input = useRef(null);

  return (
    <div className="flex todo-input">
      <Input
        name="item"
        placeholder="Add Item"
        disabled={disabled}
        ref={input}
      />
      <Button
        className="btn-action"
        disabled={disabled || (input.current && !input.current.getValue())}
        onClick={() => {
          addItem(input.current.getValue());
          input.current.resetValue();
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </div>
  );
};

TodoForm.prototypes = {
  todoItem: PropTypes.string,
  addListItem: PropTypes.func,
  itemNumber: PropTypes.number,
  disabled: PropTypes.bool
};

export default TodoForm;
