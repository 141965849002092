import React, { Component } from "react";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { UserAvatar, MultiUserAvatar } from "../users/UserAvatar";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisV,
  faTrash
} from "@fortawesome/free-solid-svg-icons";

import { getConversationParticipants } from "./utils";
import { Modal, Button } from "react-bootstrap";

import moment from "moment";
const TimeStamp = ({ date }) => {
  let yesterday = moment()
    .subtract(1, "day")
    .endOf("day");

  if (
    moment(date)
      .endOf("day")
      .isSame(yesterday)
  ) {
    return "Yesterday";
  } else if (
    moment(date)
      .endOf("day")
      .isBefore(yesterday)
  ) {
    return moment(date).format("dddd");
  } else {
    return moment(date).format("hh:mm A");
  }
};

class Conversation extends Component {
  constructor(props) {
    super(props);
    this.titleInput = React.createRef();

    this.state = {
      editing: false,
      title:
        props.conversation.title ||
        getConversationParticipants(
          props.conversation.participants.filter(
            user => user._id !== props.auth.user._id
          )
        )
    };
  }

  handleEdit = e => {
    e.preventDefault();
    this.setState(
      state => ({
        editing: !state.editing
      }),
      () => {
        this.titleInput.current.focus();
      }
    );
  };
  handleSave = e => {
    e.preventDefault();
    if (this.state.title !== this.props.conversation.title) {
      this.props.handleSave({
        ...this.props.conversation,
        title: this.state.title
      });
    }
    this.setState({ editing: false });
  };
  handleChange = e => {
    this.setState({ title: e.target.value });
  };
  render() {
    const { handleDelete, isSelected, auth, conversation } = this.props;
    const { editing, title } = this.state;
    const participants = conversation.participants.filter(
      user => user._id !== auth.user._id
    );
    return (
      <LinkContainer
        key={conversation._id}
        to={`/app/messages/m/${conversation._id}`}
      >
        <div
          className={`conversation__list__item ${isSelected &&
            "is-selected-conversation"}`}
        >
          <div className="conversation__avatar">
            {participants.length === 1 ? (
              <UserAvatar user={participants[0]} editable={false} size="sm" />
            ) : (
              <MultiUserAvatar users={participants} size="xs" />
            )}
          </div>
          <div className="conversation__content">
            <div>
              <strong>
                {conversation.title ||
                  getConversationParticipants(participants)}
              </strong>
              <br />
              <small>
                <TimeStamp date={conversation.last_message.time_created} />
              </small>
              <br />
              <small>
                {conversation.last_message && conversation.last_message.content}
              </small>
            </div>
          </div>
          {auth.role.number === 8000 && (
            <div className="conversation__actions">
              <Dropdown size="sm" onClick={e => e.preventDefault()}>
                <Dropdown.Toggle>
                  <FontAwesomeIcon fixedWidth icon={faEllipsisV} />
                </Dropdown.Toggle>

                <Dropdown.Menu alignRight>
                  <Dropdown.Item tag="div" onClick={this.handleEdit}>
                    <FontAwesomeIcon size="lg" fixedWidth icon={faEdit} />
                    <span>Edit Title</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    tag="div"
                    onClick={e => handleDelete(e, conversation._id)}
                  >
                    <FontAwesomeIcon size="lg" fixedWidth icon={faTrash} />
                    <span>Delete</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            // <div className="conversation__timestamp">
            //   {conversation.last_message && (
            //     <TimeStamp
            //       date={conversation.last_message.time_created}
            //     />
            //   )}
            // </div>
          )}
          <div className={`modal ${editing ? "show" : ""}`}>
            <div className="modal-content">
              <div className="modal-header">
                <h2>Edit Title</h2>
              </div>
              <div className="modal-body">
                <form
                  className="conversation__input__group"
                  onSubmit={this.handleSave}
                >
                  <input
                    type="text"
                    value={title}
                    onChange={this.handleChange}
                    onBlur={this.handleSave}
                    ref={this.titleInput}
                    placeholder="Enter conversation title here."
                  />
                </form>
              </div>
              <div className="modal-footer">
                <div className="btn-group">
                  <button className="btn btn-action" onClick={this.handleEdit}>
                    Close
                  </button>
                  <button
                    className="btn btn-action"
                    type="submit"
                    onClick={this.handleSave}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LinkContainer>
    );
  }
}

export default Conversation;
