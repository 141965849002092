import React, { Component } from "react";
import PropTypes from "prop-types";

import orderBy from "lodash/orderBy";
import isEmpty from "lodash/isEmpty";

import Conversation from "./Conversation";

class ConversationList extends Component {
  handleDelete = (e, id) => {
    e.preventDefault();
    this.props.deleteConversation(id);
  };
  render() {
    const {
      conversations,
      auth,
      selectedConversation,
      updateConversation
    } = this.props;
    return (
      <div className="conversation__list">
        {!isEmpty(conversations) ? (
          orderBy(conversations, "modified_on", "desc").map(
            (conversation, i) => (
              <Conversation
                key={i}
                auth={auth}
                conversation={conversation}
                handleDelete={this.handleDelete}
                handleSave={updateConversation}
                isSelected={selectedConversation === conversation._id}
              />
            )
          )
        ) : (
          <h4 className="center">No messages</h4>
        )}
      </div>
    );
  }
}

ConversationList.propTypes = {
  conversations: PropTypes.array,
  auth: PropTypes.object,
  selectedConversation: PropTypes.string,
  history: PropTypes.object,
  onClickConversation: PropTypes.func,
  isMobile: PropTypes.bool
};

export default ConversationList;
