import * as endpoints from "./apiEndpoints";
import axios from "axios";

class EventService {
  static loadEvents() {
    const url = `${endpoints.BASE_URL}${endpoints.GET_EVENTS}`;
    return axios.get(url).then(response => response.data);
  }

  static loadEventsByUserId(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_EVENTS}${endpoints.BY_USER_ID}/${id}`;

    console.log("url: ", url);
    return axios.get(url).then(response => response.data);
  }

  static loadPublicEvents() {
    const url = `${endpoints.BASE_URL}${endpoints.PUBLIC_EVENTS}`;

    console.log("url: ", url);
    return axios.get(url).then(response => response.data);
  }

  static getEvent(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_EVENT}/${id}`;

    return axios.get(url).then(response => response.data);
  }

  static createEvent(event) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_EVENT}`;
    const body = {
      event: event
    };
    console.log("event: ", event);
    return axios.post(url, body).then(response => response.data);
  }

  static updateEvent(event) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_EVENT}/${event._id}`;
    const body = {
      event: event
    };

    return axios.put(url, body).then(response => response.data);
  }

  static deleteEvent(id) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_EVENT}/${id}`;
    return axios.delete(url).then(response => response.data);
  }
}

export default EventService;
