import React from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { Input } from "formsy-react-components";
import PhoneNumberInput from "../../common/PhoneNumberInput";

import find from "lodash/find";

const UserInfo = ({ user, users, editing }) => {
  return (
    <>
      <Col md={12} xs={12}>
        <fieldset>
          <legend>Contact</legend>
        </fieldset>
      </Col>
      <Col md={5} xs={12}>
        <Input
          formNoValidate
          required
          autoComplete="off"
          name="first_name"
          label="First Name"
          type="text"
          disabled={!editing}
          value={user.first_name}
        />
      </Col>
      <Col md={6} xs={12}>
        <Input
          formNoValidate
          required
          autoComplete="off"
          name="last_name"
          label="Last Name"
          type="text"
          disabled={!editing}
          value={user.last_name}
        />
      </Col>
      <Col md={1} xs={12}>
        <Input
          formNoValidate
          autoComplete="off"
          name="suffix"
          label="Suffix"
          type="text"
          disabled={!editing}
          value={user.suffix}
        />
      </Col>
      <Col md={12} xs={12}>
        <Input
          formNoValidate
          autoComplete="off"
          name="email"
          label="Email"
          type="email"
          value={user.email}
          disabled={!editing}
          validations={{
            isEmail: true,
            isExists: (values, value) => {
              let foundUser = find(users, { email: value });
              let alreadyExists = foundUser && foundUser._id !== user._id;
              return !alreadyExists;
            }
          }}
          validationErrors={{
            isEmail: "This is not a valid email",
            isExists: "There is a user with this email."
          }}
        />
      </Col>
      <Col md={4} xs={12}>
        <PhoneNumberInput
          autoComplete="off"
          name="home_phone"
          label="Home Phone"
          placeholder="Home Phone"
          value={user.home_phone}
          disabled={!editing}
        />
      </Col>
      <Col md={4} xs={12}>
        <PhoneNumberInput
          autoComplete="off"
          name="mobile_phone"
          label="Mobile Phone"
          placeholder="Mobile Phone"
          value={user.mobile_phone}
          disabled={!editing}
        />
      </Col>
      <Col md={4} xs={12}>
        <PhoneNumberInput
          autoComplete="off"
          name="work_phone"
          label="Work Phone"
          placeholder="Work Phone"
          value={user.work_phone}
          disabled={!editing}
        />
      </Col>
    </>
  );
};

UserInfo.propTypes = {
  user: PropTypes.object,
  editing: PropTypes.bool
};

export default UserInfo;
