import * as types from "./actionTypes";
import { showModalSuccess } from "./modalActions";
import { showAlertSuccess, hideAlertSuccess } from "./alertActions";
import taskService from "../services/taskService";

export function loadTaskSuccess(tasks) {
  return {
    type: types.LOAD_TASKS_SUCCESS,
    tasks
  };
}

export function loadTasksByUserIdSuccess(tasks) {
  return {
    type: types.LOAD_TASKS_BY_USER_ID_SUCCESS,
    tasks
  };
}

export function getTaskSuccess(task) {
  return {
    type: types.GET_TASK_SUCCESS,
    task
  };
}

export function findTaskGroupSuccess(taskGroup) {
  return {
    type: types.GET_TASK_GROUP_SUCCESS,
    taskGroup
  };
}

export function savingTask(status = true) {
  return {
    type: types.SAVING_TASK,
    savingTask: status
  };
}

export function createTaskSuccess(task) {
  return {
    type: types.CREATE_TASK_SUCCESS,
    task
  };
}

export function updateTaskSuccess(task) {
  return {
    type: types.UPDATE_TASK_SUCCESS,
    task
  };
}

export function requestTaskId(taskId) {
  return dispatch => {
    dispatch({
      type: types.REQUEST_TASK_ID,
      taskToDelete: taskId
    });
    dispatch(showModalSuccess("taskDeleteModal"));
  };
}

export function deleteTaskSuccess(taskId) {
  return {
    type: types.DELETE_TASK_SUCCESS,
    taskId
  };
}

export function loadTasks() {
  return dispatch => {
    dispatch(hideAlertSuccess());
    return taskService
      .loadTasks()
      .then(data => dispatch(loadTaskSuccess(data)))
      .catch(error => dispatch(showAlertSuccess(error, "error")));
  };
}

export function loadTasksByUserId(id) {
  return dispatch => {
    dispatch(hideAlertSuccess());
    return taskService
      .loadTasksByUserId(id)
      .then(data => dispatch(loadTasksByUserIdSuccess(data)))
      .catch(error => dispatch(showAlertSuccess(error, "error")));
  };
}

export function getTask(id, showTASKDetails = false) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    if (id) {
      return taskService
        .getTask(id)
        .then(task => {
          dispatch(getTaskSuccess(task));
          if (showTASKDetails) {
            dispatch(showModalSuccess("taskDetailsModal"));
          }
        })
        .catch(error => dispatch(showAlertSuccess(error.description, "error")));
    } else {
      dispatch(showModalSuccess("taskDetailsModal"));
    }
  };
}

export function findTaskGroup(id, showTASKDetails = false) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    if (id) {
      return taskService
        .findTaskGroup(id)
        .then(task => {
          dispatch(findTaskGroupSuccess(task));
          if (showTASKDetails) {
            dispatch(showModalSuccess("taskDetailsModal"));
          }
        })
        .catch(error => dispatch(showAlertSuccess(error.description, "error")));
    } else {
      dispatch(showModalSuccess("taskDetailsModal"));
    }
  };
}

export function createTask(task) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingTask());
    return taskService
      .createTask(task)
      .then(createdTask => {
        dispatch(createTaskSuccess(createdTask));
        dispatch(savingTask(false));
        dispatch(showAlertSuccess("Task created successfully", "success"));
      })
      .catch(error => {
        dispatch(savingTask(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateTask(task) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingTask());
    return taskService
      .updateTask(task)
      .then(updatedTask => {
        dispatch(updateTaskSuccess(updatedTask));
        dispatch(savingTask(false));
        dispatch(showAlertSuccess("Task updated successfully", "success"));
      })
      .catch(error => {
        dispatch(savingTask(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteTask(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return taskService
      .deleteTask(id)
      .then(() => {
        dispatch(deleteTaskSuccess(id));
        dispatch(showAlertSuccess("Task deleted successfully", "success"));
      })
      .catch(error => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function deleteOneTask(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return taskService
      .deleteOneTask(id)
      .then(() => {
        dispatch(deleteTaskSuccess(id));
        dispatch(showAlertSuccess("Task deleted successfully", "success"));
      })
      .catch(error => dispatch(showAlertSuccess(error.description, "error")));
  };
}
