import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const events = (state = initialState.events, action) => {
  switch (action.type) {
    case types.LOAD_EVENTS_SUCCESS:
      return action.events;

    case types.LOAD_EVENTS_BY_USER_ID_SUCCESS:
      return action.events;

    case types.CREATE_EVENT_SUCCESS:
      return [...state, Object.assign({}, action.event)];

    case types.UPDATE_EVENT_SUCCESS:
      return [
        ...state.filter(events => events.id !== action.event.id),
        Object.assign({}, action.event)
      ];

    case types.DELETE_EVENT_SUCCESS:
      return [...state.filter(event => event.id !== action.eventId)];

    default:
      return state;
  }
};

export const publicEvents = (state = initialState.publicEvents, action) => {
  switch (action.type) {
    case types.LOAD_PUBLIC_EVENTS_SUCCESS:
      console.log(action.publicEvents)
      return action.publicEvents;

    default:
      return state;
  }
};

export const event = (state = initialState.event, action) => {
  switch (action.type) {
    case types.GET_EVENT_SUCCESS:
      return Object.assign({}, state, {
        ...action.event,
        invited: action.event.invited.map(user => {
          return {
            value: user._id,
            label: user.full_name
          };
        })
      });

    default:
      return state;
  }
};

export const savingEvent = (state = initialState.savingEvent, action) => {
  switch (action.type) {
    case types.SAVING_EVENT:
      return action.savingEvent;

    default:
      return state;
  }
};

export const eventToDelete = (state = initialState.eventToDelete, action) => {
  switch (action.type) {
    case types.REQUEST_EVENT_ID:
      return action.eventToDelete;

    default:
      return state;
  }
};
