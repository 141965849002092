import React from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { Input } from "formsy-react-components";
import DropdownSearch from "../../common/DropdownSearch";

import orderBy from "lodash/orderBy";

const generateStateOptions = codes => {
  return orderBy(
    codes
      .filter(code => code.field === "state")
      .map(code => ({
        value: code._id,
        label: code.label
      })),
    "label"
  );
};

const UserAddress = ({ user, state, codes, editing, onChange }) => {
  return (
    <>
      <Col md={12} xs={12}>
        <fieldset>
          <legend>Address</legend>
        </fieldset>
      </Col>
      <Col md={3}>
        <Input
          formNoValidate
          name="street"
          type="text"
          autocomplete="off"
          label="Street Address"
          disabled={!editing}
          value={user.address.street}
        />
      </Col>
      <Col md={3}>
        <Input
          formNoValidate
          name="city"
          type="text"
          autocomplete="off"
          label="City"
          disabled={!editing}
          value={user.address.city}
        />
      </Col>
      <Col md={3}>
        <DropdownSearch
          name="state"
          label="State"
          disabled={!editing}
          onChange={onChange}
          value={state}
          data={[...generateStateOptions(codes)]}
        />
      </Col>
      <Col md={3}>
        <Input
          formNoValidate
          name="zip"
          type="text"
          autocomplete="off"
          label="Postal Code"
          disabled={!editing}
          value={user.address.zip}
        />
      </Col>
    </>
  );
};

UserAddress.propTypes = {
  user: PropTypes.object,
  editing: PropTypes.bool,
  stateOptions: PropTypes.array,
  onChangeSelect: PropTypes.func
};

export default UserAddress;
