import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import * as userActions from "../../actions/userActions";

import LoginForm from "./LoginForm";

class LoginPage extends Component {
  handleSave = user => {
    this.props.actions.loginUser(user);
  };

  render() {
    const { savingUser } = this.props;
    return (
      <div className="login-page">
        <div className="form-container center">
          <LoginForm onSave={this.handleSave} saving={savingUser} />
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  actions: PropTypes.object.isRequired,
  savingUser: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  state,
  savingUser: state.savingUser
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...userActions }, dispatch)
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LoginPage)
);
