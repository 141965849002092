import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import * as userActions from "../../../actions/userActions";
import * as gradeActions from "../../../actions/gradeActions";
import * as routeActions from "../../../actions/routeActions";
import * as modalActions from "../../../actions/modalActions";

import CourseCard from "./CourseCard";
import BreadCrumbs from "../../common/BreadCrumbs";
import ConfirmModal from "../../common/ConfirmModal";
import { Row, Col } from "react-bootstrap";

import AddButton from "../../common/AddButton";

class CoursesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grade: {}
    };
  }
  componentDidMount() {
    let { actions, match, auth } = this.props;
    // If looking up camper, pull search via url param, else pull current user
    let userId = match.params.id || auth.user._id;

    actions.getGradeByCamperId(userId);
    actions.getUser(userId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.grade !== this.props.grade) {
      this.setState({
        grade: nextProps.grade
      });
    }
  }
  //add new course
  onClickAdd = () => {
    let userId = this.props.match.params.id;
    this.props.history.push(`/app/users/${userId}/courses/add`);
  };
  //on click delete course, open modal
  onCourseDelete = async course => {
    this.props.actions.requestGradeId(course._id);
  };
  //on modal confirm, delete course
  handleCourseDelete = async () => {
    let courseId = this.props.state.gradeToDelete;
    let userId = this.props.match.params.id;
    await this.props.actions.deleteGrade(courseId);
    await this.props.actions.getGradeByCamperId(userId);
  };
  //breadcrumbs
  getLabel = () => {
    let { user, match } = this.props;
    if (!match.params.id) {
      return [{ label: "My Courses" }];
    } else {
      return [{ label: user.full_name }, { label: "Courses" }];
    }
  };

  render() {
    let { user, auth, actions, modal, isMobile } = this.props;
    let { grade } = this.state;

    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs
            breadcrumbs={this.getLabel()}
            onClick={this.props.history.goBack}
          />
          {auth.role.number > 7000 && (
            <AddButton onClickAdd={this.onClickAdd} />
          )}
        </div>
        <div className="content-wrapper transparent">
          <Row>
            <div className="list">
              {grade.courses ? (
                grade.courses.map(course => (
                  <CourseCard
                    key={course.id}
                    course={course}
                    categories={course.categories}
                    user={user}
                    auth={auth}
                    isMobile={isMobile}
                    onCourseDelete={this.onCourseDelete}
                    handleCourseDelete={this.handleCourseDelete}
                    actions={actions}
                  />
                ))
              ) : (
                <h4 className="center">No Courses</h4>
              )}
            </div>
          </Row>
        </div>
        <ConfirmModal
          id="courseDeleteModal"
          title="Delete Course"
          body="Are you sure you want to delete this course?"
          modal={modal}
          close={this.props.actions.hideModal}
          confirm={this.handleCourseDelete}
        />
      </>
    );
  }
}

CoursesPage.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object,
  modal: PropTypes.object,
  user: PropTypes.object,
  grade: PropTypes.object,
  grades: PropTypes.array,
  pathname: PropTypes.string,
  baseUrl: PropTypes.string
};

const mapStateToProps = state => ({
  state: state,
  auth: state.auth,
  user: state.user,
  modal: state.modal,
  grade: state.grade,
  grades: state.grades
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { ...userActions, ...gradeActions, ...routeActions, ...modalActions },
      dispatch
    )
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CoursesPage)
);
