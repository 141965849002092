import React from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";
import { Dropdown, DropdownButton, Button, Row, Col } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt } from "@fortawesome/fontawesome-free-solid";

import matchSorter from "match-sorter";
import moment from "moment";
import orderBy from "lodash/orderBy";

import isEmpty from "lodash/isEmpty";

const CommunityServiceList = ({
  communityService,
  search,
  onClickEdit,
  onClickDelete,
  auth
}) => {
  if (search) {
    communityService = matchSorter(communityService, search, {
      keys: ["organization", "date", "hours", "note"]
    });
  }

  return (
    <div className="community-list">
      {!isEmpty(communityService) ? (
        orderBy(communityService, "date", "desc").map(service => (
          <div key={service.id} className="column">
            <div className="community-card-wrapper">
              <div className="community-card-header">
                <h4>{service.organization}</h4>
                <p>{`Started: ${moment(service.date).format("MM/DD/YYYY")}`}</p>
                <p>{`Hours: ${service.hours}`}</p>
                <p>{!service.note || `Note: ${service.note}`}</p>
                {(auth.user.role.number == 8000 ||
                  auth.user.role.number == 4000) && (
                  <div className="button-container">
                    <Button
                      className="btn-action btn-primary"
                      onClick={() => onClickEdit(service.id)}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </Button>
                    <Button
                      className="btn-action btn-primary"
                      onClick={() => onClickDelete(service.id)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <h4 className="center">No community service</h4>
      )}
    </div>
  );
};

CommunityServiceList.propTypes = {
  onClickView: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  communityService: PropTypes.array.isRequired,
  auth: PropTypes.object
};

export default CommunityServiceList;
