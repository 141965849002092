import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as modalActions from "../../../actions/modalActions";

import Formsy from "formsy-react";
import FormFooter from "../../common/FormFooter";
import { Row, Col, ListGroup } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import { Input, Textarea } from "formsy-react-components";
import DateTimePicker from "../../common/DateTimePicker";
import DropdownSearch from "../../common/DropdownSearch";
import TodoForm from "./TodoForm";
import TodoList from "./TodoList";

import orderBy from "lodash/orderBy";
import moment from "moment";
import uniqid from "uniqid";
import isEmpty from "lodash/isEmpty";

class EditTaskForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false,
      items: []
    };
  }

  componentWillReceiveProps = nextProps => {
    if (!this.props.adding && nextProps.task !== this.props.task) {
      this.setState({ items: nextProps.task.items });
    }
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  submit = form => {
    let { items } = this.state;
    let { task } = this.props;
    let oldAssignees = task.assigned_to;
    let newAssignees = form.assigned_to;

    let formData = {
      ...form,
      items: items,
      group_id: task.group_id,
      assigned_to: form.assigned_to
        ? form.assigned_to.map(assignedUser => assignedUser.value)
        : []
    };
    this.props.onSave(formData, oldAssignees, newAssignees);
  };

  onChangeSelect = (field, selected) => {
    this.setState({
      [field]: selected
    });
  };

  selectDate = (date, name) => {
    this.setState({
      [name]: date
    });
  };

  addItem = val => {
    let { task } = this.props;
    let items = this.state.items || task.items;

    const todo = { text: val, id: uniqid(), isComplete: false };

    items.push(todo);

    this.setState({ items: items });
  };

  removeItem = id => {
    let { task } = this.props;
    let items = this.state.items || task.items;

    // Filter all todos except the one to be removed
    const remainder = items.filter(todo => todo.id !== id);

    // Update state with filter
    this.setState({ items: remainder });
  };

  userAssociationList = () => {
    let assigned_to = this.state.assigned_to || this.props.task.assigned_to;

    return (
      <Col md={12} xs={12}>
        <fieldset>
          <legend> Assigned To </legend>{" "}
        </fieldset>{" "}
        <DropdownSearch
          name="assigned_to"
          disabled={!this.props.editing}
          onChange={this.onChangeSelect}
          isMulti={true}
          value={assigned_to}
          data={[
            ...orderBy(this.props.users, "full_name")
              .filter(user => user.role.number !== 8000)
              .map(user => ({
                value: user._id,
                label: user.full_name
              }))
          ]}
        />{" "}
      </Col>
    );
  };

  userAssociationDetails = () => {
    let taskGroup = this.props.taskGroup;
    return (
      <Col md={12} xs={12}>
        <ListGroup>
          {" "}
          {!isEmpty(taskGroup) ? (
            taskGroup.map(task => (
              <ListGroup.Item key={task._id}>
                <strong> {task.assigned_to[0].full_name} </strong>{" "}
                {task.status.label == "Assigned" && (
                  <Badge variant="warning" className="warningBadge">
                    {" "}
                    {task.status.label}{" "}
                  </Badge>
                )}{" "}
                {task.status.label == "Completed" && (
                  <Badge variant="success" className="successBadge">
                    {" "}
                    {task.status.label}{" "}
                  </Badge>
                )}{" "}
              </ListGroup.Item>
            ))
          ) : (
            <h4 className="center" />
          )}{" "}
        </ListGroup>{" "}
      </Col>
    );
  };

  render() {
    let { task, editing, saving } = this.props;

    return (
      <Formsy
        ref="form"
        className="vertical form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Row>
          <Col md={12} xs={12}>
            <Input
              name="title"
              label="Title"
              disabled={!editing}
              value={task.title}
            />{" "}
          </Col>{" "}
        </Row>{" "}
        <Row>
          <Col md={12} xs={12}>
            <Textarea
              type="text"
              name="description"
              label="Description"
              disabled={!editing}
              value={task.description}
            />{" "}
          </Col>{" "}
        </Row>{" "}
        <Row>
          <Col md={6} xs={12}>
            <DateTimePicker
              required
              name="assigned_date"
              label="Start Date"
              disabled={!editing}
              value={this.state.assigned_date || task.assigned_date}
              onChange={this.selectDate}
            />{" "}
          </Col>{" "}
          <Col md={6} xs={12}>
            <DateTimePicker
              required
              name="due_date"
              label="Due Date"
              disabled={!editing}
              value={this.state.due_date || task.due_date}
              onChange={this.selectDate}
              validations={{
                invalidDate: (values, value) =>
                  value &&
                  values["assigned_date"] &&
                  moment(value).isBefore(values["assigned_date"])
                    ? false
                    : true
              }}
              validationErrors={{
                invalidDate: "End date should be after start date"
              }}
            />{" "}
          </Col>{" "}
        </Row>{" "}
        <Row>
          <Col md={12} xs={12}>
            <fieldset>
              <legend> Todo Items </legend>{" "}
            </fieldset>{" "}
            <TodoForm disabled={!editing} addItem={this.addItem} />{" "}
            <TodoList
              disabled={!editing}
              listItems={this.state.items}
              removeItem={this.removeItem}
            />{" "}
          </Col>{" "}
        </Row>{" "}
        <Row> {this.userAssociationList()} </Row>{" "}
        <Row> {this.userAssociationDetails()} </Row>{" "}
        {editing && (
          <FormFooter
            cancel={this.props.onCancel}
            saving={saving}
            canSubmit={this.state.canSubmit}
          />
        )}
      </Formsy>
    );
  }
}

EditTaskForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  task: PropTypes.object,
  actions: PropTypes.object,
  onSave: PropTypes.func,
  auth: PropTypes.object,
  users: PropTypes.array,
  editing: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    state: state,
    modal: state.modal,
    users: state.users
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...modalActions }, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTaskForm);
