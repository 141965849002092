import React from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { Input } from "formsy-react-components";

const UserSocial = ({ social, editing }) => {
  return (
    <>
      <Col md={12} xs={12}>
        <fieldset>
          <legend>Social Media</legend>
        </fieldset>
      </Col>
      <Col md={4} xs={12}>
        <Input
          formNoValidate
          autoComplete="off"
          name={"snapchat"}
          label={"Snapchat"}
          type="text"
          value={social.snapchat}
          disabled={!editing}
        />
      </Col>
      <Col md={4} xs={12}>
        <Input
          formNoValidate
          autoComplete="off"
          name={"instagram"}
          label={"Instagram"}
          type="text"
          value={social.instagram}
          disabled={!editing}
        />
      </Col>
      <Col md={4} xs={12}>
        <Input
          formNoValidate
          autoComplete="off"
          name={"twitter"}
          label={"Twitter"}
          type="text"
          value={social.twitter}
          disabled={!editing}
        />
      </Col>
    </>
  );
};

UserSocial.propTypes = {
  social: PropTypes.objectOf(PropTypes.string),
  editing: PropTypes.bool
};

export default UserSocial;
