import React from "react";
import PropTypes from "prop-types";

import { Dropdown, DropdownButton } from "react-bootstrap";
import ReactTable from "react-table";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faTrashAlt,
  faEllipsisV
} from "@fortawesome/fontawesome-free-solid";

import matchSorter from "match-sorter";
import { formatPhone } from "../../helpers";

const UserList = ({ users, search, onClickView, onClickDelete, isMobile }) => {
  let columns = [
    {
      Header: "First Name",
      accessor: "first_name"
    },
    {
      Header: "Last Name",
      accessor: "last_name"
    },
    {
      Header: "Suffix",
      accessor: "suffix"
    },
    {
      Header: "Email",
      accessor: "email"
    },
    {
      Header: "Home Phone",
      accessor: "home_phone",
      Cell: ({ value }) => (value ? formatPhone(value) : "")
    },
    {
      Header: "Mobile Phone",
      accessor: "mobile_phone",
      Cell: ({ value }) => (value ? formatPhone(value) : "")
    },
    {
      Header: "Role",
      accessor: "role.title"
    }
  ];

  if (isMobile) {
    columns = [
      {
        Header: "Full Name",
        id: "name",
        accessor: d =>
          `${d.first_name} ${d.last_name} ${d.suffix ? d.suffix : ""}`
      },
      {
        Header: "Role",
        accessor: "role.title"
      }
    ];
  }

  columns.push({
    accessor: "_id",
    maxWidth: 80,
    filterable: false,
    sortable: false,
    Cell: row => (
      <div className="text-center actions">
        <DropdownButton
          title={
            <span>
              <FontAwesomeIcon icon={faEllipsisV} />
            </span>
          }
          id={"dropdown"}
        >
          <Dropdown.Item onClick={() => onClickView(row.value)}>
            <FontAwesomeIcon icon={faFolder} />
            View
          </Dropdown.Item>
          <Dropdown.Item onClick={() => onClickDelete(row.value)}>
            <FontAwesomeIcon icon={faTrashAlt} />
            Delete
          </Dropdown.Item>
        </DropdownButton>
      </div>
    )
  });

  const props = {
    getTdProps: (state, rowInfo, column) => {
      return {
        onClick: () => {
          if (rowInfo && column && column.id !== "_id") {
            onClickView(rowInfo.original._id);
          }
        }
      };
    },
    showPageJump: isMobile ? false : true
  };

  if (search) {
    users = matchSorter(users, search, {
      keys: [
        "first_name",
        "last_name",
        "full_name",
        "role.title",
        "home_phone",
        "mobile_phone",
        "email"
      ]
    });
  }

  return (
    <ReactTable
      className="-highlight"
      data={users}
      columns={columns}
      {...props}
      defaultPageSize={10}
      defaultSorted={[
        {
          id: isMobile ? "name" : "first_name",
          asc: true
        }
      ]}
    />
  );
};

UserList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      full_name: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  onClickView: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired
};

export default UserList;
