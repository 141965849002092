import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as userActions from "../../actions/userActions";
import * as codeActions from "../../actions/codeActions";
import * as taskActions from "../../actions/taskActions";

import BreadCrumbs from "../common/BreadCrumbs";
import TaskForm from "./forms/TaskForm";

import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import { v4 as uuidv4 } from "uuid";

class AddTaskPage extends Component {
  componentDidMount = () => {
    let { users, codes, actions } = this.props;
    isEmpty(users) && actions.loadUsers();
    isEmpty(codes) && actions.loadCodes();
  };

  handleSave = async (task) => {
    let { auth, codes } = this.props;
    let groupId = uuidv4();

    let parentTask = {
      ...task,
      main_task: true,
      group_id: groupId,
      user_id: auth.user._id,
      assigned_to: task.assigned_to,
      status: find(codes, { label: "Assigned" })._id,
    };

    task.assigned_to.map((userId) => {
      let childTask = {
        ...task,
        main_task: false,
        group_id: groupId,
        user_id: auth.user._id,
        assigned_to: userId,
        status: find(codes, { label: "Assigned" })._id,
      };
      this.props.actions.createTask(childTask);
    });

    await this.props.actions.createTask(parentTask);
    await this.props.history.push(`/app/tasks`);
  };

  render() {
    let { auth, codes, users, savingTask } = this.props;

    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs
            breadcrumbs={[{ label: "Tasks" }, { label: "New Task" }]}
            onClick={this.props.history.goBack}
          />
        </div>
        <div className="content-wrapper">
          <TaskForm
            task={{}}
            users={users}
            auth={auth}
            codes={codes}
            onSave={this.handleSave}
            onCancel={this.props.history.goBack}
            saving={savingTask}
            editing
            adding
          />
        </div>
      </>
    );
  }
}

AddTaskPage.propTypes = {
  actions: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  savingTask: PropTypes.bool,
  users: PropTypes.array,
  codes: PropTypes.array,
};

const mapStateToProps = (state) => ({
  state: state,
  auth: state.auth,
  users: state.users,
  codes: state.codes,
  savingTask: state.savingTask,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        ...userActions,
        ...taskActions,
        ...codeActions,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTaskPage);
