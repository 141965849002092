import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import * as userActions from "../../actions/userActions";
import * as roleActions from "../../actions/roleActions";
import * as gradeActions from "../../actions/gradeActions";

import BreadCrumbs from "../common/BreadCrumbs";

import CourseForm from "./CourseForm";

class AddCoursePage extends Component {
  constructor(props, context) {
    super(props, context);

    props.actions.loadUsers();
    props.actions.loadRoles();
  }

  onClickSave = async (course) => {
    let { user } = this.props;

    let data = {
      user_id: user._id,
      categories: [],
      ...course,
    };
    await this.props.actions.createGrade(data);
    await this.props.history.push(
      `/app/users/${this.props.match.params.id}/courses`,
    );
  };

  handleCancel = () => {
    this.props.history.push(`/app/users/${this.props.user._id}/courses`);
  };

  render() {
    let { savingGrade, roles } = this.props;

    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs
            breadcrumbs={[{ label: "Courses" }, { label: "New Course" }]}
          />
        </div>
        <div className="content-wrapper">
          <CourseForm
            grade={{}}
            roles={roles}
            onSubmit={this.onClickSave}
            onCancel={this.handleCancel}
            saving={savingGrade}
            editing
            adding
          />
        </div>
      </>
    );
  }
}

AddCoursePage.propTypes = {
  actions: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  savingGrade: PropTypes.bool,
  users: PropTypes.array,
  roles: PropTypes.array,
  user: PropTypes.object,
  grade: PropTypes.object,
};

const mapStateToProps = (state) => ({
  state: state,
  auth: state.auth,
  users: state.users,
  savingGrade: state.savingGrade,
  roles: state.roles,
  user: state.user,
  grade: state.grade,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        ...userActions,
        ...roleActions,
        ...gradeActions,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCoursePage);
