import React from "react";
import PropTypes from "prop-types";
import { Card, Badge } from "react-bootstrap";

import moment from "moment";

const TaskDetails = ({ task, isMobile }) => {
  let daysActive = Math.round(
    moment.duration(moment(new Date()).diff(task.created_on)).asDays()
  );
  return (
    <div key={task.id} className="task-details">
      <div className="task-header">
        <h1>{task.title}</h1>
        <div className="task-date">
          <p>
            <strong>Due: </strong>
            {moment(task.due_date).format(
              isMobile ? "MM/DD/YYYY" : "MMM DD YYYY hh:mm A"
            )}
          </p>
          <Badge>
            {daysActive} {daysActive == 1 ? "day" : "days"} active
          </Badge>
        </div>
      </div>
      <Card.Body>
        <Card.Text>
          <h4>Description: </h4>
          {task.description}
        </Card.Text>
      </Card.Body>
    </div>
  );
};

TaskDetails.proptypes = {
  task: PropTypes.object,
  startTime: PropTypes.string,
  endDate: PropTypes.string,
  assignedUsers: PropTypes.array
};

export default TaskDetails;
