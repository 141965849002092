import * as endpoints from "./apiEndpoints";
import axios from "axios";

class TaskService {
  static loadTasks() {
    const url = `${endpoints.BASE_URL}${endpoints.LOAD_TASKS}`;
    return axios.get(url).then(response => response.data);
  }

  static loadTasksByUserId(id) {
    const url = `${endpoints.BASE_URL}${endpoints.LOAD_TASKS}${endpoints.BY_USER_ID}/${id}`;
    console.log("loadTasksByUserId url: ", url);
    return axios.get(url).then(response => response.data);
  }

  static getTask(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_TASK}/${id}`;

    return axios.get(url).then(response => response.data);
  }

  static findTaskGroup(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_TASK_GROUP}/${id}`;

    return axios.get(url).then(response => response.data);
  }

  static createTask(task) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_TASK}`;
    const body = {
      task: task
    };
    console.log("task: ", body);
    return axios.post(url, body).then(response => response.data);
  }

  static updateTask(task) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_TASK}/${task._id}`;
    const body = {
      task: task
    };
    console.log("body: ", body);
    return axios.put(url, body).then(response => response.data);
  }

  static deleteTask(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_TASK_GROUP}/${id}`;
    return axios.delete(url).then(response => response.data);
  }

  static deleteOneTask(id) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_TASK}/${id}`;
    return axios.delete(url).then(response => response.data);
  }
}

export default TaskService;
