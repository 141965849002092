import * as types from "./actionTypes";
import { showModalSuccess } from "./modalActions";
import { showAlertSuccess, hideAlertSuccess } from "./alertActions";
import eventService from "../services/eventService";

import { push } from "connected-react-router";

export function loadEventSuccess(events) {
  return {
    type: types.LOAD_EVENTS_SUCCESS,
    events
  };
}

export function loadEventsByUserIdSuccess(events) {
  return {
    type: types.LOAD_EVENTS_BY_USER_ID_SUCCESS,
    events
  };
}

export function loadPublicEventsSuccess(publicEvents) {
  return {
    type: types.LOAD_PUBLIC_EVENTS_SUCCESS,
    publicEvents
  };
}

export function getEventSuccess(event) {
  return {
    type: types.GET_EVENT_SUCCESS,
    event
  };
}

export function savingEvent(status = true) {
  return {
    type: types.SAVING_EVENT,
    savingEvent: status
  };
}

export function createEventSuccess(event) {
  return {
    type: types.CREATE_EVENT_SUCCESS,
    event
  };
}

export function updateEventSuccess(event) {
  return {
    type: types.UPDATE_EVENT_SUCCESS,
    event
  };
}

export function requestEventId(eventId) {
  return dispatch => {
    dispatch({
      type: types.REQUEST_EVENT_ID,
      eventToDelete: eventId
    });
    dispatch(showModalSuccess("eventDeleteModal"));
  };
}

export function deleteEventSuccess(eventId) {
  return {
    type: types.DELETE_EVENT_SUCCESS,
    eventId
  };
}

export function loadEvents() {
  return dispatch => {
    dispatch(hideAlertSuccess());
    return eventService
      .loadEvents()
      .then(data => dispatch(loadEventSuccess(data)))
      .catch(error => dispatch(showAlertSuccess(error, "error")));
  };
}

export function loadEventsByUserId(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return eventService
      .loadEventsByUserId(id) 
      .then(events => dispatch(loadEventsByUserIdSuccess(events)))
      .catch(error => dispatch(showAlertSuccess(error, "error")));
  };
}

export function loadPublicEvents() {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return eventService
      .loadPublicEvents()
      .then(events => dispatch(loadPublicEventsSuccess(events)))
      .catch(error => dispatch(showAlertSuccess(error, "error")));
  };
}

export function getEvent(id, showEVENTDetails = false) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    if (id) {
      return eventService
        .getEvent(id)
        .then(event => {
          dispatch(getEventSuccess(event));
          if (showEVENTDetails) {
            dispatch(showModalSuccess("eventDetailsModal"));
          }
        })
        .catch(error => dispatch(showAlertSuccess(error.description, "error")));
    } else {
      dispatch(showModalSuccess("eventDetailsModal"));
    }
  };
}

export function createEvent(event) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingEvent());
    return eventService
      .createEvent(event)
      .then(createdEvent => {
        dispatch(createEventSuccess(createdEvent));
        dispatch(savingEvent(false));
        dispatch(showAlertSuccess("Event created successfully", "success"));
        dispatch(push("/app/events"));
      })
      .catch(error => {
        dispatch(savingEvent(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateEvent(event) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingEvent());
    return eventService
      .updateEvent(event)
      .then(updatedEvent => {
        dispatch(updateEventSuccess(updatedEvent));
        dispatch(savingEvent(false));
        dispatch(showAlertSuccess("Event updated successfully", "success"));
        dispatch(push("/app/events"));
      })
      .catch(error => {
        dispatch(savingEvent(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteEvent(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return eventService
      .deleteEvent(id)
      .then(() => {
        dispatch(deleteEventSuccess(id));
        dispatch(showAlertSuccess("Event deleted successfully", "success"));
      })
      .catch(error => dispatch(showAlertSuccess(error.description, "error")));
  };
}
