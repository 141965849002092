import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Badge, Col } from "react-bootstrap";
import moment from "moment";
import TaskItems from "./view/TaskItems";
import find from "lodash/find";

class TaskCard extends Component {
  constructor(props) {
    super(props);
  }

  markCompleted = (e, item) => {
    let { task, codes } = this.props;
    let updatedItem = find(task.items, { id: item.id });

    updatedItem.isComplete = e.target.checked;

    let notYetCompleted = task.items.filter(item => item.isComplete == false);
    if (notYetCompleted.length == 0) {
      let status = find(codes, { field: "status", value: "C" });
      task.status = status;
    } else {
      let status = find(codes, { field: "status", value: "A" });
      task.status = status;
    }

    this.props.actions.updateTask(task);
  };

  render = () => {
    let { task } = this.props;
    let daysUntilDue = Math.round(
      moment.duration(moment(task.due_date).diff(new Date())).asDays()
    );
    let badgeColor;
    let badgeText;
    if (daysUntilDue < 0) {
      badgeColor = "dangerBadge";
      badgeText = "Past Due";
    } else if (daysUntilDue == 0) {
      badgeColor = "warningBadge";
      badgeText = "Due Today!";
    } else if (daysUntilDue > 0 && daysUntilDue <= 7) {
      badgeColor = "warningBadge";
      badgeText = "Due in " + daysUntilDue + " days";
    } else {
      badgeColor = "primaryBadge";
      badgeText = "Due in " + daysUntilDue + " days";
    }
    return (
      <Row id="task-card" className="card-wrapper column">
        <div className="card-header">
          <h4>{task.title}</h4>
          {task.status.label == "Assigned" && (
            <Badge variant="warning" className={badgeColor}>
              {" "}
              {badgeText}
            </Badge>
          )}
          {task.status.label == "Completed" && (
            <Badge variant="success" className="successBadge">
              {" "}
              {task.status.label}{" "}
            </Badge>
          )}{" "}
        </div>
        <div className="card-detail taskCardDescription">
          {task.description}
        </div>
        <Col md={12} xs={12}>
          <TaskItems
            items={task.items}
            onMarkCompleted={this.markCompleted}
            task={task}
          />
        </Col>
      </Row>
    );
  };
}
TaskCard.propTypes = {
  task: PropTypes.object,
  actions: PropTypes.object
};
export default TaskCard;
