import React, { Component } from "react";
import ReactLoading from "react-loading";

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = { active: props.active };
  }

  componentDidMount = () => {
    setTimeout(() => this.setState({ active: false }), 500);
  };

  render() {
    let { active } = this.state;
    return (
      <div className="center">
        <div className={`-loading ${active && `-active`}`}>
          <ReactLoading
            type="bubbles"
            color="#72bf39"
            width={100}
            className="-loading-inner"
          />
        </div>
      </div>
    );
  }
}

export default Loading;