import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";
import { faChevronLeft } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as userActions from "../../actions/userActions";
import * as conversationActions from "../../actions/conversationActions";
import MessageInput from "./MessageInput";
import UserSearch from "./UserSearch";

class NewMessagePage extends Component {
  constructor(props) {
    super(props);
    const isCamperOrGuardian =
      props.auth.role.number === 4000 || props.auth.role.number === 5000;

    const users = props.users.filter(user => user._id !== props.auth.user._id);

    this.state = {
      new: true,
      loading: false,
      users: isCamperOrGuardian
        ? users.filter(user => user.role.number === 8000)
        : users,
      selectedUsers: []
    };
  }
  onSelectUsers = (field, selectedOptions) => {
    this.setState({ selectedUsers: selectedOptions });
  };
  sendMessage = async model => {
    if (model.message !== "") {
      const { auth } = this.props;
      if (this.state.selectedUsers.length) {
        const message = {
          content: model.message,
          sender: auth.user._id,
          time_created: new Date()
        };

        const participants = [
          ...this.state.selectedUsers.map(user => user.value),
          auth.user._id
        ];

        await this.props.actions.createConversation(participants, message);

        const id = this.props.conversations[this.props.conversations.length - 1]
          ._id;
        this.props.actions.getConversation(id, this.state.limit);
        this.props.history.push(`/app/messages/m/${id}`);
      }
    }
  };
  render() {
    return (
      <div className="message__inner new__message__page">
        <div className="message__header">
          <UserSearch
            className="search"
            data={this.state.users}
            value={this.state.selectedUsers}
            onChange={this.onSelectUsers}
          />
        </div>
        <div className="messages-container" />
        <MessageInput ref={this.childRef} onSubmit={this.sendMessage} />
      </div>
    );
  }
}

NewMessagePage.propTypes = {
  state: PropTypes.object,
  pathname: PropTypes.string,
  search: PropTypes.string,
  hash: PropTypes.string,
  users: PropTypes.array,
  auth: PropTypes.object,
  register: PropTypes.func,
  client: PropTypes.object,
  actions: PropTypes.object,
  conversations: PropTypes.array,
  history: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  users: state.users,
  conversations: state.conversations,
  conversation: state.conversation,
  loadingConversation: state.loadingConversation
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { ...userActions, ...conversationActions },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewMessagePage);
