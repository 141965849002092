import React, { useEffect, useLayoutEffect } from "react";

import { UserAvatar } from "../users/UserAvatar";
import Loading from "../common/Loading";
import moment from "moment";
import orderBy from "lodash/orderBy";
import find from "lodash/find";

const TimeStamp = ({ time }) => (
  <p className="-timestamp">{moment(time).format("hh:mm A")}</p>
);

const DateStamp = ({ date, sameDay }) => (
  <p className="message-new-date">
    {moment(date).format(sameDay ? "h:mm A" : "ddd h:mm A")}
  </p>
);

const MessageList = React.forwardRef((props, ref) => {
  let { conversation, users, loading, auth, onScroll } = props;
  let { messages } = conversation;
  useEffect(() => {
    if (ref) ref.current.scrollTop = ref.current.scrollHeight;
  }, [conversation._id]);

  useLayoutEffect(() => {
    if (ref) ref.current.scrollTop = ref.current.scrollHeight / 8;
  }, [messages]);

  messages = orderBy(messages, "time_created");

  return (
    <div
      ref={ref}
      className="messages-container"
      id="messages"
      onScroll={onScroll}
    >
      {messages.map((message, i) => {
        const isSelf = message.sender == auth.user._id;
        const sender = find(users, { _id: message.sender });

        let lastMessage = i == 0 ? messages[i] : messages[i - 1];
        let lastSender = lastMessage.sender == message.sender && i > 0;

        let hours = moment
          .duration(moment(message.time_created).diff(lastMessage.time_created))
          .asHours();

        return (
          <>
            {hours > 3 && (
              <DateStamp
                key={`datestamp-${hours}`}
                date={message.time_created}
                sameDay={hours < 24}
              />
            )}
            <div key={i} className={`message ${isSelf ? "sender" : "replier"}`}>
              {!isSelf && sender && (
                <UserAvatar
                  user={sender}
                  hidden={lastSender}
                  editable={false}
                  size="xs"
                />
              )}
              <div className="-content">
                <p className="bubble">{message.content}</p>
                <TimeStamp time={message.time_created} />
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
});

export default MessageList;
