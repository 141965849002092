import React from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { Input } from "formsy-react-components";

const InputPasswordComponent = ({ user, editing }) => {
  return (
    <>
      <Col md={12} xs={12}>
        <fieldset>
          <legend>Password</legend>
        </fieldset>
      </Col>
      <Col md={6} xs={12}>
        <Input
          formNoValidate
          required
          type="password"
          name="password"
          label="Password"
          value={user.password}
        />
      </Col>
      <Col md={6} xs={12}>
        <Input
          formNoValidate
          required
          type="password"
          name="verifyPassword"
          label="Verify Password"
          validations="equalsField:password"
          validationError="Passwords do not match."
          disabled={!editing}
          value={user.verifyPassword}
        />
      </Col>
    </>
  );
};

InputPasswordComponent.propTypes = {
  user: PropTypes.object,
  editing: PropTypes.bool
};

export default InputPasswordComponent;
