import React, { Component } from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import Formsy from "formsy-react";
import FormFooter from "../common/FormFooter";
import { UserAvatar } from "../users/UserAvatar";
import UserInfo from "../users/forms/UserInfo";
import { Textarea, Input } from "formsy-react-components";
import UserService from "../../services/userService";

import UserSocial from "../users/forms/UserSocial";
import UserAddress from "@/components/users/forms/UserAddress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/fontawesome-free-solid";

import "./ProfileForm.scss";

class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatar: props.user.avatar,
      showModal: false
    };
    this.avatarInput = React.createRef();
    // this.fr = new FileReader();
    // this.fr.addEventListener("load", () => {
    //   this.setState({ avatar: this.fr.result });
    // });
  }

  handleChange = (field, selected) => {
    this.setState({ [field]: selected.value });
  };

  cancel = () => {
    this.props.onCancel();
  };

  submit = form => {
    form.avatar = this.state.avatar || this.props.user.avatar;
    let data = {
      full_name: form.first_name + " " + form.last_name,
      ...form
    };
    this.props.onSave(data);
  };

  onClickPasswordReset = () => {
    this.setState({ showModal: true });
  };

  onCancelModal = () => {
    this.setState({ showModal: false });
    this.refs.form.reset();
  };
  changePassword = async data => {
    console.log(data);
    // await this.props.actions.changePassword({})
  };
  onSaveModal = async data => {
    this.setState({ showModal: false });
    await this.props.actions.changePassword(data.password);
  };
  onChange = async () => {
    if (this.avatarInput.current.files.length) {
      // this.fr.readAsDataURL(this.avatarInput.current.files[0]);
      console.log(`UPLOADING`);
      const fd = new FormData();
      fd.append("avatar", this.avatarInput.current.files[0]);
      const file = await UserService.uploadAvatar(fd);
      this.setState({ avatar: file.location });
    }
  };
  render = () => {
    let { user, savingUser, codes } = this.props;
    const avatar = this.state.avatar;
    return (
      <>
        <Formsy
          ref="form"
          className="vertical form"
          onValidSubmit={this.submit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
        >
          <div className="user__profile">
            <header>
              <figure
                className="image round show__edit"
                onClick={() => this.avatarInput.current.click()}
              >
                <img src={avatar} />
                <FontAwesomeIcon icon={faCloudUploadAlt} size="6x" fixedWidth />
                <input
                  type="file"
                  name="avatar"
                  ref={this.avatarInput}
                  onChange={this.onChange}
                />
              </figure>
            </header>
          </div>
          <UserBiography user={user} editing />
          <UserInfo user={user} editing />
          <UserAddress
            user={user}
            codes={codes}
            state={user.address.state}
            onChange={this.handleChange}
            editing
          />
          <UserSocial social={user.social} editing />
          <FormFooter
            cancel={this.cancel}
            saving={savingUser}
            canSubmit={true}
          />
        </Formsy>
        <button
          className="btn changePassBtn"
          style={{
            background: "#f15d2a",
            color: "white !important"
          }}
          onClick={this.onClickPasswordReset}
        >
          Change Password
        </button>
        <div className={`modal ${this.state.showModal ? "show" : ""}`}>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Change Password</h2>
            </div>
            <Formsy ref="form" onValidSubmit={this.onSaveModal}>
              <div className="modal-body">
                <Input
                  required
                  type="password"
                  name="password"
                  label="Password"
                  validations="minLength: 8"
                  validationError="Password should be at least 8 characters long."
                />
                <Input
                  required
                  type="password"
                  name="verifyPassword"
                  label="Verify Password"
                  validations="equalsField:password"
                  validationError="Passwords do not match."
                />
              </div>
              <div className="modal-footer">
                <div className="btn-group">
                  <button
                    className="btn btn-action"
                    onClick={this.onCancelModal}
                  >
                    Cancel
                  </button>
                  <button className="btn btn-action" type="submit">
                    Save
                  </button>
                </div>
              </div>
            </Formsy>
          </div>
        </div>
      </>
    );
  };
}

ProfileForm.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object,
  baseUrl: PropTypes.string,
  pathname: PropTypes.string
};

const UserBiography = ({ user }) => {
  return (
    <>
      <Col md={12} xs={12}>
        <fieldset>
          <legend>Edit Bio</legend>
        </fieldset>
      </Col>
      <Col md={12} xs={12}>
        <Textarea
          name="biography"
          as="textarea"
          rows="3"
          placeholder="Describe who you are."
          value={user.biography}
        />
      </Col>
    </>
  );
};

export default ProfileForm;
