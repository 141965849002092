import React from "react";
import { Form, Input } from "formsy-react-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

const MessageInput = React.forwardRef((props, ref) => {
  return (
    <Form ref={ref} className="message-form" onSubmit={props.onSubmit}>
      <Input
        name="message"
        value=""
        placeholder="Send a Message"
        type="text"
        buttonAfter={
          <button className="btn" type="submit">
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        }
      />
    </Form>
  );
});

export default MessageInput;
