import React from "react";
import PropTypes from "prop-types";

import { Dropdown, DropdownButton } from "react-bootstrap";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faTrashAlt,
  faEllipsisV
} from "@fortawesome/fontawesome-free-solid";

import matchSorter from "match-sorter";
import moment from "moment";

const TaskList = ({
  auth,
  tasks,
  search,
  onClickView,
  onClickDelete,
  isMobile
}) => {
  let columns = [
    {
      Header: "Title",
      accessor: "title"
    },
    {
      Header: "Assigned Date",
      accessor: "assigned_date",
      Cell: row => moment(row.value).format("MM/DD/YYYY")
    },
    {
      Header: "Due Date",
      accessor: "due_date",
      Cell: row => moment(row.value).format("MM/DD/YYYY")
    },
    {
      Header: "Created By",
      accessor: "created_by"
    }
  ];

  if (isMobile) {
    columns = [
      {
        Header: "Title",
        accessor: "title"
      },
      {
        Header: "Due Date",
        accessor: "due_date",
        Cell: row => moment(row.value).format("MM/DD/YYYY")
      }
    ];
  }

  columns.push({
    Header: "",
    accessor: "_id",
    maxWidth: 80,
    filterable: false,
    sortable: false,
    Cell: row => (
      <div className="text-center actions">
        <DropdownButton
          title={
            <span>
              <FontAwesomeIcon icon={faEllipsisV} />
            </span>
          }
          id={"dropdown"}
        >
          <Dropdown.Item onClick={() => onClickView(row.value)}>
            <FontAwesomeIcon icon={faFolder} />
            View
          </Dropdown.Item>
          {auth.role.number == 8000 && (
            <Dropdown.Item onClick={() => onClickDelete(row.original.group_id)}>
              <FontAwesomeIcon icon={faTrashAlt} />
              Delete
            </Dropdown.Item>
          )}
        </DropdownButton>
      </div>
    )
  });

  const props = {
    getTdProps: (state, rowInfo, column) => {
      return {
        onClick: () => {
          if (state && rowInfo && column) {
            if (column.id !== "_id") {
              onClickView(rowInfo.original._id);
            }
          }
        }
      };
    },
    showPageJump: isMobile ? false : true
  };

  if (search) {
    tasks = matchSorter(tasks, search, {
      keys: [
        "title",
        "assigned_date",
        "due_date",
        "status.label",
        row => {
          let assigned_to = row.assigned_to;
          return assigned_to.map(user => user.full_name);
        },
        "created_by"
      ]
    });
  }

  return (
    <ReactTable
      className="-highlight"
      data={tasks}
      columns={columns}
      {...props}
      defaultPageSize={10}
      defaultSorted={[
        {
          id: "assigned_date",
          asc: true
        }
      ]}
    />
  );
};

TaskList.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.object.isRequired,
      due_date: PropTypes.string.isRequired,
      assigned_date: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  onClickView: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func
};

export default TaskList;
