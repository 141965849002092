import React from "react";
import { Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import PrivateRoute from "./PrivateRoute";
import Sidebar from "../components/nav/Sidebar";
import TabBar from "../components/nav/TabBar";

import CommunityServicePage from "../components/community-service/CommunityServicePage";
import AddCommunityServicePage from "../components/community-service/AddCommunityServicePage";
import EditCommunityServicePage from "../components/community-service/EditCommunityServicePage";
import ProfilePage from "../components/profile/ProfilePage";
import DirectoryPage from "../components/directory/DirectoryPage";
import TasksPage from "../components/tasks/TasksPage";
import ViewTaskPage from "../components/tasks/view/ViewTaskPage";
import MessagesPage from "../components/messages/MessagesPage";
import EventPage from "../components/event/EventPage";
import EditEventPage from "../components/event/EditEventPage";
import CoursesPage from "../components/grades/courses/CoursesPage";
import TopicBoardsPage from "../components/boards/TopicBoardsPage";
import BoardPostPage from "../components/boards/BoardPostPage";

// MESSAGES
import { messagingRoutes } from "./MessagingRoutes";
import { RouteWithChildRoutes } from "./RouteWithChildRoutes";

// DOCUMENTS
import DocumentPage from "../components/documents/DocumentPage";

/* CAMPER ROLE
 * profile (update)
 * grades (read)
 * community service (update)
 * tasks (read and update, cannot create)
 * calendar (read, only shown 'public' events unless specifically invited to 'private' event)
 * directory (read, only show other 'campers')
 * chat
 * topic boards (read and update, cannot create)
 */

const CamperRoutes = ({ auth, isMobile }) => {
  const routeProps = { exact: true, auth, isMobile };

  return (
    <>
      {!isMobile ? (
        <Sidebar role={auth.role.number} />
      ) : (
        <TabBar role={auth.role.number} />
      )}
      <Switch>
        <Redirect exact from="/app" to="/app/profile" />
        <PrivateRoute
          path="/app/profile"
          component={ProfilePage}
          {...routeProps}
        />
        <PrivateRoute
          path="/app/community-service"
          component={CommunityServicePage}
          {...routeProps}
        />
        <PrivateRoute
          path="/app/community-service/add"
          component={AddCommunityServicePage}
          {...routeProps}
        />
        <PrivateRoute
          path="/app/community-service/:id/edit"
          component={EditCommunityServicePage}
          {...routeProps}
        />
        <PrivateRoute
          path="/app/events"
          component={EventPage}
          {...routeProps}
        />
        <PrivateRoute
          path="/app/events/:id"
          component={EditEventPage}
          {...routeProps}
        />
        <PrivateRoute
          path="/app/directory"
          component={DirectoryPage}
          {...routeProps}
        />
        <PrivateRoute
          path="/app/directory/:id"
          component={ProfilePage}
          {...routeProps}
        />
        <PrivateRoute
          {...routeProps}
          path="/app/documents"
          component={DocumentPage}
        />
        <PrivateRoute
          path="/app/boards"
          component={TopicBoardsPage}
          {...routeProps}
        />
        <PrivateRoute path="/app/tasks" component={TasksPage} {...routeProps} />
        <PrivateRoute
          path="/app/tasks/:id"
          component={ViewTaskPage}
          {...routeProps}
        />
        <PrivateRoute
          path="/app/courses"
          component={CoursesPage}
          {...routeProps}
        />
        {messagingRoutes.map((route, i) => (
          <RouteWithChildRoutes key={i} {...route} />
        ))}
        <PrivateRoute
          path="/app/topic-boards"
          component={TopicBoardsPage}
          {...routeProps}
        />
        <PrivateRoute
          {...routeProps}
          path="/app/topic-boards/:id"
          component={BoardPostPage}
        />
      </Switch>
    </>
  );
};

CamperRoutes.propTypes = {
  history: PropTypes.object,
  auth: PropTypes.object
};

export default CamperRoutes;
