import * as endpoints from "./apiEndpoints";
import axios from "axios";

class ConversationService {
  static loadConversations(userId) {
    const url = `${endpoints.BASE_URL}${endpoints.LOAD_CONVERSATIONS}/user=${userId}`;
    return axios.get(url).then(response => response.data);
  }

  static getConversation(id, limit) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_CONVERSATION}/${id}/limit=${limit}`;

    return axios.get(url).then(response => response.data);
  }

  static createConversation(participants, message) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_CONVERSATION}`;

    const body = {
      participants: participants,
      message: message
    };

    return axios.post(url, body).then(response => response.data);
  }

  static updateConversation(conversation) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_CONVERSATION}/${conversation._id}`;

    const body = {
      conversation: conversation
    };

    return axios.put(url, body).then(response => response.data);
  }

  static deleteConversation(id) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_CONVERSATION}/${id}`;
    return axios.delete(url).then(response => response.data);
  }

  static createMessage(message) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_MESSAGE}`;
    const body = {
      message: message
    };

    return axios.post(url, body).then(response => response.data);
  }
}

export default ConversationService;
