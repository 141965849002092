import React, { Component } from "react";
import PropTypes from "prop-types";

import { Col } from "react-bootstrap";
import { Input, Checkbox } from "formsy-react-components";
import Formsy from "formsy-react";
import FileUpload from "../../common/FileUpload";
import FormFooter from "../../common/FormFooter";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faTimes } from "@fortawesome/fontawesome-free-solid";

import isEmpty from "lodash/isEmpty";
import { prettyBytes } from "../../../helpers";

class DocumentForm extends Component {
  constructor(props) {
    super(props);
    this.state = { canSubmit: false, is_private: false, file: {} };
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.document !== this.props.document) {
      this.setState({
        file: nextProps.document,
        is_private: nextProps.document.is_private
      });
    }
  };

  onSubmit = data => {
    if (data.is_private === undefined) {
      data.is_private = false;
    }
    this.props.onSubmit({ ...data, file: this.state.file });
  };

  onFilesChange = files => {
    this.setState({ file: files[0] }, () => console.log(this.state.file));
  };

  onChange = (field, selected) => {
    this.setState({ [field]: selected });
  };

  cancel = () => {
    this.props.onCancel();
  };

  copy = e => {
    e.stopPropagation();
    let copyText = document.getElementById("url");
    copyText.select();
    document.execCommand("copy");
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  render() {
    const { document, adding, saving } = this.props;
    const { name, file, is_private } = this.state;

    return (
      <Formsy
        className="vertical form"
        onValidSubmit={this.onSubmit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Col md={12} xs={12}>
          <Input
            formNoValidate
            required
            autoComplete="off"
            name="name"
            label="Name"
            type="text"
            onChange={this.onChange}
            value={name || document.name}
          />
        </Col>
        {adding && (
          <Col md={12} xs={12}>
            <label className="control-label col-sm-3">File</label>
            <FileUpload label="File" onFilesChange={this.onFilesChange} />
            {!isEmpty(file) && (
              <p>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{ marginRight: ".5em" }}
                  onClick={() => this.setState({ file: {} })}
                />
                {file.name} ({prettyBytes(file.size)})
              </p>
            )}
          </Col>
        )}
        {!isEmpty(file) && (
          <>
            <Col md={1} xs={12}>
              <Checkbox
                name="is_private"
                value={is_private || document.is_private}
                label="Is Private"
                onChange={this.onChange}
              />
            </Col>
            {is_private && (
              <Col md={11} xs={12}>
                <Input
                  name="url"
                  id="url"
                  onClick={this.copy}
                  autoComplete="off"
                  value={`https://vohokase.s3.amazonaws.com/documents/${file.originalname ||
                    file.name}`}
                  buttonBefore={
                    <button
                      className="btn btn-action"
                      onFocus={this.copy}
                      type="button"
                      data-tip
                      data-for="clipboard"
                      data-event="click"
                    >
                      <FontAwesomeIcon icon={faCopy} size="sm" />
                    </button>
                  }
                />
                <ReactTooltip id="clipboard" place="bottom" effect="solid">
                  Copied
                </ReactTooltip>
              </Col>
            )}
          </>
        )}
        <FormFooter
          cancel={this.cancel}
          saving={saving}
          canSubmit={!isEmpty(file) && this.state.canSubmit}
        />
      </Formsy>
    );
  }
}

DocumentForm.propTypes = {
  onSubmit: PropTypes.func,
  onDrop: PropTypes.func
};

export default DocumentForm;
