import React, { Component } from "react";

export default class BouncyLoader extends Component {
  constructor(props) {
    super(props);
    this.state = { rendered: true };
  }

  componentDidMount = () => {
    this.timer = setTimeout(() => this.setState({ rendered: false }), 750);
  };

  render() {
    let { message, children } = this.props;
    return this.state.rendered ? (
      <div className="loader">
        <div className="donut" />
        <h1>{message}</h1>
      </div>
    ) : (
      children
    );
  }
}
