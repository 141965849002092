import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as codeActions from "../../actions/codeActions";
import * as modalActions from "../../actions/modalActions";

import CodeList from "./CodeList";
import CodeForm from "./CodeForm";

import BreadCrumbs from "../common/BreadCrumbs";
import AddButton from "../common/AddButton";
import Modal from "../common/Modal";
import ConfirmModal from "../common/ConfirmModal";

import { Col } from "react-bootstrap";

import find from "lodash/find";

class CodePage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      code: {},
      adding: false
    };
  }

  componentDidMount = () => {
    this.props.actions.loadCodes();
  };

  onClickAdd = () => {
    this.setState({ code: {}, adding: true });
    this.props.actions.getCode();
  };

  onClickEdit = id => {
    let code = find(this.props.codes, { id: id });
    this.setState({ code, adding: false });
    this.props.actions.getCode(id, true);
  };

  onClickDelete = id => {
    this.props.actions.requestCodeId(id);
  };

  handleAdd = code => {
    this.props.actions.createCode(code);
    this.props.actions.hideModal();
  };

  handleSave = code => {
    let data = {
      id: this.props.code.id,
      ...code
    };

    this.props.actions.hideModal();
    this.props.actions.updateCode(data);
  };

  handleDelete = () => {
    this.props.actions.deleteCode(this.props.codeToDelete);
  };

  render() {
    let { code } = this.state;

    let codeForm = (
      <CodeForm
        code={code}
        codes={this.props.codes}
        onAdd={this.handleAdd}
        onSave={this.handleSave}
        onCancel={this.props.actions.hideModal}
        saving={this.props.savingCode}
        editing
        adding={this.state.adding}
      />
    );

    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs breadcrumbs={[{ label: "System Codes" }]} />
          <div>
            <AddButton onClickAdd={this.onClickAdd} />
          </div>
        </div>
        <div className="content-wrapper">
          <Col md={12} xs={12}>
            <CodeList
              codes={this.props.codes}
              onClickEdit={this.onClickEdit}
              onClickDelete={this.onClickDelete}
            />
          </Col>
          <Modal
            id="codeDetailsModal"
            title="Code Info"
            body={codeForm}
            modal={this.props.modal}
            close={this.props.actions.hideModal}
          />
          <ConfirmModal
            id="codeDeleteModal"
            title="Delete Code"
            body="Are you sure you want to delete this code?"
            modal={this.props.modal}
            close={this.props.actions.hideModal}
            confirm={this.handleDelete}
          />
        </div>
      </>
    );
  }
}

CodePage.propTypes = {
  actions: PropTypes.object,
  modal: PropTypes.object,
  savingCode: PropTypes.bool,
  codeToDelete: PropTypes.string,
  codes: PropTypes.array.isRequired,
  code: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    state: state,
    modal: state.modal,
    code: state.code,
    codes: state.codes,
    savingCode: state.savingCode,
    codeToDelete: state.codeToDelete
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...modalActions, ...codeActions }, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CodePage);
