import React, { Component } from "react";
import PropTypes from "prop-types";

import Formsy from "formsy-react";
import { Textarea, Input } from "formsy-react-components";
import { Row, Col } from "react-bootstrap";
import DropdownSearch from "../common/DropdownSearch";
import FormFooter from "../common/FormFooter";

class PostForm extends Component {
  constructor(props) {
    super(props);
    this.state = { canSubmit: false };
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  cancel = () => {
    this.props.onCancel();
  };

  submit = post => {
    this.props.onSave(post);
  };

  handleChange = (field, selected) => {
    this.setState({ [field]: selected.value });
  };

  render() {
    let { post, codes, saving } = this.props;

    return (
      <Formsy
        className="vertical form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Row>
          <Col md={8} xs={12}>
            <Input required name="title" label="Title" value={post.title} />
          </Col>
          <Col md={4} xs={12}>
            <DropdownSearch
              required
              name="topic"
              label="Topic"
              onChange={this.handleChange}
              value={this.state.topic || (post.topic && post.topic._id)}
              data={[
                ...codes
                  .filter(
                    code => code.entity == "Topic" && code.field == "category"
                  )
                  .map(code => ({ value: code._id, label: code.label }))
              ]}
            />
          </Col>
          <Col md={12} xs={12}>
            <Textarea
              name="content"
              label="Post"
              value={post.content}
              required
            />
          </Col>
        </Row>
        <FormFooter
          saving={saving}
          cancel={this.cancel}
          canSubmit={this.state.canSubmit}
        />
      </Formsy>
    );
  }
}

PostForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  auth: PropTypes.object,
  boards: PropTypes.array
};

export default PostForm;
