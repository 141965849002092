import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as codeActions from "../../actions/codeActions";
import * as documentActions from "../../actions/documentActions";

import BreadCrumbs from "../common/BreadCrumbs";
import DocumentForm from "./forms/DocumentForm";

class DocumentAddPage extends Component {
  componentDidMount = () => {
    this.props.actions.getDocument(this.props.match.params.id);
  };

  handleSave = async data => {
    const document = {
      id: this.props.document._id,
      name: data.name,
      is_private: data.is_private,
      file: data.file
    };

    await this.props.actions.updateDocument(document);
    await this.props.history.push("/app/documents");
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    let { document, savingDocument } = this.props;

    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs
            breadcrumbs={[
              { label: "Documents", to: "/app/documents" },
              { label: "Edit Document" }
            ]}
          />
        </div>
        <div className="content-wrapper">
          <DocumentForm
            document={document}
            saving={savingDocument}
            onSubmit={this.handleSave}
            onCancel={this.handleCancel}
            editing
          />
        </div>
      </>
    );
  }
}

DocumentAddPage.propTypes = {
  actions: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  document: PropTypes.object
};

const mapStateToProps = state => ({
  state: state,
  auth: state.auth,
  codes: state.codes,
  document: state.document
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { ...codeActions, ...documentActions },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentAddPage);
