import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as topicBoardActions from "../../actions/topicBoardActions";
import * as modalActions from "../../actions/modalActions";

import PostCard from "./PostCard";
import CommentForm from "./CommentForm";

import BreadCrumbs from "../common/BreadCrumbs";

import EditButton from "../common/EditButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/fontawesome-free-solid";

import isEmpty from "lodash/isEmpty";
import ConfirmModal from "../common/ConfirmModal";

import CommentList from "./CommentList";

class BoardPostPage extends Component {
  constructor(props) {
    super(props);
  }

  //get single post
  componentDidMount = () => {
    this.props.actions.getTopicBoard(this.props.match.params.id);
  };

  //edit single post
  onClickEdit = async () => {
    await this.props.history.push(
      `/app/topic-boards/${this.props.match.params.id}/edit`
    );
  };

  //on click delete post
  onClickDelete = async () => {
    await this.props.actions.requestTopicBoardId(this.props.match.params.id);
  };

  //on delete post modal confirm
  handleDelete = async () => {
    await this.props.actions.deleteTopicBoard(this.props.match.params.id);
    this.props.history.push("/app/topic-boards");
  };

  //add new comment
  handleSubmitComment = async comment => {
    let { auth, post } = this.props;
    let newComment = {
      comment: comment.comment,
      user: auth.user._id
    };
    post.comments.push(newComment);
    await this.props.actions.updateTopicBoard(post);
    await this.props.actions.getTopicBoard(post.id);
    await this.props.actions.loadTopicBoards();
  };

  //on click delete comment open modal
  onCommentDelete = async comment => {
    this.props.actions.requestTopicBoardId_Comment(comment._id);
  };

  //on delete comment modal confirm
  handleCommentDelete = async () => {
    let commentToDelete = this.props.state.topicBoardToDelete;

    let { post } = this.props;
    let newComments = post.comments.filter(function(x) {
      return x._id !== commentToDelete;
    });
    let newPost = {
      ...post,
      comments: newComments
    };
    await this.props.actions.updateTopicBoard(post, commentToDelete);
    await this.props.actions.getTopicBoard(this.props.match.params.id);
    await this.props.actions.loadTopicBoards();
  };

  render = () => {
    let { post, auth, modal, saving } = this.props;
    console.log(post);

    const comments = this.props.post.comments.sort(
      (a, b) => Date.parse(b.modified_on) - Date.parse(a.modified_on)
    );
    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs
            breadcrumbs={[{ label: "Topic Boards" }, { label: post.title }]}
            onClick={this.props.history.goBack}
          />
          {auth.role.number > 7000 && (
            <div>
              <EditButton onClickEdit={this.onClickEdit} />
              <button
                className="btn right btn-action"
                onClick={this.onClickDelete}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          )}
        </div>
        <div id="post-view" className="content-wrapper transparent">
          {!isEmpty(this.props.post) && this.props.post !== undefined && <PostCard post={this.props.post} />}
          <CommentForm onSave={this.handleSubmitComment} saving={saving} />
          <CommentList
            comments={comments}
            auth={auth}
            onCommentDelete={this.onCommentDelete}
            modal={modal}
            handleCommentDelete={this.handleCommentDelete}
            actions={this.props.actions}
          />
        </div>
        <ConfirmModal
          id="topicBoardDeleteModal"
          title="Delete Topic Board"
          body="Are you sure you want to delete this topic board post?"
          modal={this.props.modal}
          close={this.props.actions.hideModal}
          confirm={this.handleDelete}
        />
      </>
    );
  };
}

BoardPostPage.propTypes = {
  actions: PropTypes.object,
  modal: PropTypes.object,
  post: PropTypes.object,
  onClickView: PropTypes.func,
  history: PropTypes.object
};

const mapStateToProps = state => ({
  state: state,
  auth: state.auth,
  modal: state.modal,
  boards: state.topicBoard,
  post: state.topicBoardPost,
  saving: state.savingTopicBoard
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { ...topicBoardActions, ...modalActions },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BoardPostPage);
