import * as endpoints from "./apiEndpoints";
import axios from "axios";

class UserService {
  static loadUsers() {
    let url = `${endpoints.BASE_URL}${endpoints.LOAD_USERS}`;
    return axios.get(url).then(response => response.data);
  }
  static loadUsersByRole(role) {
    const url = `${endpoints.BASE_URL}${endpoints.LOAD_USERS}?role=${role}`;
    return axios.get(url).then(response => response.data);
  }
  static loadUsersByAssociation(idArray) {
    const url = `${endpoints.BASE_URL}${endpoints.LOAD_USERS}/usersByAssociation`;

    const body = {
      idArray
    };

    return axios.post(url, body).then(response => response.data);
  }

  static getUser(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_USER}/${id}`;
    return axios.get(url).then(response => response.data);
  }

  static findByAuthToken(token) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_USER_BY_TOKEN}`;
    const body = {
      token
    };

    return axios.post(url, body).then(response => {
      return response.data;
    });
  }

  static loginUser(user) {
    const url = `${endpoints.BASE_URL}${endpoints.LOGIN}`;
    const body = {
      user: user,
      online: true
    };

    return axios.post(url, body).then(response => {
      localStorage.setItem("camp_vk_token", response.data.token);
      localStorage.setItem("username", response.data.user.email);
      return response.data;
    });
  }

  static logoutUser(user) {
    return new Promise((resolve, reject) => {
      localStorage.clear();
      resolve(true);
    });
  }

  static changePassword(password) {
    const url = `${endpoints.BASE_URL}${endpoints.CHANGE}`;
    return axios.post(url, { password }).then(response => response.data);
  }
  static forgotPassword(user) {
    const url = `${endpoints.BASE_URL}${endpoints.FORGOT}`;
    const body = {
      email: user.email
    };

    return axios.post(url, body).then(response => response.data);
  }

  static resetPassword(user) {
    const url = `${endpoints.BASE_URL}${endpoints.RESET}`;
    return axios.post(url, user).then(response => response.data);
  }

  static createUser(user) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_USER}`;
    const body = {
      user: user
    };
    return axios.post(url, body).then(response => response.data);
  }

  static uploadAvatar(formData) {
    const url = `${endpoints.BASE_URL}/users/avatar`;
    return axios.post(url, formData).then(response => response.data);
  }

  static updateUser(user) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_USER}/${user._id}`;
    const body = {
      user: user
    };
    return axios.put(url, body).then(response => response.data);
  }

  static deleteUser(id) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_USER}/${id}`;

    return axios.delete(url).then(response => response.data);
  }
}

export default UserService;
