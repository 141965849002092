import React from "react";
import windowSize from "react-window-size";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

const EditButton = ({ onClickEdit, readOnly }) => {
  return (
    !readOnly && (
      <button className="btn right btn-action" onClick={onClickEdit}>
        <FontAwesomeIcon icon={faPencilAlt} />
      </button>
    )
  );
};

export default windowSize(EditButton);
