import React from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Breadcrumbs = ({ breadcrumbs, size, onClick }) => (
  <div>
    <div className="bread-aisle">
      {Array.isArray(breadcrumbs) &&
        breadcrumbs.map((breadcrumb, idx) => {
          let activeClass = idx == breadcrumbs.length - 1 ? "active" : "";
          return (
            <LinkContainer key={idx} to={breadcrumb.to || ""}>
              <div className="loaf">
                <div className="slice">
                  <h1 className={`${activeClass} label`}>
                    {idx == 0 && !activeClass ? (
                      <span onClick={onClick}>{breadcrumb.label}</span>
                    ) : (
                      breadcrumb.label
                    )}
                  </h1>
                </div>
              </div>
            </LinkContainer>
          );
        })}
    </div>
  </div>
);

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
  size: PropTypes.number,
  onClick: PropTypes.func
};

export default Breadcrumbs;
