import React from "react";
import PropTypes from "prop-types";

import Loadable from "react-loading-overlay";
import ReactTable from "react-table";
import { Dropdown, DropdownButton } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faTrashAlt,
  faLock,
  faEllipsisV
} from "@fortawesome/fontawesome-free-solid";

import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import uniq from "lodash/uniq";
import map from "lodash/map";

const CodeList = ({ codes, onClickEdit, onClickDelete }) => {
  let codesView = (
    <Loadable
      active={true}
      background="none"
      className="vertical-center"
      spinner
      text="Loading..."
      color="#cf4b04"
    />
  );

  let group = groupBy(codes, "entity");

  const columns = [
    {
      Header: "Field",
      accessor: "field",
      PivotValue: ({ value }) => {
        value = value.replace(/[_-]/g, " ");
        return <span style={{ textTransform: "capitalize" }}>{value}</span>;
      }
    },
    {
      Header: "Value",
      accessor: "value"
    },
    {
      Header: "Label",
      accessor: "label"
    },
    {
      Header: "",
      accessor: "_id",
      maxWidth: 80,
      Aggregated: ({ value }) => {
        return <span />;
      },
      Cell: row => {
        if (row.original && row.original.read_only == true) {
          return <FontAwesomeIcon className="brand-color" icon={faLock} />;
        } else {
          return (
            <div className="text-center actions">
              <DropdownButton
                title={
                  <span>
                    <FontAwesomeIcon icon={faEllipsisV} />
                  </span>
                }
                id={"dropdown"}
              >
                <Dropdown.Item onClick={() => onClickEdit(row.value)}>
                  <FontAwesomeIcon icon={faFolder} />
                  View
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onClickDelete(row.value)}>
                  <FontAwesomeIcon icon={faTrashAlt} className="brand-color" />
                  Delete
                </Dropdown.Item>
              </DropdownButton>
            </div>
          );
        }
      }
    }
  ];

  if (codes.length > 0) {
    codesView = Object.keys(group)
      .sort()
      .map((entity, i) => (
        <div key={i}>
          <h3>{entity}</h3>
          <ReactTable
            className="-highlight margin-bottom"
            data={orderBy(group[entity], "label")}
            showPagination={false}
            columns={columns}
            pageSize={uniq(map(group[entity], "field")).length}
            pivotBy={["field"]}
            defaultSorted={[
              {
                id: "order",
                asc: true
              }
            ]}
          />
        </div>
      ));
  }

  return <div>{codesView}</div>;
};

CodeList.propTypes = {
  codes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      entity: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  value: PropTypes.string,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired
};

export default CodeList;
