import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { Row, Col } from "react-bootstrap";

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
  }

  onDrop = files => {
    this.props.onFilesChange(files);
  };

  render() {
    return (
      <Dropzone onDrop={this.onDrop} multiple>
        {({ getRootProps, getInputProps }) => (
          <section className="file-upload">
            <div {...getRootProps()}>
              <input {...getInputProps()} multiple={false} />
              <p>Drop your file here.</p>
            </div>
          </section>
        )}
      </Dropzone>
    );
  }
}

FileUpload.propTypes = {
  onDrop: PropTypes.func
};

export default FileUpload;
