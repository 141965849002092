import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as eventActions from "../../actions/eventActions";
import * as modalActions from "../../actions/modalActions";
import * as userActions from "../../actions/userActions";
import * as codeActions from "../../actions/codeActions";

import EventForm from "./forms/EventForm";
import NonAdminEventView from "./NonAdminEventView";
import BreadCrumbs from "../common/BreadCrumbs";
import EditButton from "../common/EditButton";
import ConfirmModal from "../common/ConfirmModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/fontawesome-free-solid";

class EditEventPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      editing: false,
      deleting: false,
      readOnly: false,
      event: {}
    };
  }

  componentDidMount = () => {
    const { auth, actions } = this.props;
    actions.loadCodes();
    actions.loadUsers();
    actions.getEvent(this.props.match.params.id);

    if (auth.user.role.number < 8000) {
      this.setState({ readOnly: true });
    }
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  onClickDelete = () => {
    this.props.actions.requestEventId(this.props.event._id);
    this.setState({ deleting: true });
  };

  handleDelete = async () => {
    await this.props.actions.deleteEvent(this.props.event._id);
    this.props.history.goBack();
  };

  handleEdit = () => {
    this.setState({ editing: !this.state.editing });
  };

  handleSave = event => {
    event = event || this.state.modal;

    let data = {
      _id: this.props.event._id,
      ...event
    };

    this.setState({ editing: false });
    this.props.actions.updateEvent(data);
  };

  onClickSave = event => {
    this.handleSave(event);
  };

  render() {
    let { deleting, readOnly } = this.state;
    let { event, users, codes, auth, isMobile } = this.props;

    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs
            breadcrumbs={[{ label: "Events" }, { label: event.title }]}
            onClick={this.props.history.goBack}
          />
          <div>
            <EditButton
              onClickEdit={this.handleEdit}
              disabled={auth.maxRole !== 8000}
              readOnly={readOnly}
            />
            {auth.role.number === 8000 && (
              <button
                className="btn right btn-action"
                onClick={this.onClickDelete}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            )}
          </div>
        </div>
        <div className="add-edit-content-wrapper">
          {auth.role.number === 8000 ? (
            <EventForm
              event={event}
              users={users}
              auth={auth}
              codes={codes}
              isMobile={isMobile}
              onSave={this.onClickSave}
              onDelete={this.onClickDelete}
              onCancel={this.handleCancel}
              saving={this.props.savingEvent}
              editing={this.state.editing}
              readOnly={readOnly}
            />
          ) : (
            <NonAdminEventView
              users={users}
              event={event}
              onCancel={this.handleCancel}
            />
          )}
          <ConfirmModal
            id="eventDeleteModal"
            title={!deleting ? "Save Event" : "Delete Event"}
            body={
              !deleting
                ? "Are you sure you want to save this event?"
                : "Are you sure you want to delete this event?"
            }
            modal={this.props.modal}
            close={this.props.actions.hideModal}
            confirm={!deleting ? this.handleSave : this.handleDelete}
          />
        </div>
      </>
    );
  }
}

EditEventPage.propTypes = {
  actions: PropTypes.object,
  savingEvent: PropTypes.bool,
  event: PropTypes.object,
  events: PropTypes.array,
  users: PropTypes.array,
  auth: PropTypes.object,
  codes: PropTypes.array,
  params: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  modal: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    state: state,
    modal: state.modal,
    savingEvent: state.savingEvent,
    event: state.event,
    events: state.events,
    codes: state.codes,
    users: state.users,
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        ...eventActions,
        ...modalActions,
        ...userActions,
        ...codeActions
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEventPage);
