import React from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";
import Loading from "../common/Loading";

import moment from "moment";

const ActivityList = ({ logs }) => {
  const columns = [
    {
      Header: "User",
      accessor: "user.full_name"
    },
    {
      Header: "Timestamp",
      accessor: "timestamp",
      Cell: row => moment(row.value).format("MM/DD/YYYY hh:mm A")
    }
  ];

  const loading = !logs.length;

  return (
    <ReactTable
      className="-highlight"
      data={logs}
      loading={true}
      columns={columns}
      LoadingComponent={() => <Loading active={loading} />}
      showPagination={false}
      defaultSorted={[
        {
          id: "number",
          desc: true
        }
      ]}
    />
  );
};

ActivityList.propTypes = {
  logs: PropTypes.array.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired
};

export default ActivityList;
