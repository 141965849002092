import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as topicBoardActions from "../../actions/topicBoardActions";

import BreadCrumbs from "../common/BreadCrumbs";
import Searchbar from "../common/Searchbar";
import AddButton from "../common/AddButton";
import PostCard from "./PostCard";

import orderBy from "lodash/orderBy";
import matchSorter from "match-sorter";

class TopicBoardsPage extends Component {
  constructor(props) {
    super(props);

    this.state = { search: "" };
  }

  componentDidMount = () => {
    this.props.actions.loadTopicBoards();
  };

  onClickView = id => {
    this.props.history.push(`/app/topic-boards/${id}`);
  };

  onClickAdd = () => {
    this.props.history.push("/app/topic-boards/add");
  };

  filter = e => {
    this.setState({ search: e.target.value });
  };

  render = () => {
    let { auth } = this.props;
    const boards = orderBy(
      matchSorter([...this.props.boards], this.state.search, {
        keys: ["title", "content", post => post.topic.label]
      }),
      "created_on",
      "desc"
    );
    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs breadcrumbs={[{ label: "Topic Boards" }]} />
          <div>
            <Searchbar onFilter={this.filter} />
            {auth.role.number > 7000 && (
              <AddButton onClickAdd={this.onClickAdd} />
            )}
          </div>
        </div>
        <div className="content-wrapper transparent">
          <div className="list">
            {boards.length ? (
              boards.map(post => (
                <PostCard
                  key={post._id}
                  post={post}
                  auth={auth}
                  onClickView={this.onClickView}
                />
              ))
            ) : (
              <h4 className="center">No Posts</h4>
            )}
          </div>
        </div>
      </>
    );
  };
}

TopicBoardsPage.propTypes = {
  actions: PropTypes.object,
  boards: PropTypes.array,
  onClickView: PropTypes.func
};

const mapStateToProps = state => ({
  state: state,
  auth: state.auth,
  boards: state.topicBoards
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...topicBoardActions }, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopicBoardsPage);
