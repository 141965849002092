// ----- USERS --------------------------------------
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const SAVING_USER = "SAVING_USER";
export const REQUEST_USER_DELETE = "REQUEST_USER_DELETE";
export const REQUEST_USER_DETAIL = "REQUEST_USER_DETAIL";
export const CHECK_AUTH_SUCCESS = "CHECK_AUTH_SUCCESS";
export const RESET_PASS_SENT_SUCCESS = "RESET_PASS_SENT_SUCCESS";
export const RESET_PASS_SUCCESS = "RESET_PASS_SUCCESS";
export const CHANGE_PASS_SUCCESS = "CHANGE_PASS_SUCCESS";

// ---- MODAL -----------------------------------------
export const SHOW_MODAL_SUCCESS = "SHOW_MODAL_SUCCESS";
export const HIDE_MODAL_SUCCESS = "HIDE_MODAL_SUCCESS";

// ---- ALERT -----------------------------------------
export const SHOW_ALERT_SUCCESS = "SHOW_ALERT_SUCCESS";
export const HIDE_ALERT_SUCCESS = "HIDE_ALERT_SUCCESS";

// ---- ROLE -----------------------------------------
export const LOAD_ROLE_SUCCESS = "LOAD_ROLE_SUCCESS";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const SAVING_ROLE = "SAVING_ROLE";
export const REQUEST_ROLE_ID = "REQUEST_ROLE_ID";

// ---- CODES -----------------------------------------

export const LOAD_CODES_SUCCESS = "LOAD_CODES_SUCCESS";
export const GET_CODE_SUCCESS = "GET_CODE_SUCCESS";
export const CREATE_CODE_SUCCESS = "CREATE_CODE_SUCCESS";
export const UPDATE_CODE_SUCCESS = "UPDATE_CODE_SUCCESS";
export const DELETE_CODE_SUCCESS = "DELETE_CODE_SUCCESS";
export const SAVING_CODE = "SAVING_CODE";
export const REQUEST_CODE_ID = "REQUEST_CODE_ID";

// ----- EVENTS -----------------------------------------

export const LOAD_EVENTS_SUCCESS = "LOAD_EVENTS_SUCCESS";
export const LOAD_EVENTS_BY_USER_ID_SUCCESS = "LOAD_EVENTS_BY_USER_ID_SUCCESS";
export const LOAD_PUBLIC_EVENTS_SUCCESS = "LOAD_PUBLIC_EVENTS_SUCCESS";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const SAVING_EVENT = "SAVING_EVENT";
export const REQUEST_EVENT_ID = "REQUEST_EVENT_ID";

// ----- TASKS -----------------------------------------

export const LOAD_TASKS_SUCCESS = "LOAD_TASKS_SUCCESS";
export const LOAD_TASKS_BY_USER_ID_SUCCESS = "LOAD_TASKS_BY_USER_ID_SUCCESS";
export const GET_TASK_SUCCESS = "GET_TASK_SUCCESS";
export const GET_TASK_GROUP_SUCCESS = "GET_TASK_GROUP_SUCCESS";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const SAVING_TASK = "SAVING_TASK";
export const REQUEST_TASK_ID = "REQUEST_TASK_ID";

// ----- GRADES -----------------------------------------

export const LOAD_GRADES_SUCCESS = "LOAD_GRADES_SUCCESS";
export const LOAD_GRADES_BY_USER_ID = "LOAD_GRADES_BY_USER_ID_SUCCESS";
export const GET_GRADE_SUCCESS = "GET_GRADE_SUCCESS";
export const CREATE_GRADE_SUCCESS = "CREATE_GRADE_SUCCESS";
export const UPDATE_GRADE_SUCCESS = "UPDATE_GRADE_SUCCESS";
export const DELETE_GRADE_SUCCESS = "DELETE_GRADE_SUCCESS";
export const SAVING_GRADE = "SAVING_GRADE";
export const REQUEST_GRADE_ID = "REQUEST_GRADE_ID";
export const GET_GRADE_BY_CAMPER_ID_SUCCESS = "GET_GRADE_BY_CAMPER_ID_SUCCESS";

// ----- COMMUNITY_SERVICE_ -----------------------------------------

export const LOAD_COMMUNITY_SERVICES_SUCCESS = "LOAD_COMMUNITY_SERVICE_SUCCESS";
export const LOAD_COMMUNITY_SERVICES_BY_USER_ID_SUCCESS =
  "LOAD_COMMUNITY_SERVICE_BY_USER_ID_SUCCESS";
export const GET_COMMUNITY_SERVICE_SUCCESS = "GET_COMMUNITY_SERVICE_SUCCESS";
export const CREATE_COMMUNITY_SERVICE_SUCCESS =
  "CREATE_COMMUNITY_SERVICE_SUCCESS";
export const UPDATE_COMMUNITY_SERVICE_SUCCESS =
  "UPDATE_COMMUNITY_SERVICE_SUCCESS";
export const DELETE_COMMUNITY_SERVICE_SUCCESS =
  "DELETE_COMMUNITY_SERVICE_SUCCESS";
export const SAVING_COMMUNITY_SERVICE = "SAVING_COMMUNITY_SERVICE";
export const REQUEST_COMMUNITY_SERVICE_ID = "REQUEST_COMMUNITY_SERVICE_ID";
export const GET_COMMUNITY_SERVICE_BY_CAMPER_ID_SUCCESS =
  "GET_COMMUNITY_SERVICE_BY_CAMPER_ID_SUCCESS";

// ---- CONVERSATIONS -----------------------------------------
export const LOAD_CONVERSATION_SUCCESS = "LOAD_CONVERSATION_SUCCESS";
export const GET_CONVERSATION_SUCCESS = "GET_CONVERSATION_SUCCESS";
export const CREATE_CONVERSATION_SUCCESS = "CREATE_CONVERSATION_SUCCESS";
export const UPDATE_CONVERSATION_SUCCESS = "UPDATE_CONVERSATION_SUCCESS";
export const DELETE_CONVERSATION_SUCCESS = "DELETE_CONVERSATION_SUCCESS";
export const SAVING_CONVERSATION = "SAVING_CONVERSATION";
export const LOADING_CONVERSATION = "LOADING_CONVERSATION";
export const REQUEST_CONVERSATION_ID = "REQUEST_CONVERSATION_ID";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const CREATE_MESSAGE_SUCCESS = "CREATE_MESSAGE_SUCCESS";

//---- FILE UPLOADS ------------------------------------
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOADING_FILE = "UPLOADING_FILE";

// -------- ACTIVITY LOGS ------------------------------
export const LOAD_LOG_SUCCESS = "LOAD_LOG_SUCCESS";

// --- ROUTER ------------------------------------------
export const NAVIGATE_TO_USER_EDIT_PAGE_SUCCESS =
  "NAVIGATE_TO_USER_EDIT_PAGE_SUCCESS";

// ----- TOPIC_BOARDS -----------------------------------------

export const LOAD_TOPIC_BOARD_SUCCESS = "LOAD_TOPIC_BOARD_SUCCESS";
export const GET_TOPIC_BOARD_BY_USER_ID_SUCCESS =
  "LOAD_TOPIC_BOARD_BY_USER_ID_SUCCESS";
export const GET_TOPIC_BOARD_SUCCESS = "GET_TOPIC_BOARD_SUCCESS";
export const CREATE_TOPIC_BOARD_SUCCESS = "CREATE_TOPIC_BOARD_SUCCESS";
export const UPDATE_TOPIC_BOARD_SUCCESS = "UPDATE_TOPIC_BOARD_SUCCESS";
export const DELETE_TOPIC_BOARD_SUCCESS = "DELETE_TOPIC_BOARD_SUCCESS";
export const SAVING_TOPIC_BOARD = "SAVING_TOPIC_BOARD";
export const REQUEST_TOPIC_BOARD_ID = "REQUEST_TOPIC_BOARD_ID";

// ------- DOCUMENTS -------------
export const LOAD_DOCUMENTS_SUCCESS = "LOAD_DOCUMENTS_SUCCESS";
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
export const CREATE_DOCUMENT_SUCCESS = "CREATE_DOCUMENT_SUCCESS";
export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const REQUEST_DOCUMENT_DELETE = "REQUEST_DOCUMENT_DELETE";
export const REQUEST_DOCUMENT_DETAIL = "REQUEST_DOCUMENT_DETAIL";
export const SAVING_DOCUMENT = "SAVING_DOCUMENT";
export const LOADING_DOCUMENT = "LOADING_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
