import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as userActions from "../../actions/userActions";
import * as taskActions from "../../actions/taskActions";
import * as modalActions from "../../actions/modalActions";

import TaskList from "./TaskList";
import BreadCrumbs from "../common/BreadCrumbs";
import Searchbar from "../common/Searchbar";
import ConfirmModal from "../common/ConfirmModal";
import AddButton from "../common/AddButton";
import { Col } from "react-bootstrap";
import TaskCard from "./TaskCard";

import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import matchSorter from "match-sorter";

class TaskPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ""
    };
  }

  componentDidMount = () => {
    let { actions } = this.props;

    this.loadTasks(this.props);
  };

  // componentWillReceiveProps = nextProps => {
  //   if (this.props.location.pathname !== nextProps.location.pathname) {
  //     this.loadTasks(nextProps);
  //   }

  //   console.log("nextProps", nextProps);
  // };

  //load user tasks assigned unless admin, then load all main tasks
  loadTasks = props => {
    let { actions, match, auth } = props;

    if (auth.role.number == 8000) {
      actions.loadTasks();
    } else {
      let userId = match.params.id || auth.user._id;
      actions.loadTasksByUserId(userId);
      actions.getUser(userId);
    }
  };

  onClickAdd = () => {
    this.props.history.push("/app/tasks/add");
  };

  onClickDelete = id => {
    this.props.actions.requestTaskId(id);
  };

  handleDelete = async () => {
    await this.props.actions.deleteTask(this.props.taskToDelete);
    await this.loadTasks(this.props);
  };

  handleEdit = id => {
    this.props.history.push(`/app/tasks/${id}`);
  };

  filter = e => {
    this.setState({ search: e.target.value });
  };

  getLabel = () => {
    let { user, match } = this.props;
    if (!match.params.id) {
      return [{ label: "Tasks" }];
    } else {
      return [{ label: user.full_name }, { label: "Tasks" }];
    }
  };

  render = () => {
    let { tasks, auth, isMobile, actions } = this.props;

    if (this.state.search) {
      tasks = matchSorter(tasks, this.state.search, {
        keys: [
          "title",
          row => {
            let assigned_to = row.assigned_to;
            return assigned_to.map(user => user.full_name);
          },
          "created_by"
        ]
      });
    }

    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs breadcrumbs={this.getLabel()} />
          <div>
            <Searchbar onFilter={this.filter} />
            {auth.user.role.number == 8000 && (
              <AddButton onClickAdd={this.onClickAdd} />
            )}
          </div>
        </div>
        {/* admin task list table view */}
        {auth.user.role.number == 8000 && (
          <div className="content-wrapper">
            <Col md={12} xs={12}>
              <TaskList
                tasks={tasks}
                auth={auth}
                isMobile={isMobile}
                search={this.state.search}
                onClickView={this.handleEdit}
                onClickDelete={this.onClickDelete}
              />
            </Col>
          </div>
        )}
        {/* non-admin tasks viewed as cards */}
        {auth.user.role.number !== 8000 && (
          <div className="content-wrapper transparent">
            <div className="list">
              {tasks.length ? (
                orderBy(tasks, "due_date", "asc")
                  .filter(task => task.id)
                  .map(task => (
                    <TaskCard
                      key={task._id}
                      task={task}
                      taskId={task._id}
                      auth={auth}
                      onItemUpdate={this.onItemUpdate}
                      actions={actions}
                      loadTasks={this.loadTasks}
                      codes={this.props.codes}
                    />
                  ))
              ) : (
                <h4 className="center">No Tasks</h4>
              )}
            </div>
          </div>
        )}
        )
        <ConfirmModal
          id="taskDeleteModal"
          title="Delete Task"
          body="Are you sure you want to delete this task?"
          modal={this.props.modal}
          close={this.props.actions.hideModal}
          confirm={this.handleDelete}
        />
      </>
    );
  };
}

TaskPage.propTypes = {
  actions: PropTypes.object,
  modal: PropTypes.object,
  task: PropTypes.object,
  tasks: PropTypes.array
};

const mapStateToProps = state => ({
  state: state,
  auth: state.auth,
  user: state.user,
  modal: state.modal,
  tasks: state.tasks,
  taskToDelete: state.taskToDelete,
  codes: state.codes
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { ...userActions, ...taskActions, ...modalActions },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskPage);
