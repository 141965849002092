import { push } from "connected-react-router";
import { getUserSuccess } from "./userActions";
import { getTaskSuccess } from "./taskActions";
import userService from "../services/userService";
import taskService from "../services/taskService";
import { showAlertSuccess, hideAlertSuccess } from "./alertActions";

export function navigateToEditUser(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return userService
      .getUser(id)
      .then(user => {
        dispatch(getUserSuccess(user));
        dispatch(showAlertSuccess("Retrieved User Successfully"));
        dispatch(push(`/app/edit/${id}`));
      })
      .catch(error => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function navigateToEditTask(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return taskService
      .getTask(id)
      .then(task => {
        dispatch(getTaskSuccess(task));
        dispatch(showAlertSuccess("Retrieved Task Successfully"));
        dispatch(push(`/app/tasks/${id}`));
      })
      .catch(error => dispatch(showAlertSuccess(error.description, "error")));
  };
}
