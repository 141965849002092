import { push } from "connected-react-router";
import * as types from "./actionTypes";
import { showModalSuccess } from "./modalActions";
import { showAlertSuccess, hideAlertSuccess } from "./alertActions";
import userService from "../services/userService";
import emailService from "../services/emailService";

export function loginUserSuccess(user) {
  return { type: types.LOGIN_USER_SUCCESS, user };
}

export function logoutUserSuccess() {
  return { type: types.LOGOUT_USER_SUCCESS };
}

export function loadUsersSuccess(users) {
  return { type: types.LOAD_USERS_SUCCESS, users };
}

export function getUserSuccess(user) {
  return { type: types.GET_USER_SUCCESS, user };
}

export function savingUser(status = true) {
  return { type: types.SAVING_USER, savingUser: status };
}

export function createUserSuccess(user) {
  return { type: types.CREATE_USER_SUCCESS, user };
}

export function updateUserSuccess(user) {
  return { type: types.UPDATE_USER_SUCCESS, user };
}

export function checkAuthSuccess(user) {
  return { type: types.CHECK_AUTH_SUCCESS, user };
}

export function changePasswordSuccess(status = true) {
  return { type: types.CHANGE_PASS_SUCCESS, status };
}

export function resetPassSentSuccess(status = true) {
  return { type: types.RESET_PASS_SENT_SUCCESS, status };
}

export function resetPassSuccess(status = true) {
  return { type: types.RESET_PASS_SUCCESS, status };
}

export function requestUserId(userId, ACTION = null) {
  return dispatch => {
    if (ACTION == "delete") {
      dispatch(showModalSuccess("userDeleteModal"));
      dispatch({ type: types.REQUEST_USER_DELETE, userToDelete: userId });
    } else {
      dispatch(showModalSuccess("userDetailModal"));
      dispatch({ type: types.REQUEST_USER_DETAIL, userToView: userId });
    }
  };
}

export function deleteUserSuccess(userId) {
  return { type: types.DELETE_USER_SUCCESS, userId };
}

export function checkAuth(token) {
  return (dispatch, getState) => {
    if (token) {
      return userService
        .findByAuthToken(token)
        .then(data => dispatch(checkAuthSuccess(data)))
        .catch(error => dispatch(logoutUser()));
    } else {
      // dispatch(logoutUser());
    }
  };
}

export function loginUser(user) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingUser());
    return userService
      .loginUser(user)
      .then(data => {
        dispatch(loginUserSuccess(data));
        dispatch(savingUser(false));
        dispatch(
          showAlertSuccess(`Welcome, ${data.user.first_name}`, "success", false)
        );
        let token = localStorage.getItem("camp_vk_token");
        dispatch(checkAuth(token));
        dispatch(push("/app"));
      })
      .catch(error => {
        dispatch(savingUser(false));
        dispatch(showAlertSuccess("Invalid Username or Password", "error"));
      });
  };
}

export function logoutUser() {
  return dispatch => {
    return userService.logoutUser().then(data => {
      dispatch(logoutUserSuccess());
      dispatch(push("/"));
    });
  };
}

export function loadUsers() {
  return dispatch => {
    dispatch(hideAlertSuccess());
    return userService
      .loadUsers()
      .then(data => dispatch(loadUsersSuccess(data)))
      .catch(error => dispatch(showAlertSuccess(error.description, "error")));
  };
}
export function loadUsersByRole(role) {
  return dispatch => {
    dispatch(hideAlertSuccess());
    return userService
      .loadUsersByRole(role)
      .then(data => dispatch(loadUsersSuccess(data)))
      .catch(error => dispatch(showAlertSuccess(error.description, "error")));
  };
}
export function loadUsersByAssociation(idArray) {
  return dispatch => {
    dispatch(hideAlertSuccess());
    return userService
      .loadUsersByAssociation(idArray)
      .then(data => dispatch(loadUsersSuccess(data)))
      .catch(error => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function getUser(id, showUserDetails = false) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return userService
      .getUser(id)
      .then(user => {
        dispatch(getUserSuccess(user));
        if (showUserDetails) {
          dispatch(showModalSuccess("userDetailsModal"));
        }
      })
      .catch(error => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function createUser(user) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingUser());
    return userService
      .createUser(user)
      .then(createdUser => {
        dispatch(createUserSuccess(createdUser));
        dispatch(savingUser(false));
        dispatch(showAlertSuccess("User created successfully", "success"));
      })
      .catch(error => {
        dispatch(savingUser(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateUser(user, UPDATE_PASS = false, SHOW_ALERT = true) {
  return (dispatch, getState) => {
    dispatch(savingUser());
    dispatch(hideAlertSuccess());
    return userService
      .updateUser(user)
      .then(updatedUser => {
        dispatch(updateUserSuccess(updatedUser));
        dispatch(savingUser(false));
        if (UPDATE_PASS) {
          dispatch(
            showAlertSuccess("Password updated successfully", "success")
          );
        } else if (SHOW_ALERT) {
          dispatch(showAlertSuccess("User updated successfully", "success"));
        }
      })
      .catch(error => {
        dispatch(savingUser(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteUser(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return userService
      .deleteUser(id)
      .then(() => {
        dispatch(deleteUserSuccess(id));
        dispatch(showAlertSuccess("User removed successfully", "success"));
      })
      .catch(error => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function changePassword(password) {
  return dispatch => {
    dispatch(hideAlertSuccess());
    dispatch(savingUser());
    return userService
      .changePassword(password)
      .then(data => {
        console.log({ data });
        dispatch(changePasswordSuccess());
        dispatch(savingUser(false));
        dispatch(showAlertSuccess("Password Changed", "success"));
      })
      .catch(err => {
        dispatch(savingUser(false));
        dispatch(showAlertSuccess("Could not change password", "error"));
      });
  };
}

export function forgotPassword(user) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingUser());
    return userService
      .forgotPassword(user)
      .then(data => {
        console.log("data", data);
        dispatch(resetPassSentSuccess());
        dispatch(savingUser(false));

        let reset_link = "";
        switch (process.env.NODE_ENV) {
          case "development":
            reset_link = `http://localhost:3000/reset/${data.reset_password_token}`;
            break;
          default:
            reset_link = `https://www.campvohokase.org/reset/${data.reset_password_token}`;
            break;
        }

        const email = {
          to: data.email,
          from: "noreply@gravoc.com",
          subject: "Reset Password",
          html: `<p><em>This is an automated email</em></p>`,
          templateId: "d-05ac3b93bd874459a193e9aa574564f8",
          substitutions: {
            name: data.first_name,
            application: "Camp Vohokase",
            themeColor: "#f15d2a",
            reset_link
          }
        };

        dispatch(showAlertSuccess("Password Reset Link Sent", "success"));
        dispatch(push("/login"));
        return emailService.sendEmail(email);
      })
      .catch(error => {
        console.log("error", error);
        dispatch(savingUser(false));
        dispatch(showAlertSuccess("Could not reset password", "error"));
      });
  };
}

export function resetPassword(user) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingUser());
    return userService
      .resetPassword(user)
      .then(data => {
        dispatch(resetPassSuccess());
        dispatch(savingUser(false));
        dispatch(showAlertSuccess("Password Reset Successfully", "success"));
        dispatch(push("/login"));

        const email = {
          to: data.email,
          from: "noreply@gravoc.com",
          subject: "Reset Success",
          html: `<p><em>This is an automated email</em></p>`,
          templateId: "d-107a5fb81d174c7aaadf7383de790c9c",
          substitutions: {
            name: data.first_name,
            themeColor: "#f15d2a"
          }
        };

        return emailService.sendEmail(email);
      })
      .catch(error => {
        dispatch(savingUser(false));
        dispatch(showAlertSuccess("Could not reset password", "error"));
      });
  };
}
