import React, { Component } from "react";

import Formsy from "formsy-react";
import { Textarea } from "formsy-react-components";
import { Row } from "react-bootstrap";

class CommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = { canSubmit: false };
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  submit = post => {
    this.props.onSave(post);
    this.refs.form.reset();
  };

  render() {
    return (
      <Formsy
        ref="form"
        className="post-form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Textarea required name="comment" placeholder="Leave a comment" />
        <Row className="action-footer">
          <input
            className="btn btn-success"
            type="submit"
            disabled={!this.state.canSubmit}
          />
        </Row>
      </Formsy>
    );
  }
}

export default CommentForm;
