import { AppContainer } from "react-hot-loader";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";

// CSS Imports
import "./styles/style.scss";
import "react-table/react-table.css";
import "../node_modules/toastr/build/toastr.min.css";
import "react-dates/lib/css/_datepicker.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";

// Third-Party Imports
import axios from "axios";
import isObject from "lodash/isObject";

import App from "./App";
import configureStore, { history } from "./store/configureStore";

const store = configureStore();

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <App history={history} />
      </Provider>
    </AppContainer>,
    document.getElementById("root")
  );
};

// Add a request interceptor
axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    let token = localStorage.getItem("camp_vk_token");
    let username = localStorage.getItem("username");

    if (token) {
      if (config.data) {
        for (let key in config.data) {
          if (
            // key !== 'token' &&
            isObject(config.data[key]) &&
            !Array.isArray(config.data[key])
          ) {
            // append active user to all requests w/ body to maintain audit trail
            if (config.method == "post") {
              config.data[key].created_by = username;
            } else if (config.data.modified) {
              config.data[key].modified_by = username;
              config.data[key].modified_on = new Date();
            }
          }
        }
      }

      // Append token to all request headers
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(response => response, function(error) {
  if (error.response.status == 401 || error.response.status == 403) {
    console.log("Logging out user...");
    store.dispatch(push("/login"));
  }
  return Promise.reject(error);
});

render();

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept("./App", () => {
    render();
  });
}
