import React from "react";

// MESSAGES
import MessagesPage from "../components/messages/MessagesPage";
import MessagePage from "../components/messages/Message";
import NewMessagePage from "@/components/messages/NewMessage";
import MessagesHome from "@/components/messages/MessagesHome";

export const messagingRoutes = [
  {
    path: "/app/messages",
    component: MessagesPage,
    routes: [
      {
        path: "/app/messages",
        component: MessagesHome
      },
      {
        path: "/app/messages/new",
        component: NewMessagePage
      },
      {
        path: "/app/messages/m/:id",
        component: MessagePage
      }
    ]
  }
];

export default { messagingRoutes };
