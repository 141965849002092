import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";

const FormFooter = ({ canSubmit, saving, cancel, readOnly }) => {
  return (
    <Col md={12} xs={12} className="footer">
      {!readOnly ? (
        <Fragment>
          <button className="btn btn-cancel" type="button" onClick={cancel}>
            Cancel
          </button>
          <input
            className="btn btn-success"
            type="submit"
            disabled={!canSubmit}
            value={saving ? "Saving... " : "Save"}
          />
        </Fragment>
      ) : (
        <button className="btn btn-cancel" type="button" onClick={cancel}>
          Back
        </button>
      )}
    </Col>
  );
};

FormFooter.propTypes = {
  savingUser: PropTypes.bool,
  canSubmit: PropTypes.bool,
  cancel: PropTypes.func
};

export default FormFooter;
