import * as endpoints from "./apiEndpoints";
import axios from "axios";

class DocumentService {
  static async loadDocuments() {
    const url = `${endpoints.BASE_URL}${endpoints.UPLOAD_FILE}`;
    return await axios.get(url);
  }

  static getDocument(id) {
    const url = `${endpoints.BASE_URL}${endpoints.UPLOAD_FILE}/${id}`;
    return axios.get(url).then(response => response.data);
  }

  static async createDocument(document) {
    const url = `${endpoints.BASE_URL}${endpoints.UPLOAD_FILE}`;
    const data = new FormData();

    data.append("document", document.file);
    data.append("name", document.name);
    data.append("is_private", document.is_private);

    const doc = await axios.post(url, data, {
      headers: { "content-type": "multipart/form-data" }
    });
    return doc.data;
  }

  static updateDocument(doc) {
    const url = `${endpoints.BASE_URL}${endpoints.UPLOAD_FILE}/${doc.id}`;
    const body = {
      doc: doc
    };

    return axios.put(url, body).then(response => response.data);
  }

  static async deleteDocument(document) {
    const url = `${endpoints.BASE_URL}${endpoints.UPLOAD_FILE}/${document._id}?name=${document.originalname}`;
    const { data } = await axios.delete(url);
    return data;
  }

  static async uploadFile(record, files) {
    const url = `${endpoints.BASE_URL}${endpoints.UPLOAD_FILE}`;
    const data = new FormData();

    await files.forEach(file => {
      data.append("number", file.number);
      data.append("field", file.field);
      data.append(`fileUpload`, file);
    });

    return await axios
      .post(url, data, { headers: { "content-type": "multipart/form-data" } })
      .then(response => this.createAttachment(record, response.data))
      .then(resp => resp);
  }
}

export default DocumentService;
