import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";

import * as logActions from "../../actions/logActions";
import * as modalActions from "../../actions/modalActions";

import ActivityList from "./ActivityList";

import BreadCrumbs from "../common/BreadCrumbs";

import { Row, Col } from "react-bootstrap";

class ActivityPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      role: {},
      open: false,
      adding: false
    };

    props.actions.loadLogs();
    autoBind(this);
  }

  render() {
    return (
      <div className="content-wrapper">
        <Row className="header-wrapper">
          <BreadCrumbs breadcrumbs={[{ label: "Activity Log" }]} />
        </Row>
        <Col md={12} xs={12}>
          <ActivityList
            logs={this.props.logs}
            onClickEdit={this.onClickEdit}
            onClickDelete={this.onClickDelete}
          />
        </Col>
      </div>
    );
  }
}

ActivityPage.propTypes = {
  actions: PropTypes.object,
  modal: PropTypes.object,
  logs: PropTypes.array.isRequired
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state,
    modal: state.modal,
    logs: state.logs
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...logActions, ...modalActions }, dispatch)
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ActivityPage);
