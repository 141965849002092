import * as types from "./actionTypes";
import { showAlertSuccess, hideAlertSuccess } from "./alertActions";
import logService from "../services/logService";

export function loadLogSuccess(logs) {
  return { type: types.LOAD_LOG_SUCCESS, logs };
}

export function loadLogs() {
  return dispatch => {
    dispatch(hideAlertSuccess());
    return logService
      .loadLogs()
      .then(data => dispatch(loadLogSuccess(data)))
      .catch(error => dispatch(showAlertSuccess(error, "error")));
  };
}
