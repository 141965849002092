import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as userActions from "../../actions/userActions";
import * as modalActions from "../../actions/modalActions";

import BreadCrumbs from "../common/BreadCrumbs";
import AddButton from "../common/AddButton";
import CallCard from "./CallCard";
import CallForm from "./CallForm";

import orderBy from "lodash/orderBy";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

class CallLogPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddForm: false,
      editMode: false,
      call: {},
      canSubmit: false,
    };
  }

  componentDidMount = () => {
    let { actions, user, match, auth } = this.props;
    let camperId = match.params.id ? match.params.id : auth.user._id;

    if (!user.first_name) actions.getUser(camperId);
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  onClickEdit = (call) => {
    this.setState({
      showAddForm: !this.state.showAddForm,
      call: call,
      editMode: true,
    });
  };

  toggleAddForm = () => {
    this.setState({ showAddForm: !this.state.showAddForm });
  };

  cancelForm = () => {
    this.setState({ showAddForm: false, call: {} });
  };

  //Add new call or Edit one call
  submitAddForm = (formData) => {
    let callList = this.props.user.call_log || [];
    //add new call to call_log
    if (!this.state.editMode) {
      let date = moment();
      let callId = uuidv4();
      let created_by = this.props.auth.user.full_name;
      let newCall = {
        _id: callId,
        created_by: created_by,
        date: date,
        title: formData.title,
        description: formData.description,
      };
      callList.unshift(newCall);
      let data = {
        call_log: callList,
        ...this.props.user,
      };
      this.props.actions.updateUser(data);
      //edit one call in call_log
    } else {
      callList.map((call) => {
        if (call._id == this.state.call._id) {
          (call.title = formData.title),
            (call.description = formData.description);
        }
      });
      let data = {
        call_log: callList,
        ...this.props.user,
      };
      this.props.actions.updateUser(data);
    }
    this.setState({
      showAddForm: false,
      editMode: false,
    });
  };

  //on click delete call, open modal
  onClickDelete = async (call) => {
    this.setState({ call: call });
    this.props.actions.showModalSuccess("callDeleteModal");
  };

  //on delete call modal confirm
  handleCallDelete = async (call) => {
    let oldCallList = this.props.user.call_log;
    let newCallList = oldCallList.filter((call) => {
      return call._id !== this.state.call._id;
    });
    let data = {
      ...this.props.user,
      call_log: newCallList,
    };
    await this.props.actions.updateUser(data);
    await this.props.actions.getUser(this.props.match.params.id);
  };

  render = () => {
    let { user, auth, modal, actions } = this.props;
    let calls = user.call_log;
    let call = {};

    if (this.state.editMode) {
      call = this.state.call;
    }

    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs
            breadcrumbs={[
              { label: this.props.user.full_name },
              { label: "Call Log" },
            ]}
            onClick={this.props.history.goBack}
          />
          <AddButton onClickAdd={this.toggleAddForm} />
        </div>
        <div className="content-wrapper transparent">
          {this.state.showAddForm && (
            <CallForm
              calls={calls}
              user={user}
              cancel={this.cancelForm}
              submit={this.submitAddForm}
              call={call}
              enableButton={this.enableButton}
              disableButton={this.disableButton}
              canSubmit={this.state.canSubmit}
            />
          )}
          {calls.length ? (
            <div className="list">
              {orderBy(calls, "date", "desc").map((call) => (
                <CallCard
                  key={call.id}
                  call={call}
                  auth={auth}
                  onClickEdit={this.onClickEdit}
                  onClickDelete={this.onClickDelete}
                  modal={modal}
                  actions={actions}
                  handleCallDelete={this.handleCallDelete}
                />
              ))}
            </div>
          ) : (
            <h4 className="center">No Calls</h4>
          )}
        </div>
      </>
    );
  };
}

CallLogPage.propTypes = {
  user: PropTypes.object,
  actions: PropTypes.object,
  auth: PropTypes.object,
  modal: PropTypes.object,
};

const mapStateToProps = (state) => ({
  state: state,
  user: state.user,
  modal: state.modal,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ ...userActions, ...modalActions }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CallLogPage);
