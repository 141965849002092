import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as userActions from "../../actions/userActions";
import * as roleActions from "../../actions/roleActions";
import * as codeActions from "../../actions/codeActions";
import * as documentActions from "../../actions/documentActions";

import BreadCrumbs from "../common/BreadCrumbs";
import DocumentForm from "./forms/DocumentForm";

class DocumentAddPage extends Component {
  handleSave = async data => {
    const document = {
      name: data.name,
      is_private: data.is_private,
      file: data.file
    };

    await this.props.actions.createDocument(document);
    await this.props.history.push("/app/documents");
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    let { documents, savingDocument } = this.props;

    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs
            breadcrumbs={[
              { label: "Documents", to: "/app/documents" },
              { label: "Upload Document" }
            ]}
          />
        </div>
        <div className="content-wrapper">
          <DocumentForm
            document={{}}
            saving={savingDocument}
            onSubmit={this.handleSave}
            onCancel={this.handleCancel}
            editing
            adding
          />
        </div>
      </>
    );
  }
}

DocumentAddPage.propTypes = {
  actions: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  savingUser: PropTypes.bool,
  user: PropTypes.object,
  users: PropTypes.array,
  roles: PropTypes.array,
  document: PropTypes.object
};

const mapStateToProps = state => ({
  state: state,
  auth: state.auth,
  user: state.user,
  users: state.users,
  savingUser: state.savingUser,
  roles: state.roles,
  codes: state.codes,
  document: state.document
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        ...userActions,
        ...roleActions,
        ...codeActions,
        ...documentActions
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentAddPage);
