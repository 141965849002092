import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as alertActions from "../../actions/alertActions";
import * as userActions from "../../actions/userActions";

import ResetPassForm from "./ResetPassForm";
import { Row, Col } from "react-bootstrap";

class ResetPage extends Component {
  constructor(props, context) {
    super(props, context);
  }

  handleSave = user => {
    user.token = this.props.match.params.token;
    this.props.actions.resetPassword(user);
  };

  render() {
    return (
      <Row className="login-page">
        <Col md={12} xs={12} className="form-container center">
          <ResetPassForm
            onSave={this.handleSave}
            saving={this.props.savingUser}
          />
        </Col>
      </Row>
    );
  }
}

ResetPage.propTypes = {
  actions: PropTypes.object.isRequired,
  alert: PropTypes.object,
  savingUser: PropTypes.bool
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state,
    alert: state.alert,
    savingUser: state.savingUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...userActions, ...alertActions }, dispatch)
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ResetPage);
