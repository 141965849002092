import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as userActions from "../../actions/userActions";
import * as roleActions from "../../actions/roleActions";
import * as modalActions from "../../actions/modalActions";

import UserList from "./UserList";
import BreadCrumbs from "../common/BreadCrumbs";
import Searchbar from "../common/Searchbar";
import ConfirmModal from "../common/ConfirmModal";
import AddButton from "../common/AddButton";
import { Col } from "react-bootstrap";

class UserPage extends Component {
  constructor(props) {
    super(props);
    this.state = { search: "" };
  }

  componentDidMount = () => {
    let { actions } = this.props;
    actions.loadUsers();
  };

  onClickAdd = () => {
    this.props.history.push("/app/users/add");
  };

  onClickView = id => {
    this.props.history.push(`/app/users/${id}`);
  };

  onClickDelete = userId => {
    this.props.actions.requestUserId(userId, "delete");
  };

  handleDelete = () => {
    this.props.actions.deleteUser(this.props.userToDelete);
  };

  filter = e => {
    this.setState({ search: e.target.value });
  };

  render() {
    let { users, isMobile } = this.props;

    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs breadcrumbs={[{ label: "Users" }]} />
          <div>
            <Searchbar onFilter={this.filter} />
            <AddButton onClickAdd={this.onClickAdd} />
          </div>
        </div>
        <div className="content-wrapper">
          <Col md={12} sm={12}>
            <UserList
              users={users}
              isMobile={isMobile}
              search={this.state.search}
              onClickView={this.onClickView}
              onClickDelete={this.onClickDelete}
            />
          </Col>
          <ConfirmModal
            id="userDeleteModal"
            title="Delete User"
            body="Are you sure you want to delete this user?"
            modal={this.props.modal}
            close={this.props.actions.hideModal}
            confirm={this.handleDelete}
          />
        </div>
      </>
    );
  }
}

UserPage.propTypes = {
  actions: PropTypes.object,
  modal: PropTypes.object,
  userToDelete: PropTypes.string,
  users: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  state: state,
  auth: state.auth,
  modal: state.modal,
  users: state.users,
  user: state.user,
  userToDelete: state.userToDelete,
  modal: state.modal
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { ...userActions, ...roleActions, ...modalActions },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPage);
