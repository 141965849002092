import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";

import PrivateRoute from "./PrivateRoute";
import Sidebar from "../components/nav/Sidebar";
import TabBar from "../components/nav/TabBar";

import UserPage from "../components/users/UserPage";
import UserEditPage from "../components/users/UserEditPage";
import UserAddPage from "../components/users/UserAddPage";
import EventPage from "../components/event/EventPage";
import AddEventPage from "../components/event/AddEventPage";
import EditEventPage from "../components/event/EditEventPage";
import TasksPage from "../components/tasks/TasksPage";
import AddTaskPage from "../components/tasks/AddTaskPage";
import EditTaskPage from "../components/tasks/EditTaskPage";
import ActivityLogs from "../components/logs/ActivityPage";

// DOCUMENTS
import DocumentPage from "../components/documents/DocumentPage";
import DocumentAddPage from "../components/documents/DocumentAddPage";
import DocumentEditPage from "../components/documents/DocumentEditPage";

import CodePage from "../components/settings/CodePage";
import RolePage from "../components/settings/RolePage";
import TopicBoardsPage from "../components/boards/TopicBoardsPage";
import BoardPostPage from "../components/boards/BoardPostPage";
import AddPostPage from "../components/boards/AddPostPage";
import EditPostPage from "../components/boards/EditPostPage";
import CoursesPage from "../components/grades/courses/CoursesPage";
import AddCoursePage from "../components/grades/AddCoursePage";
import CommunityServicePage from "../components/community-service/CommunityServicePage";
import EditCommunityServicePage from "../components/community-service/EditCommunityServicePage";
import CallLogPage from "../components/call-logs/CallLogPage";

// MESSAGES
import { messagingRoutes } from "./MessagingRoutes";
import { RouteWithChildRoutes } from "./RouteWithChildRoutes";

const AdminRoutes = ({ auth, isMobile }) => {
  const routeProps = { exact: true, auth, isMobile };

  return (
    <>
      {!isMobile ? (
        <Sidebar role={auth.role.number} />
      ) : (
        <TabBar role={auth.role.number} />
      )}
      <Switch>
        <Redirect exact from="/app" to={`/app/users`} />
        <PrivateRoute
          {...routeProps}
          path={`/app/users`}
          component={UserPage}
        />
        <PrivateRoute
          {...routeProps}
          path={`/app/users/add`}
          component={UserAddPage}
        />
        <PrivateRoute
          {...routeProps}
          path={`/app/users/:id`}
          component={UserEditPage}
        />
        <PrivateRoute
          {...routeProps}
          path={`/app/events`}
          component={EventPage}
        />
        <PrivateRoute
          {...routeProps}
          path={`/app/events/add`}
          component={AddEventPage}
        />
        <PrivateRoute
          {...routeProps}
          path={`/app/events/:id`}
          component={EditEventPage}
        />
        <PrivateRoute {...routeProps} path="/app/tasks" component={TasksPage} />
        <PrivateRoute
          {...routeProps}
          path="/app/tasks/add"
          component={AddTaskPage}
        />
        <PrivateRoute
          {...routeProps}
          path="/app/tasks/:id"
          component={EditTaskPage}
        />
        <PrivateRoute
          {...routeProps}
          path="/app/documents"
          component={DocumentPage}
        />
        <PrivateRoute
          {...routeProps}
          path="/app/documents/add"
          component={DocumentAddPage}
        />
        <PrivateRoute
          {...routeProps}
          path="/app/documents/:id"
          component={DocumentEditPage}
        />
        <PrivateRoute
          {...routeProps}
          path="/app/documents/:id"
          component={DocumentEditPage}
        />
        {messagingRoutes.map((route, i) => (
          <RouteWithChildRoutes key={i} {...route} />
        ))}
        <PrivateRoute {...routeProps} path="/app/codes" component={CodePage} />
        <PrivateRoute {...routeProps} path="/app/roles" component={RolePage} />
        <PrivateRoute
          {...routeProps}
          path="/app/topic-boards"
          component={TopicBoardsPage}
        />
        <PrivateRoute
          {...routeProps}
          path="/app/topic-boards/add"
          component={AddPostPage}
        />
        <PrivateRoute
          {...routeProps}
          path="/app/topic-boards/:id/edit"
          component={EditPostPage}
        />
        <PrivateRoute
          {...routeProps}
          path="/app/topic-boards/:id"
          component={BoardPostPage}
        />
        <PrivateRoute
          {...routeProps}
          path="/app/logs"
          component={ActivityLogs}
        />
        <PrivateRoute
          {...routeProps}
          path="/app/users/:id/courses"
          component={CoursesPage}
        />
        <PrivateRoute
          {...routeProps}
          path="/app/users/:id/courses/add"
          component={AddCoursePage}
        />
        <PrivateRoute
          path={`/app/users/:id/community-service`}
          component={CommunityServicePage}
          {...routeProps}
        />
        <PrivateRoute
          path={`/app/community-service/:id/edit`}
          component={EditCommunityServicePage}
          {...routeProps}
        />
        <PrivateRoute
          path={`/app/users/:id/call-log`}
          component={CallLogPage}
          {...routeProps}
        />
      </Switch>
    </>
  );
};

AdminRoutes.propTypes = {
  auth: PropTypes.object
};

export default AdminRoutes;
