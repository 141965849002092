import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import { withFormsy } from "formsy-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

class DateTimePicker extends Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  onDatepickerRef(el) {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  }

  handleChange = date => {
    this.props.onChange(date, this.props.name);
  };

  render() {
    let {
      name,
      placeholderText,
      value,
      label,
      interval,
      required,
      disabled,
      valid
    } = this.props;

    name = name.replace(/_/g, " ");
    let fieldLabel = required ? (label += " *") : label;
    const errorMessage = !valid && this.props.getErrorMessage();

    return (
      <div className="form-group row">
        <label
          className="control-label"
          htmlFor={name}
          style={{ textTransform: "uppercase" }}
        >
          {fieldLabel}
        </label>
        <DatePicker
          ref={el => this.onDatepickerRef(el)}
          selected={value ? moment(value) : null}
          className="form-control"
          onChange={this.handleChange}
          placeholderText={placeholderText ? placeholderText : "Enter time"}
          showTimeSelect
          fixedHeight
          disabled={disabled}
          timeFormat="hh:mm A"
          timeIntervals={interval ? interval : 15}
          dateFormat="LLL"
          timeCaption="Time"
        />
        <span className="validation-message">{errorMessage}</span>
      </div>
    );
  }
}

DateTimePicker.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  interval: PropTypes.string,
  valid: PropTypes.bool,
  label: PropTypes.string,
  placeholderText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  getErrorMessage: PropTypes.func,
  required: PropTypes.bool
};

export default withFormsy(DateTimePicker);
