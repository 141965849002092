import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import { withFormsy } from "formsy-react";
import Select from "react-select";
import Animated from "react-select/lib/animated";

import { Row, Col } from "react-bootstrap";

import find from "lodash/find";

class DropdownSearch extends Component {
  constructor(props) {
    super(props);

    this.state = { value: props.value };
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.data !== this.props.data) {
      let hasOptions = false;

      nextProps.data.map(data => {
        if (Object.keys(data).includes("options")) {
          hasOptions = true;
        }
      });

      if (hasOptions) {
        let obj;
        nextProps.data.map(data => {
          let value = find(data.options, { value: this.props.value });
          if (value) {
            obj = value;
          }
        });
        this.setState({ value: obj });
      } else {
        let obj = find(nextProps.data, { value: this.props.value });
        this.setState({ value: obj });
      }
    }
  };

  render() {
    let {
      name,
      label,
      data,
      placeholder,
      required,
      disabled,
      isMulti,
      open,
      defaultOpen,
      menuIsOpen,
      className
    } = this.props;

    let fieldLabel = required && !disabled ? (label += " *") : label;

    return (
      <Row className="form-group">
        {label && (
          <label
            className={`control-label ${!label ? "hidden" : ""}`}
            htmlFor={name}
          >
            {fieldLabel}
          </label>
        )}
        <Col sm={12}>
          <Select
            className={className}
            components={Animated()}
            ref="select"
            required={required}
            name={name}
            value={isMulti ? this.props.value : this.state.value || ""}
            onChange={value => this.props.onChange(name, value)}
            options={data}
            placeholder={placeholder ? placeholder : "Please Select..."}
            validations="isEmptyString"
            isSearchable
            menuIsOpen={open}
            defaultMenuIsOpen={defaultOpen}
            closeMenuOnSelect={isMulti}
            isMulti={isMulti}
            menuIsOpen={menuIsOpen}
            isClearable={false}
            isDisabled={disabled}
          />
        </Col>
      </Row>
    );
  }
}

DropdownSearch.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  data: PropTypes.array,
  required: PropTypes.bool,
  setValue: PropTypes.func,
  getValue: PropTypes.func,
  label: PropTypes.string
};

export default withFormsy(DropdownSearch);
