// ----------  Utilities ----------
export const BASE_URL = API_BASE_URL;
export const LOGIN = "/login";
export const LOGOUT = "/logout";
export const FORGOT = "/forgot";
export const RESET = "/reset";
export const SEND_EMAIL = "/email";
export const CHANGE = '/change';

// ---------- Admin Only ------------------

// Users
export const LOAD_USERS = "/users";
export const GET_USER = "/users";
export const GET_USER_BY_TOKEN = "/userByToken";
export const POST_USER = "/users";
export const PUT_USER = "/users";
export const DELETE_USER = "/users";

// Roles
export const LOAD_ROLES = "/roles";
export const GET_ROLE = "/roles";
export const POST_ROLE = "/roles";
export const PUT_ROLE = "/roles";
export const DELETE_ROLE = "/roles";

// Activity Logs
export const GET_LOGS = "/logs";

// ---------- Codes ------------------
export const GET_CODES = "/codes";
export const GET_CODE = "/codes";
export const POST_CODE = "/codes";
export const PUT_CODE = "/codes";
export const DELETE_CODE = "/codes";

// ---------- Events ----------
export const GET_EVENTS = "/all-events";
export const BY_INVITED_USER_ID = "/invited";
export const PUBLIC_EVENTS = "/public-events";
export const GET_EVENT = "/events";
export const POST_EVENT = "/events";
export const PUT_EVENT = "/events";
export const DELETE_EVENT = "/events";

// ---------- Tasks ----------
export const LOAD_TASKS = "/load-tasks";
export const BY_USER_ID = "/user";
export const GET_TASK = "/tasks";
export const GET_TASK_GROUP = "/tasks/group";
export const POST_TASK = "/tasks";
export const PUT_TASK = "/tasks";
export const DELETE_TASK = "/tasks";

export const BY_CAMPER = "/camper";

// ---------- Grades ----------
export const LOAD_GRADES = "/grades";
export const GET_GRADE = "/grades";
export const POST_GRADE = "/grades";
export const PUT_GRADE = "/grades";
export const DELETE_GRADE = "/grades";

export const BY_ORGANIZATION = "/organization";

// ---------- Community Service ----------
export const LOAD_COMMUNITY_SERVICES = "/community-service";
export const BY_ASSC_ORGANIZATION = "/organization";
export const GET_COMMUNITY_SERVICE = "/community-service";
export const POST_COMMUNITY_SERVICE = "/community-service";
export const PUT_COMMUNITY_SERVICE = "/community-service";
export const DELETE_COMMUNITY_SERVICE = "/community-service";

// ---------- Conversations ----------
export const LOAD_CONVERSATIONS = "/conversations";
export const GET_CONVERSATION = "/conversations";
export const POST_CONVERSATION = "/conversations";
export const PUT_CONVERSATION = "/conversations";
export const DELETE_CONVERSATION = "/conversations";

// ---------- Messages ----------
export const LOAD_MESSAGES = "/messages";
export const GET_MESSAGE = "/messages";
export const POST_MESSAGE = "/messages";
export const PUT_MESSAGE = "/messages";
export const DELETE_MESSAGE = "/messages";

// ---------- Message Board----------

export const LOAD_TOPICS = "/";

// --------- Associations --------------
export const BY_ASSC_CAMPER = "/camper";

// ---------- Topic Boards ----------
export const LOAD_TOPIC_BOARDS = "/topic-boards";
export const GET_TOPIC_BOARDS = "/topic-boards";
export const POST_TOPIC_BOARDS = "/topic-boards";
export const PUT_TOPIC_BOARDS = "/topic-boards";
export const DELETE_TOPIC_BOARDS = "/topic-boards";

// ---------- DOCUMENTS ---------------
export const UPLOAD_FILE = "/documents";
export const LOAD_DOCUMENT = '/documents';
