import * as endpoints from "./apiEndpoints";
import axios from "axios";

class GradeService {
  static loadGrades() {
    const url = `${endpoints.BASE_URL}${endpoints.LOAD_GRADES}`;
    return axios.get(url).then(response => response.data);
  }

  static getGrade(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_GRADE}/${id}`;

    return axios.get(url).then(response => response.data);
  }

  static getGradeByCamperId(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_GRADE}${
      endpoints.BY_ASSC_CAMPER
    }/${id}`;
    console.log("url: ", url);
    return axios.get(url).then(response => response.data);
  }

  static createGrade(grade) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_GRADE}`;
    const body = {
      grade: grade
    };

    return axios.post(url, body).then(response => response.data);
  }

  static updateGrade(grade) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_GRADE}/${grade.id}`;
    const body = {
      grade: grade
    };

    return axios.put(url, body).then(response => response.data);
  }

  static deleteGrade(id) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_GRADE}/${id}`;
    return axios.delete(url).then(response => response.data);
  }
}

export default GradeService;
