import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as eventActions from "../../actions/eventActions";
import * as modalActions from "../../actions/modalActions";

import CalenderComponent from "../calender/CalenderComponent";

import BreadCrumbs from "../common/BreadCrumbs";
import Modal from "../common/Modal";
import ConfirmModal from "../common/ConfirmModal";
import AddButton from "../common/AddButton";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { Row, Col } from "react-bootstrap";

import moment from "moment";

class EventPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      events: [],
      adding: false,
      focusedInput: null,
      filters: {
        start_date: null,
        end_date: null
      }
    };
  }

  componentDidMount = () => {
    this.loadEvents(this.props);
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.loadEvents(nextProps);
    }
  };

  loadEvents = props => {
    let { actions, match, auth } = props;

    if (auth.role.number == 8000) {
      actions.loadEvents();
    } else {
      let userId = match.params.id || auth.user._id;
      actions.loadEventsByUserId(userId);
      actions.loadPublicEvents();
    }
  };

  onClickAdd = () => {
    this.props.history.push("/app/events/add");
  };

  onClickDelete = id => {
    this.props.actions.requestEventId(id);
  };

  handleAdd = event => {
    this.props.actions.createEvent(event);
    this.props.actions.hideModal();
    this.props.actions.loadEvents();
  };

  handleSave = event => {
    let data = {
      id: this.props.event.id,
      ...event
    };

    this.props.actions.hideModal();
    this.props.actions.updateEvent(data);
  };

  handleDelete = () => {
    this.props.actions.deleteEvent(this.props.eventToDelete);
  };

  handleCancel = () => {
    this.props.actions.hideModal();
  };

  handleSelect = event => {
    this.props.history.push(`/app/events/${event._id}`);
  };

  async handleMove({ event, start, end }) {
    let data = {
      ...event,
      start_date: moment.utc(start).subtract("30", "minute"),
      end_date: moment.utc(end).subtract("30", "minute")
    };

    await this.props.actions.updateEvent(data);
  }

  handleChangeDate(startDate, endDate) {
    this.setState({
      filters: {
        ...this.state.filters,
        start_date: startDate,
        end_date: endDate
      }
    });
  }

  handleChangeDateMobile(e) {
    this.setState({
      filters: {
        ...this.state.filters,
        [e.target.name]: e.target.value
      }
    });
  }

  render() {
    const { auth, events, publicEvents, users } = this.props;

    // combines private invite only events and public events if the user is not an admin.  Returns all events if the user is an admin
    let combinedEvents =
      auth.role.number !== 8000
        ? (combinedEvents = events.concat(publicEvents))
        : events;

    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs breadcrumbs={[{ label: "Events" }]} />
          <div>
            {auth.user.role.number > 7000 && (
              <AddButton onClickAdd={this.onClickAdd} />
            )}
          </div>
        </div>
        <div className="content-wrapper">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "0px 0px 10px 0px"
            }}
          >
            <strong>Private:</strong>
            <span className="legend-private" /> <strong>Public: </strong>
            <span className="legend-public" />
          </div>

          <Row>
            {/* Calendar component needs height to be explicitly defined in order to display */}
            <Col md={12} xs={12}>
              <CalenderComponent
                events={combinedEvents}
                users={users}
                role={auth.role.number}
                filters={this.state.filters}
                onSelectEvent={this.handleSelect}
                onMoveEvent={this.handleMove}
              />
              <Modal
                id="eventDetailsModal"
                title="Event Info"
                modal={this.props.modal}
                close={this.props.actions.hideModal}
              />
              <ConfirmModal
                id="eventDeleteModal"
                title="Delete Event"
                body="Are you sure you want to delete this event?"
                modal={this.props.modal}
                close={this.props.actions.hideModal}
                confirm={this.handleDelete}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

/* code for filtering events

    let filteredEvents = events;

    if (events) {
      // HANDLE FILTERS FROM UI
      if (this.state.filters.start_date && this.state.filters.end_date) {
        filteredEvents = filteredEvents.filter(event =>
          moment(this.state.filters.start_date).startOf("day") <=
            moment(event.start_time).startOf("day") &&
          moment(this.state.filters.end_date).startOf("day") >=
            moment(event.end_time).startOf("day")
            ? event
            : false
        );
      } else if (this.state.filters.start_date) {
        filteredEvents = filteredEvents.filter(event => {
          if (
            moment(this.state.filters.start_date).format("MM/DD/YYYY") ==
            moment(event.start_time).format("MM/DD/YYYY")
          ) {
            return event;
          }
        });
      }
    }

  handleChangeFilters(selectedOptions, field) {
    const filters = { ...this.state.filters };

    filters[field] = selectedOptions;
    this.setState({ filters });
  }

  toggleFilter() {
    this.setState({ open: !this.state.open });
  }

  resetFilters() {
    this.setState({
      filters: {
        start_date: null,
        end_date: null
      }
    });
  }
              <Button
              className="btn-action filter"
              onClick={() => this.toggleFilter()}
            >
              <FontAwesomeIcon icon={faFilter} className="icon" />
            </Button>

          <Row>
            <Col md={12} xs={12}>
              <Collapse in={this.state.open}>
                <div>
                  <Card style={{ margin: "1.5em 0" }}>
                    <Row>
                      <Col md={12} xs={12}>
                        {!isMobile ? (
                          <>
                            <DateRangePicker
                              startDate={this.state.filters.start_date}
                              startDateId="startDate"
                              endDate={this.state.filters.end_date}
                              endDateId="endDate"
                              onDatesChange={({ startDate, endDate }) => {
                                this.handleChangeDate(startDate, endDate);
                              }}
                              isOutsideRange={() => false}
                              focusedInput={this.state.focusedInput}
                              onFocusChange={focusedInput =>
                                this.setState({ focusedInput })
                              }
                            />
                            <Button
                              className="btn-calendar"
                              onClick={() => this.resetFilters()}
                            >
                              Reset Filter
                            </Button>
                            <Button
                              className="btn-calendar"
                              onClick={() => this.toggleFilter()}
                            >
                              Hide Filter
                            </Button>
                          </>
                        ) : (
                          <>
                            <FormGroup>
                              <FormLabel>Start Date</FormLabel>
                              <FormControl
                                name="start_date"
                                type="date"
                                placeholder="Start Date"
                                value={this.state.filters.start_date || ""}
                                onChange={this.handleChangeDateMobile}
                              />
                            </FormGroup>
                            <FormGroup>
                              <FormLabel>End Date</FormLabel>
                              <FormControl
                                name="end_date"
                                type="date"
                                placeholder="End Date"
                                value={this.state.filters.end_date || ""}
                                onChange={this.handleChangeDateMobile}
                              />
                            </FormGroup>
                            <Button
                              className="btn-calendar"
                              onClick={() => this.resetFilters()}
                            >
                              Reset Filter
                            </Button>
                            <Button
                              className="btn-calendar"
                              onClick={() => this.toggleFilter()}
                            >
                              Hide Filter
                            </Button>
                          </>
                        )}
                      </Col>
                    </Row>
                  </Card>
                </div>
              </Collapse>
            </Col>
          </Row>
  */

EventPage.propTypes = {
  actions: PropTypes.object,
  modal: PropTypes.object,
  savingEvent: PropTypes.bool,
  eventToDelete: PropTypes.string,
  events: PropTypes.array.isRequired,
  publicEvents: PropTypes.array,
  event: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  users: PropTypes.object,
  auth: PropTypes.object
};

const mapStateToProps = state => {
  return {
    state: state,
    modal: state.modal,
    savingEvent: state.savingEvent,
    event: state.event,
    events: state.events,
    publicEvents: state.publicEvents,
    eventToDelete: state.eventToDelete,
    modal: state.modal
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...modalActions, ...eventActions }, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventPage);
