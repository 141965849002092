import React from "react";
import PropTypes from "prop-types";
import { ListGroup, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";

import isEmpty from "lodash/isEmpty";

const TodoList = ({ listItems, removeItem, disabled }) => {
  return (
    <ListGroup>
      {!isEmpty(listItems) ? (
        listItems.map(item => (
          <ListGroup.Item key={item.id} className="flex -sb">
            {item.text}
            <Button
              className="btn-action"
              onClick={() => removeItem(item.id)}
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faMinus} />
            </Button>
          </ListGroup.Item>
        ))
      ) : (
        <h4 className="text-center">No items</h4>
      )}
    </ListGroup>
  );
};

TodoList.proptypes = {
  listItems: PropTypes.array,
  removeItem: PropTypes.func
};

export default TodoList;
