import React, { Component } from "react";
import UserService from "../../services/userService";

import { UserAvatar } from "../users/UserAvatar";
import "./AvatarUpload.scss";

class AvatarUpload extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }
  async handleChange() {
    try {
      if (this.input.current.files.length) {
        this.props.onUploadStart();
        console.log(`UPLOADING`);
        const fd = new FormData();
        fd.append("avatar", this.input.current.files[0]);
        const file = await UserService.uploadAvatar(fd);
        this.props.onUploadSuccess(file);
      }
    } catch (err) {
      console.log(err);
    }
  }
  render() {
    return (
      <div className="user__avatar">
        <div className="user__avatar__display">
          <UserAvatar
            user={{ ...this.props.user, avatar: this.props.avatar }}
            size="md"
          />
        </div>
        <div className="form-group row">
          {/* <label className="control-label">Avatar</label> */}
          <input
            disabled={this.props.disabled}
            className="form-control"
            type="file"
            accept="image/*"
            ref={this.input}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}

export default AvatarUpload;
