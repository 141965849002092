import * as endpoints from "./apiEndpoints";
import axios from "axios";

class LogService {
    static loadLogs() {
        const url = `${endpoints.BASE_URL}${endpoints.GET_LOGS}`;
        return axios.get(url).then(response => response.data);
    }
}

export default LogService;