import * as types from "./actionTypes";
import { showModalSuccess } from "./modalActions";
import { showAlertSuccess, hideAlertSuccess } from "./alertActions";
import gradeService from "../services/gradeService";

export function loadGradeSuccess(grades) {
  return {
    type: types.LOAD_GRADES_SUCCESS,
    grades
  };
}

export function getGradeSuccess(grade) {
  return {
    type: types.GET_GRADE_SUCCESS,
    grade
  };
}

export function getGradeByCamperIdSuccess(grade) {
  return {
    type: types.GET_GRADE_BY_CAMPER_ID_SUCCESS,
    grade
  };
}

export function savingGrade(status = true) {
  return {
    type: types.SAVING_GRADE,
    savingGrade: status
  };
}

export function createGradeSuccess(grade) {
  return {
    type: types.CREATE_GRADE_SUCCESS,
    grade
  };
}

export function updateGradeSuccess(grade) {
  return {
    type: types.UPDATE_GRADE_SUCCESS,
    grade
  };
}

export function requestGradeId(gradeId) {
  return dispatch => {
    dispatch({
      type: types.REQUEST_GRADE_ID,
      gradeToDelete: gradeId
    });
    dispatch(showModalSuccess("courseDeleteModal"));
  };
}

export function deleteGradeSuccess(gradeId) {
  return {
    type: types.DELETE_GRADE_SUCCESS,
    gradeId
  };
}

export function loadGrades() {
  return dispatch => {
    dispatch(hideAlertSuccess());
    return gradeService
      .loadGrades()
      .then(data => dispatch(loadGradeSuccess(data)))
      .catch(error => dispatch(showAlertSuccess(error, "error")));
  };
}

export function getGrade(id, showGRADEDetails = false) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    if (id) {
      return gradeService
        .getGrade(id)
        .then(grade => {
          dispatch(getGradeSuccess(grade));
          if (showGRADEDetails) {
            dispatch(showModalSuccess("gradeDetailsModal"));
          }
        })
        .catch(error => dispatch(showAlertSuccess(error.description, "error")));
    } else {
      dispatch(showModalSuccess("gradeDetailsModal"));
    }
  };
}

export function getGradeByCamperId(id, showGRADEDetails = false) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    if (id) {
      return gradeService
        .getGradeByCamperId(id)
        .then(grade => {
          dispatch(getGradeByCamperIdSuccess(grade));
          if (showGRADEDetails) {
            dispatch(showModalSuccess("gradeDetailsModal"));
          }
        })
        .catch(error => dispatch(showAlertSuccess(error.description, "error")));
    } else {
      dispatch(showModalSuccess("gradeDetailsModal"));
    }
  };
}

export function createGrade(grade) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingGrade());
    return gradeService
      .createGrade(grade)
      .then(createdGrade => {
        dispatch(createGradeSuccess(createdGrade));
        dispatch(savingGrade(false));
        dispatch(showAlertSuccess("Course created successfully", "success"));
      })
      .catch(error => {
        dispatch(savingGrade(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateGrade(grade) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingGrade());
    return gradeService
      .updateGrade(grade)
      .then(updatedGrade => {
        dispatch(updateGradeSuccess(updatedGrade));
        dispatch(savingGrade(false));
        dispatch(showAlertSuccess("Grades updated successfully", "success"));
      })
      .catch(error => {
        dispatch(savingGrade(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteGrade(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return gradeService
      .deleteGrade(id)
      .then(() => {
        dispatch(deleteGradeSuccess(id));
        dispatch(showAlertSuccess("Course deleted successfully", "success"));
      })
      .catch(error => dispatch(showAlertSuccess(error.description, "error")));
  };
}
