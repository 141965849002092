import React from "react";
import PropTypes from "prop-types";
import Animated from "react-select/lib/animated";
import Select from "react-select";

const UserSearch = ({ value, data, className, onChange }) => (
  <Select
    className={className}
    components={Animated()}
    value={value}
    onChange={value => onChange(name, value)}
    options={data.map(user => ({
      value: user._id,
      label: user.full_name
    }))}
    placeholder={"Search users"}
    validations="isEmptyString"
    isSearchable
    isMulti
    // menuIsOpen
    closeMenuOnSelect={false}
    isClearable={false}
  />
);

UserSearch.propTypes = {
  value: PropTypes.array,
  data: PropTypes.array,
  className: PropTypes.string,
  onChange: PropTypes.func
};

export default UserSearch;
