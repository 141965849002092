import React from "react";

function Searchbar({ onFilter, value }) {
  return (
    <input
      autoFocus
      type="text"
      className="searchbar form-control"
      placeholder="&#xF002;  Search"
      style={{ fontFamily: "Lato, FontAwesome" }}
      onChange={onFilter}
      value={value}
    />
  );
}
export default Searchbar;
