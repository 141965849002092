import React from "react";
import PropTypes from "prop-types";
import { Navbar, Nav, NavItem } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faUser,
  faCalendarAlt,
  faFileContract,
  faCog,
  faHandsHelping,
  faGraduationCap,
  faDatabase,
  faUserTag,
  faTasks,
  faCommentAlt,
  faClipboard
} from "@fortawesome/free-solid-svg-icons";
import Logout from "../logout/Logout";
import image from "../../images/mark.png";

import startCase from "lodash/startCase";

const navItems = [
  {
    path: "users",
    icon: faUsers,
    role: [8000]
  },
  {
    path: "profile",
    icon: faUser,
    role: [4000, 5000, 6000, 7000]
  },
  {
    path: "directory",
    icon: faDatabase,
    role: [4000, 5000, 6000, 7000]
  },
  {
    path: "events",
    icon: faCalendarAlt,
    role: [4000, 5000, 6000, 7000, 8000]
  },
  {
    path: "tasks",
    icon: faTasks,
    role: [4000, 5000, 6000, 7000, 8000]
  },
  {
    path: "messages",
    icon: faCommentAlt,
    role: [4000, 5000, 6000, 7000, 8000]
  },
  {
    path: "courses",
    icon: faGraduationCap,
    role: [4000]
  },
  {
    path: "community-service",
    icon: faHandsHelping,
    role: [4000]
  },
  {
    path: "topic-boards",
    icon: faClipboard,
    role: [4000, 5000, 6000, 7000, 8000]
  },
  {
    path: "documents",
    icon: faFileContract,
    role: [4000, 5000, 6000, 7000, 8000]
  }
  // {
  //   path: "roles",
  //   icon: faUserTag,
  //   role: [8000]
  // },
  // {
  //   path: "codes",
  //   icon: faCog,
  //   role: [8000]
  // },
];

const Sidebar = ({ role }) => (
  <Navbar collapseOnSelect className="fixedtop">
    <Nav className="sidebar">
      <Navbar.Brand href="/app">
        <img className="logo" src={image} alt="Vohokase" />
      </Navbar.Brand>
      <div className="nav-items">
        {navItems
          .filter(item => item.role.includes(role))
          .map((item, i) => (
            <NavLink
              key={i}
              to={`/app/${item.path}`}
              activeClassName="is-active"
            >
              <NavItem>
                <FontAwesomeIcon icon={item.icon} />
                <p>{startCase(item.path)}</p>
              </NavItem>
            </NavLink>
          ))}
      </div>
      <Logout />
    </Nav>
  </Navbar>
);

Sidebar.propTypes = {
  role: PropTypes.number
};

export default Sidebar;
