import React from "react";
import PropTypes from "prop-types";

import DropdownSearch from "../../common/DropdownSearch";
import { Col } from "react-bootstrap";

import orderBy from "lodash/orderBy";

const generateCamperOptions = users => {
  return orderBy(
    users
      .filter(user => user.role.number === 4000)
      .map(user => ({
        value: user._id,
        label: user.full_name
      })),
    "label"
  );
};

const AssociateCampers = ({
  onChangeSelect,
  users,
  associated_campers,
  editing
}) => {
  let campers;
  if (associated_campers) {
    campers = associated_campers.map(ac => {
      if (ac && ac.value && ac.label) {
        return ac;
      } else {
        return {
          value: ac._id,
          label: ac.full_name
        };
      }
    });
  }
  return (
    <>
      <Col md={12} xs={12}>
        <fieldset>
          <legend>Associated Campers</legend>
        </fieldset>
      </Col>
      <Col md={12} xs={12}>
        <DropdownSearch
          name="associated_campers"
          label="Camper Association"
          disabled={!editing}
          onChange={onChangeSelect}
          isMulti={true}
          value={campers}
          data={[...generateCamperOptions(users)]}
        />
      </Col>
    </>
  );
};

AssociateCampers.propTypes = {
  userOptions: PropTypes.array,
  editing: PropTypes.bool,
  associated_campers: PropTypes.array,
  users: PropTypes.array,
  onChangeSelect: PropTypes.func
};

export default AssociateCampers;
