import * as types from "../actions/actionTypes";
import initialState from "../reducers/initialState";

export const documents = (state = initialState.documents, action) => {
  switch (action.type) {
    case types.LOAD_DOCUMENTS_SUCCESS:
      return action.documents;
    case types.CREATE_DOCUMENT_SUCCESS:
      return [...state, action.document];
    case types.UPDATE_DOCUMENT_SUCCESS:
      return [...state, action.document];
    case types.DELETE_DOCUMENT_SUCCESS:
      return [...state.filter(doc => doc._id !== action.document._id)];
    default:
      return state;
  }
};

export const document = (state = initialState.document, action) => {
  switch (action.type) {
    case types.GET_DOCUMENT_SUCCESS:
      return action.document;
    default:
      return state;
  }
};

export const savingDocument = (state = initialState.savingDocument, action) => {
  switch (action.type) {
    case types.SAVING_DOCUMENT:
      return action.savingDocument;

    default:
      return state;
  }
};

export const loadingDocument = (
  state = initialState.loadingDocument,
  action
) => {
  switch (action.type) {
    case types.LOADING_DOCUMENT:
      return action.loadingDocument;
    default:
      return state;
  }
};

export const documentToDelete = (
  state = initialState.documentToDelete,
  action
) => {
  switch (action.type) {
    case types.REQUEST_DOCUMENT_DELETE:
      return action.documentToDelete;

    default:
      return state;
  }
};
