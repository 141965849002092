import React from "react";
import PropTypes from "prop-types";
import ScaleModal from "reboron/ScaleModal";
import autoBind from "react-autobind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";

class Modal extends React.Component {
  constructor(props, context) {
    super(props, context);

    autoBind(this);
  }

  componentWillUpdate = nextProps => {
    const { id, modal } = nextProps;

    if (modal !== this.props.modal) {
      if (id === modal.id && modal.show) {
        this.refs.modal.show();
      } else {
        this.refs.modal.hide();
      }
    }
  };

  close = () => {
    const { close, id } = this.props;
    close(id);
  };

  render() {
    const { id, size, title, body, footer, preventClose } = this.props;

    let width = size == "lg" ? "70%" : "inherit";
    let modalStyle = {
      width
    };

    return (
      <div id={id}>
        <ScaleModal
          ref="modal"
          className="view-modal"
          keyboard={preventClose ? false : true}
          closeOnClick={preventClose ? false : true}
        >
          <div className={`modal-${size}`}>
            <div className="modal-header">
              {!preventClose && (
                <button type="button" className="close" onClick={this.close}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              )}
              <h2 className="modal-title">{title}</h2>
            </div>
            <div className="modal-body">{body}</div>
            <div className="modal-footer">
              {/* <button
                className="btn btn-primary btn-outline"
                type="button"
                onClick={this.close}
              >
                Close
              </button>*/}
              {footer}
            </div>
          </div>
        </ScaleModal>
      </div>
    );
  }
}

Modal.defaultProps = {
  size: "md"
};

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.string,
  title: PropTypes.string.isRequired,
  body: PropTypes.element,
  footer: PropTypes.element,
  close: PropTypes.func,
  modal: PropTypes.object
};

export default Modal;
