import React from "react";
import PropTypes from "prop-types";
import { Row, Button, Badge } from "react-bootstrap";

import moment from "moment";

const PostCard = ({ post, onClickView }) => {
  const date = `${
    typeof post.author === "object" ? post.author.full_name : ""
  } \u2022 ${moment(post.created_on).format("MMM DD, YYYY")}`;

  const styles = {
    backgroundColor: post.topic.color
  };

  return (
    <Row id="post-card" className="card-wrapper column">
      <div className="card-header">
        <h3>{post.title}</h3>
        <Badge style={styles}>{post.topic.label}</Badge>
        <p>{date}</p>
      </div>
      <div className="card-detail">
        <p>{post.content}</p>
      </div>
      {onClickView && (
        <Button
          onClick={() => onClickView(post._id)}
          className="btn-transparent"
        >
          Read More
        </Button>
      )}
    </Row>
  );
};

PostCard.propTypes = {
  post: PropTypes.object,
  onClickView: PropTypes.func,
  onClickDelete: PropTypes.func,
  auth: PropTypes.object
};

export default PostCard;
